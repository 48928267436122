import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' // 👈️ Redux Imports
import axios from 'axios'// 👈️ Axios Imports
import { urlReportingService } from '@src/endpoints' // 👈️ API Microservice EndPoint

export const getVGMReport = createAsyncThunk('vgm/getVGMReport', async (param) => {
  const response = await axios.get(`${urlReportingService}/GetTransloadBookingReport/${param.oid}/${param.bid}`)
  return {
    vgmReport: response.data.data,
    vloaded: true
  }
})

export const vgmSlice = createSlice({
  name: 'vgm',
  initialState: {
    vloaded : false,
    verror : false,
    vgmReport: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getVGMReport.pending, (state) => {
      state.vloaded = false
    })
    .addCase(getVGMReport.fulfilled, (state, action) => {
      state.vloaded = action.payload.vloaded
      state.vgmReport = action.payload.vgmReport
    })
    .addCase(getVGMReport.rejected, (state) => {
      state.vloaded = true
      state.verror = true
    })
  }
})
  
export default vgmSlice.reducer

vgmSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'VGM Reports - Store {Get Data from Microservices}',
  filename: 'vgm',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/reports/store/vgm.js',
  prodUrl: ''
}