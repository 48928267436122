const baseURL = process.env.REACT_APP_API_URL

export const urlAuthService = `${baseURL}/Auth`
export const urlIdentityService = `${baseURL}/Identity`
export const urlCompanyService = `${baseURL}/Company`
export const urlCompanyAddressService = `${baseURL}/CompanyAddress`
export const urlFreightForwardService = `${baseURL}/FreightForward`
export const urlEquipmentService = `${baseURL}/Equipment`
export const urlTerminalService = `${baseURL}/Movement`
export const urlMovementService = `${baseURL}/Terminal`
export const urlTransportationDriverService = `${baseURL}/TransportationDriver`
export const urlOceanScheduleService = `${baseURL}/OceanSchedule`
export const urlConfigCacheService = `${baseURL}/ConfigCache`
export const urlShipSmplService = `${baseURL}/ShipSmpl`
export const urlOceanBookingService = `${baseURL}/OceanBooking`
export const urlLandBookingService = `${baseURL}/LandBooking`
export const urlInboundRequestService = `${baseURL}/InboundRequest`
export const urlOutboundRequestService = `${baseURL}/OutboundReport`
export const urlOutboundPlanService = `${baseURL}/OutboundPlan`
export const urlDispatchService = `${baseURL}/Dispatch`
export const urlYardMove = `${baseURL}/YardMovePlan`
export const urlTimeSheetService = `${baseURL}/TimeSheet`
export const urlDriverService = `${baseURL}/Driver`
export const urlReportService = `${baseURL}/ManagementReport`
export const urlDashboardService = `${baseURL}/Dashboard`
export const urlCRMLeadService = `${baseURL}/CRM/Leads`
export const urlCRMContactService = `${baseURL}/CRM/Contacts`
export const urlCRMDealService = `${baseURL}/CRM/Deals`
export const urlInvoiceService = `${baseURL}/Invoice`
export const urlInvoiceChargeSummary = `${baseURL}/ChargeSummary`
export const urlRateService =  `${baseURL}/Rate`
export const urlServiceRateDetailService =  `${baseURL}/ServiceRateDetail`
export const urlQuoteService =  `${baseURL}/Quote`
export const urlDailyPlannigService = `${baseURL}/DailyPlannig`
export const urlBookTimeOffService = `${baseURL}/BookTimeOff`
export const urlHolidayCalendarService = `${baseURL}/HolidayCalendar`
export const urlServices =  `${baseURL}/Service`
export const urlUserSkills =  `${baseURL}/UserSkills`
export const urlVesselVoyageService = `${baseURL}/VesselVoyage`
export const urlTruckService = `${baseURL}/Truck`
export const urlPieceWorkService = `${baseURL}/PieceWork`
export const urlTransloadOrderService = `${baseURL}/TransloadOrder`
export const urlLoadingInstructionService = `${baseURL}/LoadingInstruction`
export const urlTransloadTransactionService = `${baseURL}/TransloadTransaction`
export const urlPackagingService = `${baseURL}/Packaging`
export const urlDunnageService = `${baseURL}/Dunnage`
export const urlContainerService = `${baseURL}/Container`
export const urlCRMCompanyService = `${baseURL}/CRMCompany`
export const urlCRMCompanyAddressService = `${baseURL}/CRMCompanyAddress`
export const urlEdiRecords = `${baseURL}/EDIRecord`
export const urlMaterialInventoryService = `${baseURL}/MaterialInventory`
export const urlCargoInventoryService = `${baseURL}/CargoInventory`
export const urlReportingService = `${baseURL}/ReportingService`
export const urlEquipmentPictureService = `${baseURL}/EquipmentPicture`
export const urlCleanlinessCertificate = `${baseURL}/CleanlinessCertificate`
export const urlTemporaryTransactionService = `${baseURL}/TemporaryTransaction`
export const urlSpecialTransloadTransactionService = `${baseURL}/SpecialTransloadTransaction`
export const urlServiceAgreement = `${baseURL}/ServiceAgreement`
export const urlNodeService = `${baseURL}/api/v1/NodeService`

export const urlAzureBlobServices =  'https://shipsmpl20st.blob.core.windows.net/employee-pictures'
