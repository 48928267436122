// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlAuthService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('singleSignOn/getAllData', async () => {
  const response = await axios.get(`${urlAuthService}/GetSingleSignOnStatusCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('singleSignOn/getData', async params => {
  const uri =  getUriParams(params)
  const response = await axios.get(`${urlAuthService}/GetAllSingleSignOn?${uri}`)
  return {
    params,
    data: response.data.totalCount > 0 ? response.data.data : null,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

//RevokeToken
export const revokeToken = async (revokeTokenViewModel) => {
  const response = await axios.put(`${urlAuthService}/RevokeToken`, revokeTokenViewModel)
  return response
}

export const singleSignOnSlice = createSlice({
  name: 'singleSignOn',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default singleSignOnSlice.reducer

singleSignOnSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Single Sign On - Store {Get Data from Microservices}',
  filename: 'singleSignOn',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/admin/store/singleSignOn.js',
  prodUrl: ''
}
