// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import {urlDispatchService, urlConfigCacheService, urlEquipmentService} from '@src/endpoints'
import { getUriParams } from '@utils'

export const getData = createAsyncThunk('toDoTranportation/getData', async data => {
  const {params, filterForm} = data
  const uri = getUriParams({...params, status:"", q:""})
  const response = await axios.post(`${urlDispatchService}/GetToDoList?${uri}`, filterForm)
  return {
    params,
    terminal: [],
    data: response.data.data || [],
    totalPages: response.data.totalCount,
    loaded: true,
    filterForm
  }
})

export const getMissedLRDContainers = createAsyncThunk('toDoTranportation/getMissedLRDContainers', async data => {
  const {params, filterForm} = data
  const uri = getUriParams({...params, status:"", q:""})
  const response = await axios.post(`${urlEquipmentService}/GetMissedLRDContainers?${uri}`, filterForm)
  return {
    params,
    data: response.data.data || [],
    totalPages: response.data.totalCount,
    loaded: true,
    filterForm
  }
})

export const getSSLines = async () => {
  const response = await axios.get(`${urlConfigCacheService}/SteamshipLine`)
  return response.data
}

export const toDoTranportationSlice = createSlice({
  name: 'toDoTranportation',
  initialState: {
    loaded : false,
    data: [],
    terminal: [],
    terminalTotal: 0,
    total: 1,
    params: {},
    filterForm: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = true
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.terminal = action.payload.terminal
        state.filterForm = action.payload.filterForm
      })
      .addCase(getMissedLRDContainers.fulfilled, (state, action) => {
        state.loaded = true
        state.terminal = action.payload.data
        state.params = action.payload.params
        state.terminalTotal = action.payload.totalPages
        state.filterForm = action.payload.filterForm
      })
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
        state.data = []
      })
  }
})

export default toDoTranportationSlice.reducer
