import { urlRateService, urlQuoteService, urlServices } from '@src/endpoints'
import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { urlConfigCacheService } from '../../../../endpoints'

const getParamString = (params) => {
  let string = ""
  if (Object.keys(params).length > 0) {
    Object.keys(params).map((key) => {
      if (!!params[key]) {
        string += `${key}=${params[key]}&`
      }
    })
  }
  return string
}

export const GetRateVersion = async (id) => {
  try {
    const response = await axios.get(`${urlRateService}/GetRateVersion/${id}`)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  } catch (error) {
    return { success: false, message: 'Error getting Rate Version' }
  }
} 

export const GetRatesByRateType = async (rate) => {
  const data = {rateTypeName: rate, validDate: new Date().toISOString()}
  const response = await axios.post(`${urlRateService}/GetRateTier`, data)
  if (response.data) {
    return { success: true, data: response.data }
  } else {
      return { success:false, message: 'Internal server Error' }
  }
}

export const getServiceRateDetails = async (id) => {
  const response = await axios.post(`${urlRateService}/GetAllServiceRateDetails`, {rateId: id})
  if (response.data) {
      return { success: true, data: response.data }
  } else {
    return { success:false, message: 'Internal server Error' }
  }
}

export const getAllServices = async () => {
  const response = await axios.post(`${urlServices}/GetAllServices`)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}


export const UpdateService = async (data) => {
  const response = await axios.post(`${urlServices}/UpdateService`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
  return { success: false, message: response.data.errors[0] }
  }
}

export const AddService = async (data) => {
  const response = await axios.post(`${urlServices}/AddService`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

export const deleteService = async (id) => {
  const response = await axios.delete(`${urlServices}/DeleteService/${id}`)
  if (response.data.success) {
    return { success: true } 
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

  export const getAllCustomers = async () => {
    const response = await axios.get(`${urlRateService}/GetAllCustomers`)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const CreateNewQuote = async (data) => {
    const response = await axios.post(`${urlQuoteService}/AddQuote`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const GetAllQuotes = async (params) => {
  const uri = `page=${params.page}&perPage=${params.perPage}&status=${''}&q=${params.q}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  const dates = {fromDate: "2023-01-16T18:42:04.609Z", toDate: params.toDate || new Date() }
  const response = await axios.post(`${urlQuoteService}/GetAllQuotes?${uri}`, dates)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

export const getQuote = async (id) => {
  const response = await axios.get(`${urlQuoteService}/GetQuote/${id}`)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

export const AddQuoteNote = async (data) => {
  const response = await axios.post(`${urlQuoteService}/AddQuoteNote`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.errors[0] }
  }
}

export const UpdateQuote = async (data) => {
  try {
    const response = await axios.post(`${urlQuoteService}/UpdateQuote`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  } catch (error) {
    return { success: false, message: 'Error updating Quote' }
  }
}

export const DeleteQuote = async (id) => {
  const response = await axios.delete(`${urlQuoteService}/DeleteQuote/${id}`)
  if (response.data.success) {
    return { success: true }
  }
  return { success: false, message: response.data.errors[0] }
}

export const GetAllRateTiers = createAsyncThunk(
  "rate/getAllRateTiers",
  async () => {
  const response = await axios.post(`${urlRateService}/GetAllRateTiers`)
  return {
    data: response.data.data
  }
})
  
export const GetAllRateVersions = createAsyncThunk(
  "rate/getAllRates",
  async (data) => {
    const { params, filters } = data
    const response = await axios.post(
      `${urlRateService}/GetAllRateVersions?${getParamString(params)}`, filters)
    return {
      data: response.data.data, // this is an array of objects
      totalCount: response.data.totalCount, // this is a direct property of the response
      params,
      filterData: filters
    }
  }
)

export const GetFilteredRateVersions = createAsyncThunk('rate/getFilteredRateVersions', async (data) => {
  const { params, filters } = data
  const uri = `page=${params.page || 1}&perPage=${params.perPage || 10}&status=${params.status || ''}&q=${params.q || ''}&sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'id'}`
  const filterData = filters ? filters : {}
  const response = await axios.post(`${urlRateService}/GetAllRateVersions?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data,
    totalCount: response.data.totalCount
  }
})
  
  export const AddRate = async (data) => {
    const response = await axios.post(`${urlRateService}/AddRateVersion`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const UpdateRate = async (data) => {
    const response = await axios.post(`${urlRateService}/UpdateRateVersion`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const UpdateRateType = async (data) => {
    const response = await axios.post(`${urlRateService}/UpdateRateTier`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const AddRateType = async (data) => {
    const response = await axios.post(`${urlRateService}/AddRateTier`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const GetAllBillableUnits = createAsyncThunk('rate/GetAllBillableUnits', async () => {
    const response = await axios.post(`${urlRateService}/GetAllBillableUnits`)
    return {
      billableUnits: response.data.data
    }
  })

  export const GetAllCategoryServices = createAsyncThunk('rate/getAllCategoryServices', async () => {
    const response = await axios.post(`${urlServices}/GetAllCategoryServices`)
    return {
      categoryServiceList: response.data.data
    }
  })

  // export const GetServicesByCategory = async (id) => {
  //   const data = { categoryServiceId: id }
  //   const response = await axios.post(`${urlServices}/GetServicesByCategory`, data)
  //   if (response.data.success) {
  //     return { success: true, data: response.data.data }
  //   } else {
  //     return { success: false, message: response.data.errors[0] }
  //   }
  // }

  export const GetServicesByCategory = createAsyncThunk('rate/getServicesByCategory', async (data) => {
    const response = await axios.post(`${urlServices}/GetServicesByCategory`, data)
    return {
      data: response.data.data
    }
  })
  
  export const AddServiceRate = async (data) => {
    const response = await axios.post(`${urlRateService}/AddCategoryService`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const UpdateServiceRate = async (data) => {
    const response = await axios.post(`${urlRateService}/UpdateCategoryService`, data)
    if (response.data.success) {
      return { success: true, data: response.data.data }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const DeleteServiceRate = async (id) => {
    const response = await axios.delete(`${urlRateService}/DeleteCategoryService/${id}`)
    if (response.data.success) {
      return { success: true }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const DeleteRate = async (id) => {
    const response = await axios.delete(`${urlRateService}/DeleteRateVersion/${id}`)
    if (response.data.success) {
      return { success: true }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const DeleteRateType = async (id) => {
    const response = await axios.delete(`${urlRateService}/DeleteRateTier/${id}`)
    if (response.data.success) {
      return { success: true }
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

  export const getCommodityTypeData = createAsyncThunk(
    "qualityConfig/getCommodityTypeData",
    async () => {
      const response = await axios.get(`${urlConfigCacheService}/CommodityType`)
      return response.data
    }
  )

  export const getBillingCycleData = createAsyncThunk(
    "qualityConfig/getBillingCycleData",
    async () => {
      const response = await axios.get(`${urlConfigCacheService}?type=BillingCycle`)
      return response.data
    }
  )

  export const getServiceStartingData = createAsyncThunk(
    "qualityConfig/getServiceStartingData",
    async () => {
      const response = await axios.get(`${urlConfigCacheService}?type=ServiceStarting`)
      return response.data
    }
  )

  export const getServiceCompletionData = createAsyncThunk(
    "qualityConfig/getServiceCompletionData",
    async () => {
      const response = await axios.get(`${urlConfigCacheService}?type=ServiceCompletion`)
      return response.data
    }
  )

  export const getFreeTimeData = createAsyncThunk(
    "qualityConfig/getFreeTimeData",
    async () => {
      const response = await axios.get(`${urlConfigCacheService}?type=FreeTime`)
      return response.data
    }
  )

  const ratesSlice = createSlice({
    name: 'rates',
    initialState: {
      rateTier: [],
      data: [],
      categoryServiceList: [],
      serviceCategories: [],
      billableUnits: [],
      billingCycle: [],
      commodityType: [],
      serviceStarting: [],
      serviceCompletion: [],
      freeTime: [],
      loading: false,
      filter: {},
      params: {},
      total: 1,
      error: null
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(GetAllRateVersions.pending, (state) => {
          state.loading = true
        })
        .addCase(GetAllRateVersions.fulfilled, (state, action) => {
          state.loading = false
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalCount
          state.filter = action.payload.filterData
        })
        .addCase(GetAllRateVersions.rejected, (state, action) => {
          state.loading = false
          state.error = action.error.message
        })
        .addCase(GetAllRateTiers.pending, (state) => {
          state.loading = true
        })
        .addCase(GetAllRateTiers.fulfilled, (state, action) => {
          state.loading = false
          state.rateTier = action.payload.data
        })
        .addCase(GetAllRateTiers.rejected, (state, action) => {
          state.loading = false
          state.error = action.error.message
        })
        .addCase(GetAllCategoryServices.fulfilled, (state, action) => {
          state.categoryServiceList = action.payload.categoryServiceList
        })
        .addCase(GetAllBillableUnits.fulfilled, (state, action) => {
          state.billableUnits = action.payload.billableUnits
        })
        .addCase(GetServicesByCategory.fulfilled, (state, action) => {
          state.serviceCategories = action.payload.data
        })
        .addCase(getCommodityTypeData.fulfilled, (state, action) => {
          state.commodityType = action.payload
        })
        .addCase(getBillingCycleData.fulfilled, (state, action) => {
          state.billingCycle = action.payload
        })
        .addCase(getServiceStartingData.fulfilled, (state, action) => {
          state.serviceStarting = action.payload
        })
        .addCase(getServiceCompletionData.fulfilled, (state, action) => {
          state.serviceCompletion = action.payload
        })
        .addCase(getFreeTimeData.fulfilled, (state, action) => {
          state.freeTime = action.payload
        })
    }
  })
  
  export default ratesSlice.reducer