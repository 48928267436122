//convert to codes 
const Alphabet = {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, A: 10, B: 12, C: 13, D: 14, E: 15, F: 16, G: 17, H: 18, I: 19, J: 20, K: 21, L: 23, M: 24, N: 25, O: 26, P: 27, Q: 28, R: 29, S: 30, T: 31, U: 32, V: 34, W: 35, X: 36, Y: 37, Z: 38}

function GetSumm(inputString) {
    let summ = 0
    for (let i = 0; i < inputString.length - 1; i++) {
        const tempChar = inputString[i]
        let charNumber = 0
        if (Alphabet.hasOwnProperty(tempChar)) {
            charNumber = Alphabet[tempChar]
        }
        summ += charNumber * Math.pow(2, i)
    }
    return summ
}

export function CheckContainerNumberPerIsoSD(containerNumber) {
    //Container number must be 9-11 characters long
    const pattern = /^[A-Z]{4}\d{6}\d{1}$/
    const regex = new RegExp(pattern)
    const match = regex.exec(containerNumber)

    if (match) {
        const summ = GetSumm(containerNumber)
        const checkDigit = parseInt(containerNumber[containerNumber.length - 1])
        let tempCheckNumber = summ - (Math.floor(summ / 11) * 11)
        tempCheckNumber = tempCheckNumber === 10 ? 0 : tempCheckNumber
        if (tempCheckNumber === checkDigit) {
            return true
        }
    }
    return false
}

export function isValidContainerPattern(containerNumber) {
    //Container number must be 9-11 characters long
    if (containerNumber.length < 9 || containerNumber.length > 11) {
        return false
    } else {
        //Container number must be alphanumeric
        const pattern = /^[A-Z0-9]+$/
        const regex = new RegExp(pattern)
        const match = regex.exec(containerNumber)
        if (match) {
            return true
        }
    }
}