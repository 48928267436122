// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlCRMLeadService, urlCRMContactService, urlCRMDealService, urlIdentityService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('crmLeads/getAllData', async () => {
  const response = await axios.get(`${urlCRMLeadService}/GetLeadStageCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('crmLeads/getData', async params => {
  const uri =  getUriParams(params)
  const LeadFilter = {
    leNumber: params.leNumber,
    companyId: params.companyId,
    leadUserId: params.leadUserId,
    salesRepId: params.salesRepId,
    leadSource: null,
    tags: null,
    comments: null,
    fromDate: params.fromDate,
    toDate: params.toDate,
    stageType: params.stageType,
    leadType: params.leadType,
    isSoftDeleted: false
  }
  const response = await axios.post(`${urlCRMLeadService}/GetAllLeads?${uri}`, LeadFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const getLeads = createAsyncThunk('crmLeads/getLeads', async id => {
  const response = await axios.get(`${urlCRMLeadService}/GetLeads/${id}`)
  return {
    leadRequest: response.data[0],
    status: response.data[0].leadStages,
    notes: response.data[0].activityLogs,
    loadedFFR: true
  }
})

export const addUser = createAsyncThunk('crmLeads/addUser', async (user) => {
  const response = await axios.post(`${urlIdentityService}/Register`, user)
  if (response.data.userId !== null) {
    const contact = {
      ...user,
      userId : response.data.userId
    }
    await axios.post(`${urlCRMContactService}/AddContact`, contact)
  }
  return user
})

export const addLeads = createAsyncThunk('crmLeads/addLeads', async (LeadsViewModel, { dispatch, getState }) => {
  await axios.post(`${urlCRMLeadService}/AddLeads`, LeadsViewModel)
  await dispatch(getData(getState().crmLeads.params))
  await dispatch(getAllData())
  return LeadsViewModel
})

export const addDeals = createAsyncThunk('crmLeads/addDeals', async (DealsViewModel, { dispatch }) => {
  await axios.post(`${urlCRMDealService}/AddDeals`, DealsViewModel)
  await dispatch(getLeads(DealsViewModel.leadsId))
  return DealsViewModel
})

export const updateLeads = createAsyncThunk('crmLeads/updateLeads', async (LeadsUpdateModel, { dispatch }) => {
  await axios.put(`${urlCRMLeadService}/UpdateLeads`, LeadsUpdateModel)
  await dispatch(getLeads(LeadsUpdateModel.id))
  return LeadsUpdateModel
})

export const addNotes = createAsyncThunk('crmLeads/addNotes', async (ActivityLogsViewModel, { dispatch }) => {
  await axios.post(`${urlCRMLeadService}/AddActivityLogs`, ActivityLogsViewModel)
  await dispatch(getLeads(ActivityLogsViewModel.leadsId))
  return ActivityLogsViewModel
})

export const updateLeadStages = createAsyncThunk('crmLeads/updateLeadStages', async (leadStageUpdateModel, { dispatch }) => {
  await axios.post(`${urlCRMLeadService}/UpdateLeadStage`, leadStageUpdateModel)
  await dispatch(getLeads(leadStageUpdateModel.leadsId))
  return leadStageUpdateModel
})

export const updateLeadToContact = createAsyncThunk('crmLeads/updateLeadToContact', async (updateLeadToContactViewModel) => {
  await axios.put(`${urlCRMContactService}/UpdateLeadToContact`, updateLeadToContactViewModel)
  return updateLeadToContactViewModel
})

export const updateLeadStage = async (leadStageUpdateModel) => {
  await axios.post(`${urlCRMLeadService}/UpdateLeadStage`, leadStageUpdateModel)
  return leadStageUpdateModel
}

export const getLeadsSalesRep =  createAsyncThunk('crmLeads/getLeadsSalesRep', async () => {
  const response = await axios.get(`${urlCRMLeadService}/GetLeadsSalesRep`)
  const salesRep = [{ value: '', label: 'Select...' }, ...response.data]
  return salesRep
})

export const getLeadsContact =  createAsyncThunk('crmLeads/getLeadsContact', async () => {
  const response = await axios.get(`${urlCRMLeadService}/GetLeadsContact`)
  const leadContact = [{ value: '', label: 'Select...' }, ...response.data]
  return leadContact
})

export const getLeadsCompany =  createAsyncThunk('crmLeads/getLeadsCompany', async () => {
  const response = await axios.get(`${urlCRMLeadService}/GetLeadsCompany`)
  const leadCompany  = [{ value: '', label: 'Select...' }, ...response.data]
  return leadCompany
})

export const getAllContactsSelect =  createAsyncThunk('crmDeals/getAllContactsSelect', async (type) => {
  const response = await axios.get(`${urlCRMContactService}/GetAllContactsSelect?type=${type}`)
  const allContacts  = [{ value: null, label: 'Select...', fullName:null, email:null, phone: null, companyId: null}, ...response.data]
  return allContacts
})

export const crmLeadsSlice = createSlice({
  name: 'crmLeads',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    leadRequest: null,
    status: [],
    notes: [],
    allSalesRep:[],
    allLeadContact:[],
    allLeadCompany:[],
    allContacts:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLeads.pending, (state) => {
        state.loadedFFR = false
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.leadRequest = action.payload.leadRequest
        state.status = action.payload.status
        state.notes = action.payload.notes
        state.loadedFFR = action.payload.loadedFFR
      })
      .addCase(getLeadsSalesRep.fulfilled, (state, action) => {
        state.allSalesRep = action.payload
      })
      .addCase(getLeadsContact.fulfilled, (state, action) => {
        state.allLeadContact = action.payload
      })
      .addCase(getLeadsCompany.fulfilled, (state, action) => {
        state.allLeadCompany = action.payload
      })
      .addCase(getAllContactsSelect.fulfilled, (state, action) => {
        state.allContacts = action.payload
      })
  }
})

export default crmLeadsSlice.reducer

crmLeadsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Leads - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/leads/store/index.js',
  prodUrl: ''
}
