// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlFreightForwardService, urlIdentityService, urlOceanBookingService, urlDriverService, urlTerminalService, urlEquipmentService, urlMovementService, urlEdiRecords, urlConfigCacheService} from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('ffRequests/getAllData', async () => {
  const response = await axios.get(`${urlFreightForwardService}/GetFFStatusCount`)
  return response.data
})

export const getData = createAsyncThunk('equipment/getData', async data => {
  const { params, filter } = data

  const uri =  getUriParams(params)
  const filterData = {
    movementType: filter.movementType || "",
    yardLocation: filter.terminal || "ALL",
    customerName: filter.customerName === "ALL" || filter.customerName === "" ? "" : filter.customerName || "",
    line: filter.line || "",
    referenceNumber: filter.referenceNumber || "",
    equipmentStatus: filter.equipmentStatus || "",
    commodityType: filter.commodityType || "",
    equipmentType: filter.equipmentType || "ALL",
    from: filter.from || null,
    to: filter.to || null,
    destination: filter.destination || "",
    origin: filter.origin || "",
    cargoType: filter.cargoType || "",
    commodity: filter.commodity || "",
    grade: filter.grade || "",
    lotNumber: filter.lotNumber || "",
    lastTracedLocation: filter.lastTracedLocation || "",
    servingYard: filter.servingYard || "",
    tracingStatus: filter.tracingStatus || "",
    equipmentSize: filter.equipmentSize || "",
    ...(filter.tracingStatus !== 'TRANSIT' ? { [filter.tracingStatus]: filter.tracingDate } : {})
    }
  const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getFFRequest = createAsyncThunk('ffRequests/getFFRequest', async id => {
  const response = await axios.get(`${urlFreightForwardService}/GetFFRequest/${id}`)
  const customer = await axios.get(`${urlIdentityService}/${response.data[0].customerUserId}`)
  return {
    selectedCustomer: customer.data.data[0],
    ffRequest: response.data[0],
    status: response.data[0].status,
    oceanBookings: response.data[0].oceanBookings,
    notes: response.data[0].notes
  }
})

export const getEquipment = async (equipmentId) => {
  try {
    const response = await axios.get(`${urlEquipmentService}/GetEquipment/${equipmentId}`)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
    return {success: false, message: 'Error getting equipment'}
  }
}

///Equipment/UpdateEquipment
export const updateEquipment = async (equipment) => {
  //remove "addInfo" property from equipment "movement" array in object
    equipment.movements.forEach(m => {
      delete m.addInfo
    })
  try {
    const response = await axios.post(`${urlEquipmentService}/UpdateEquipment`, equipment)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
    console.log('error', error)
    return {success: false, message: 'Error updating equipment'}
  }
}

export const setEquipmentDates = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/SetEquipmentDates`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
    return {success: false, message: 'Error updating equipment'}
  }
}

export const getAllPositions = async (data) => {
  try {
    const response = await axios.post(`${urlMovementService}/GetAllPosition`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.messages[0]}
    }
  } catch (error) {
    return {success: false, message: 'Error getting equipment status'}
  }
}

//Equipment/AssignPosition
export const assignPosition = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/AssignPosition`, data)
    return response.data
  } catch (error) {
    return {success: false, message: 'Error assigning position'}
  }
}

export const assignTrack = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/SetTrackSequence`, data)
    return response.data
  } catch (error) {
    return {success: false, message: 'Error assigning truck'}
  }
}

export const setReferenceNumber = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/SetReferenceNumber`, data)
    return response.data
  } catch (error) {
    return {success: false, message: 'Error setting reference number'}
  }
}

//Equipment/AssignPosition
export const assignTrucker = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/AssignTruckingCompany`, data)
    return response.data
  } catch (error) {
    return {success: false, message: 'Error assigning trucker'}
  }
}

//Equipment/AssignOceanBooking
export const assignOceanBooking = async (data) => {
  try {
    const response = await axios.post(`${urlEquipmentService}/AssignOceanBooking`, data)
    return response.data
  } catch (error) {
    return {success: false, message: 'Error assigning ocean booking'}
  }
}

///DeleteEquipment/64c97d67215bc78157dffea9
export const deleteEquipment = async (equipmentId) => {
  try {
    const response = await axios.delete(`${urlEquipmentService}/DeleteEquipment/${equipmentId}`)
    if (response.data.success) {
      return {success: true}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
    return {success: false, message: 'Error deleting equipment'}
  }
}

export const getOceanBookingList = createAsyncThunk('ffRequests/getOceanBookingList', async oceanBookingIds => {
  const response = await axios.post(`${urlOceanBookingService}/getOceanBookingList`, oceanBookingIds)
  return {
    oceanBookingList: response.data.data.allData
  }
})

export const addIngate = createAsyncThunk('movement/addIngate', async (IngateViewModel, { dispatch, getState }) => {
  await axios.post(`${urlTerminalService}/AddMovement`, IngateViewModel)
  await dispatch(getData(getState().movement.params))
  await dispatch(getAllData())
  return IngateViewModel
})

export const updateFFRequest = createAsyncThunk('ffRequests/updateFFRequest', async (FFRequestUpdateModel, { dispatch }) => {
  await axios.put(`${urlFreightForwardService}/UpdateFFRequest`, FFRequestUpdateModel)
  await dispatch(getFFRequest(FFRequestUpdateModel.id))
  return FFRequestUpdateModel
})

export const updateIngate = createAsyncThunk('movements/updateIngate', async (IngateUpdateModel, { dispatch }) => {
  await axios.put(`${urlTerminalService}/Update`, IngateUpdateModel)
  await dispatch(getFFRequest(IngateUpdateModel.id))
  return IngateUpdateModel
})

export const addNotes = createAsyncThunk('ffRequests/addNotes', async (FFNotesViewModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/AddFFNotes`, FFNotesViewModel)
  await dispatch(getFFRequest(FFNotesViewModel.FFRequestId))
  return FFNotesViewModel
})

export const updateStatus = createAsyncThunk('ffRequests/updateStatus', async (FFStatusUpdateModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/UpdateFFStatus`, FFStatusUpdateModel)
  await dispatch(getFFRequest(FFStatusUpdateModel.FFRequestId))
  return FFStatusUpdateModel
})

export const assignFFOceanBooking = createAsyncThunk('ffRequests/assignFFOceanBooking', async (FFOceanBookingAddModel, { dispatch }) => {
  const OceanBookingNumberModel = {
    OceanBookingNumber : FFOceanBookingAddModel.OceanBookingNumber
  }
  const OceanBookingId = await axios.post(`${urlOceanBookingService}/GetOceanBookingId`, OceanBookingNumberModel)
  const FFOceanBookingAssignModel = {   
    FFRequestId : FFOceanBookingAddModel.FFRequestId,
    OceanBookingId : OceanBookingId.data.data.oceanBookigId,
    notes : FFOceanBookingAddModel.notes,
    noteType : FFOceanBookingAddModel.noteType
  }
  await axios.post(`${urlFreightForwardService}/AssignFFOceanBooking`, FFOceanBookingAssignModel)
  await dispatch(getFFRequest(FFOceanBookingAssignModel.FFRequestId))
  return FFOceanBookingAssignModel
})

// ================== EQUIPMENT ==================

export const getInventoryData = createAsyncThunk('equipment/getInventoryData', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'lastDateIn'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? filters : {}
  const response = await axios.post(`${urlEquipmentService}/GetEquipmentInventory?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.totalCount || 1
  }
})

export const getStorageData = createAsyncThunk('equipment/getStorageData', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'movementDate'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? filters : {}
  const response = await axios.post(`${urlEquipmentService}/GetEquipmentInventory?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 1
  }
})

export const presendEDI = createAsyncThunk('equipment/presendEDI', async (data, { dispatch }) => {
  const {rows, params, filters} = data
  const response = await axios.post(`${urlEdiRecords}/AddMultipleEDIRecord`, rows)
  if (response.data.success) {
    dispatch(getInventoryData({params, filters}))
  }
    return response.data
})

//get all yard locations
export const getAllYardLocations = createAsyncThunk('equipment/getAllYardLocations', async () => {
  const response = await axios.get(`${urlConfigCacheService}/YardLocation`)
  return response.data
})

// export const getDrivers = async (params) => {

//   const uri =  `${getUserUriParams(params)}${params?.company ? `&company=${params.company}` : ''}`
//   const response = await axios.get(`${urlIdentityService}/GetAllUsers?${uri}`)
//   if (response.data.success) {
//     return {success: true, data: response.data.data}
//   } else {
//     return {success: false, message: response.data.errors[0], data: []}
//   }
// }

// Updated Driver Endpoint
export const getDrivers = async (data) => {
  console.log('ENDPOINT', data)
  const { params, filters } = data
  console.log(params, filters)
  const uri = `sort=${params.sort || 'desc'}&page=${params.page || 1}&perPage=${params.perPage || 500}&q=${params.q || ''}`
  console.log(uri)
  const response = await axios.post(`${urlDriverService}/GetTruckDrivers?${uri}`, filters)
  console.log(uri)
  if (response.data.success) {
    return {
      success: true,
      data: response.data.data
    }
  } else {
    return {
      success: false,
      message: response.data.messages[0]
    }
  }
}

export const findMultipleEqOnSchedule = async (data) => {
  const res = await axios.post(`${urlEquipmentService}/FindMultipleEquipmentOnSchedule`, data)
  if (res.data.success) {
    return {success: true, data: res.data.data}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
}

export const addOrUpdateMultipleEq = async (data) => {
  try {
    const res = await axios.post(`${urlEquipmentService}/AddOrUpdateMultipleEquipment`, data)
  if (res.data.success) {
    return {success: true, data: res.data.data}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
  } catch (error) {
    return {success: false, message: 'Error adding equipment'}
  }
}

// Upload Excel Files
export const uploadCTCExcelFile = async (data) => {
  const res = await axios.post(`${urlMovementService}/UploadCTCExcelFile`, data)
  if (res.data.success) {
    return {success: true, data: res.data.data, messages: res.data.messages}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
}

export const uploadCRSAExcelFile = async (data) => {
  const res = await axios.post(`${urlMovementService}/UploadCRSAExcelFile`, data)
  if (res.data.success) {
    return {success: true, data: res.data.data, messages: res.data.messages}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
}

export const uploadBlockedEquipmentFile = async (data) => {
  const res = await axios.post(`${urlMovementService}/UploadBlockedEquipmentFile`, data)
  if (res.data.success) {
    return {success: true, data: res.data.data, messages: res.data.messages}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
}

//Get all dispatch data
export const GetAllDispatch = async (params, filters) => {
  const uri = `page=${params?.page || 1}&perPage=${params?.perPage || 1000}&status=${params?.status || ""}&q=${params?.q || ""}&sort=${params?.sort || "asc"}&sortColumn=${params?.sortColumn || "id"}`
  if (filters && filters.fromDate === "") {
    delete filters.fromDate
    delete filters.toDate
  }
  try {
    const response = await axios.post(`${urlDispatchService}/GetDispatch?${uri}`, filters || {})
    // const response = await axios.post(`${urlDispatchService}/GetDispatch`, {})
    if (response.status === 200) {
      return {data: response.data, success: true}
    } else {
      return {success: false, message: 'Internal server Error'}
    }
  } catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
}

//Retrieve all block equipment
export const getBlockedEquipment = createAsyncThunk('equipment/getBlockedEquipment', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'lastDateIn'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? filters : {}
  const response = await axios.post(`${urlEquipmentService}/GetBlockedEquipment?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.totalCount || 1
  }
})

export const setBlockEquipment = async (data) => {
    const response = await axios.post(`${urlEquipmentService}/BlockEquipment`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.messages}
    }
}

//Get Container Codes from ConfigCache
export const getContainerSize = createAsyncThunk('configCache/containerSize', async () => {
  const response = await axios.get(`${urlConfigCacheService}/ContainerSize`)
  return response.data

})

// /ConfigCache/ContainerSize
export const getContainerSizeData = createAsyncThunk(
  "companyConfig/getContainerSizeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}?type=ContainerSize`)
    return response.data ? response.data.sort((a, b) => a.id.localeCompare(b.id)) : []
  }
)

// Movement - Get RefNumbers from location
export const getRefNumbers = createAsyncThunk('movement/getMovementReferences', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)
  const filterData = {
    type: filter.type || "",
    referenceNumber: filter.referenceNumber || "",
    equipmentSize: filter.equipmentSize || "",
    ssLineCode: filter.ssLineCode || ""
  }
  const response = await axios.post(`${urlMovementService}/GetMovementReferences?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount
  }
})

export const ffRequestsSlice = createSlice({
  name: 'ffRequests',
  initialState: {
    data: [],
    total: 1,
    params: {
      sort: "desc",
      sortColumn: "MovementDate",
      q: '',
      page: 1,
      perPage: 10,
      status: ""
    },
    filter: {
      movementType: "ingate",
      yardLocation: "kingsway",
      customerName: "",
      line: "",
      referenceNumber: "",
      equipmentStatus: "",
      assignmentType: "",
      equipmentType: "",
      from: null,
      to: null
    },
    allData: {},
    selectedCustomer: null,
    ffRequest: null,
    status: [],
    yardLocations: [],
    containerSize: [],
    oceanBookings: [],
    oceanBookingList: [],
    notes: [],
    loading: false,
    refNumbers: [],
    equipment: {
      inventory:{
        data: [],
        loading: false,
        total: 1,
        params: {},
        filter: {},
        notes: [],
        errors: [],
        successMessages: []
    }, 
    storage: {
        data: [],
        loading: false,
        total: 1,
        params: {},
        filter: {},
        notes: []
    }
    }
  },
  reducers: {
    clearErrors: (state) => {
      state.equipment.inventory.errors = []
      // state.equipment.storage.errors = []
    },
    clearSuccessMessages: (state) => {
      state.equipment.inventory.successMessages = []
      // state.equipment.storage.successMessages = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
        state.loading = false
      })
      .addCase(getInventoryData.fulfilled, (state, action) => {
        state.equipment.inventory.data = action.payload.data || []
        state.equipment.inventory.params = action.payload.params
        state.equipment.inventory.filter = action.payload.filterData
        state.equipment.inventory.total = action.payload.totalPages
        state.equipment.inventory.loading = false
      })
      .addCase(getBlockedEquipment.fulfilled, (state, action) => {
        state.equipment.inventory.data = action.payload.data || []
        state.equipment.inventory.params = action.payload.params
        state.equipment.inventory.filter = action.payload.filterData
        state.equipment.inventory.total = action.payload.totalPages
        state.equipment.inventory.loading = false
      })
      .addCase(presendEDI.fulfilled, (state, action) => {
        state.equipment.inventory.errors = action.payload.errors || []
        state.equipment.inventory.successMessages = action.payload.messages || []
        state.equipment.inventory.loading = false
      })
      .addCase(presendEDI.rejected, (state) => {
        state.equipment.inventory.loading = false
      })
      .addCase(getStorageData.fulfilled, (state, action) => {
        state.equipment.storage.data = action.payload.data || []
        state.equipment.storage.params = action.payload.params
        state.equipment.storage.filter = action.payload.filterData
        state.equipment.storage.total = action.payload.totalPages
        state.equipment.storage.loading = false
      })
      .addCase(getFFRequest.fulfilled, (state, action) => {
        state.selectedCustomer = action.payload.selectedCustomer
        state.ffRequest = action.payload.ffRequest
        state.status = action.payload.status
        state.oceanBookings = action.payload.oceanBookings
        state.notes = action.payload.notes
      })
      .addCase(getOceanBookingList.fulfilled, (state, action) => {
        state.oceanBookingList = action.payload.oceanBookingList
      })
      .addCase(getData.pending, state => {
        state.loading = true
        state.data = []
      })
      .addCase(getData.rejected, (state) => {
        state.loading = false
        state.data = []
      })
      .addCase(getInventoryData.pending, state => {
        state.equipment.inventory.loading = true
        state.equipment.inventory.data = []
      })
      .addCase(getInventoryData.rejected, (state) => {
        state.equipment.inventory.loading = false
        state.equipment.inventory.data = []
      })
      .addCase(getBlockedEquipment.pending, state => {
        state.equipment.inventory.loading = true
        state.equipment.inventory.data = []
      })
      .addCase(getBlockedEquipment.rejected, (state) => {
        state.equipment.inventory.loading = false
        state.equipment.inventory.data = []
      })
      .addCase(getStorageData.pending, state => {
        state.equipment.storage.loading = true
        state.equipment.storage.data = []
      })
      .addCase(getStorageData.rejected, (state) => {
        state.equipment.storage.loading = false
        state.equipment.storage.data = []
      })
      .addCase(getAllYardLocations.fulfilled, (state, action) => {
        state.yardLocations = action.payload
      })
      .addCase(getContainerSize.fulfilled, (state, action) => {
        state.containerSize = action.payload
      })
      .addCase(getContainerSizeData.fulfilled, (state, action) => {
        state.containerSizes = action.payload
      })
      .addCase(getRefNumbers.fulfilled, (state, action) => {
        state.refNumbers = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const { clearErrors, clearSuccessMessages } = ffRequestsSlice.actions

export default ffRequestsSlice.reducer
