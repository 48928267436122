// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlCRMContactService, urlCRMDealService, urlCRMLeadService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('crmDeals/getAllData', async () => {
  const response = await axios.get(`${urlCRMDealService}/GetDealStageCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('crmDeals/getData', async params => {
  const uri =  getUriParams(params)
  const DealFilter = {
    query: params.query,
    companyId: params.companyId,
    dealUserId: params.dealUserId,
    dealOwnerId: params.dealOwnerId,
    dealSource: null,
    tags: null,
    comments: null,
    fromDate: params.fromDate,
    toDate: params.toDate,
    stageType: params.stageType,
    dealType: params.dealType,
    priority: params.priority,
    rating: params.rating,
    isSoftDeleted: false
  }
  const response = await axios.post(`${urlCRMDealService}/GetAllDeals?${uri}`, DealFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const getDeals = createAsyncThunk('crmDeals/getDeals', async id => {
  const response = await axios.get(`${urlCRMDealService}/GetDeals/${id}`)
  return {
    dealRequest: response.data[0],
    status: response.data[0].dealStages,
    notes: response.data[0].activityLogs,
    loadedFFR: true
  }
})

export const addDeals = createAsyncThunk('crmDeals/addDeals', async (DealsViewModel, { dispatch }) => {
  await axios.post(`${urlCRMDealService}/AddDeals`, DealsViewModel)
  await dispatch(getDeals(DealsViewModel.dealsId))
  return DealsViewModel
})

export const updateDeals = createAsyncThunk('crmDeals/updateDeals', async (DealsUpdateModel, { dispatch }) => {
  await axios.put(`${urlCRMDealService}/UpdateDeals`, DealsUpdateModel)
  await dispatch(getDeals(DealsUpdateModel.id))
  return DealsUpdateModel
})

export const addActivityLogs = createAsyncThunk('crmDeals/addActivityLogs', async (ActivityLogsViewModel, { dispatch }) => {
  await axios.post(`${urlCRMDealService}/AddActivityLogs`, ActivityLogsViewModel)
  await dispatch(getDeals(ActivityLogsViewModel.dealsId))
  return ActivityLogsViewModel
})

export const updateLeadStages = createAsyncThunk('crmDeals/updateLeadStages', async (leadStageUpdateModel, { dispatch }) => {
  await axios.post(`${urlCRMLeadService}/UpdateLeadStage`, leadStageUpdateModel)
  await dispatch(getDeals(leadStageUpdateModel.leadsId))
  return leadStageUpdateModel
})

export const updateDealStages = createAsyncThunk('crmDeals/updateDealStages', async (dealStageUpdateModel, { dispatch }) => {
  await axios.post(`${urlCRMDealService}/UpdateDealStage`, dealStageUpdateModel)
  await dispatch(getDeals(dealStageUpdateModel.dealsId))
  return dealStageUpdateModel
})

export const updateDealStage = async (dealStageUpdateModel) => {
  await axios.post(`${urlCRMDealService}/UpdateDealStage`, dealStageUpdateModel)
  return dealStageUpdateModel
}

export const getDealsOwner =  createAsyncThunk('crmDeals/getDealsOwner', async () => {
  const response = await axios.get(`${urlCRMDealService}/GetDealsOwner`)
  const dealOwner = [{ value: '', label: 'Select...' }, ...response.data]
  return dealOwner
})

export const getDealsContact =  createAsyncThunk('crmDeals/getDealsContact', async () => {
  const response = await axios.get(`${urlCRMDealService}/GetDealsContact`)
  const dealContact = [{ value: '', label: 'Select...' }, ...response.data]
  return dealContact
})

export const getDealsCompany =  createAsyncThunk('crmDeals/getDealsCompany', async () => {
  const response = await axios.get(`${urlCRMDealService}/GetDealsCompany`)
  const dealCompany  = [{ value: '', label: 'Select...' }, ...response.data]
  return dealCompany
})

export const getAllContactsSelect =  createAsyncThunk('crmDeals/getAllContactsSelect', async (type) => {
  const response = await axios.get(`${urlCRMContactService}/GetAllContactsSelect?type=${type}`)
  const allContacts  = [{ value: null, label: 'Select...', fullName:null, email:null, phone: null, companyId: null}, ...response.data]
  return allContacts
})

export const crmDealsSlice = createSlice({
  name: 'crmDeals',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    dealRequest: null,
    status: [],
    notes: [],
    allDealOwner:[],
    allDealContact:[],
    allDealCompany:[],
    allContacts:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDeals.pending, (state) => {
        state.loadedFFR = false
      })
      .addCase(getDeals.fulfilled, (state, action) => {
        state.dealRequest = action.payload.dealRequest
        state.status = action.payload.status
        state.notes = action.payload.notes
        state.loadedFFR = action.payload.loadedFFR
      })
      .addCase(getDealsOwner.fulfilled, (state, action) => {
        state.allDealOwner = action.payload
      })
      .addCase(getDealsContact.fulfilled, (state, action) => {
        state.allDealContact = action.payload
      })
      .addCase(getDealsCompany.fulfilled, (state, action) => {
        state.allDealCompany = action.payload
      })
      .addCase(getAllContactsSelect.fulfilled, (state, action) => {
        state.allContacts = action.payload
      })
  }
})

export default crmDealsSlice.reducer

crmDealsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}
