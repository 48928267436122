// 👇 API EndPoints
import {
  urlIdentityService,
  urlTimeSheetService,
  urlAuthService,
  urlDailyPlannigService
} from "@src/endpoints"
import { getUserUriParams } from "@src/utility/Utils" //, getNoOfMinutes
// 👇 Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// 👇 Axios Imports
import axios from "axios"
// 👇 Third-Party component
import toast from "react-hot-toast"
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"
import { handleLogin } from "@store/authentication" // 👈️ Actions 


const ToastMessage = (heading, message, isSuccess) => {
  toast((t) => (
    <ToastContent
      t={t}
      heading={heading}
      response={isSuccess}
      message={message}
    />
  ))
}

export const getAllData = createAsyncThunk("cardTapAccess/getAllData", async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllUsersCount`)
  return response.data
})

export const getData = createAsyncThunk("cardTapAccess/getData", async (params) => {
  const uri = `${getUserUriParams(params)}`
  const response = await axios.get(`${urlIdentityService}/GetAllUsers?${uri}`)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

export const getUser = createAsyncThunk("cardTapAccess/getUser", async (id) => {
  const response = await axios.get(
    `${urlIdentityService}/GetUserDetailsById/${id}`
  )
  return {
    data: response.data,
    userLoaded: true
  }
})

export const getTimeSheetListByProfileId = createAsyncThunk(
  "cardTapAccess/getTimeSheetListByProfileId",
  async (profileId) => {
    const response = await axios.get(
      `${urlTimeSheetService}/GetTimeSheetByProfileId?profileId=${profileId}`
    )
    return response.data.data
  }
)

export const addUser = createAsyncThunk(
  "cardTapAccess/addUser",
  async (user, { dispatch, getState }) => {
    await axios.post(`${urlIdentityService}/Register`, user)
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return user
  }
)

export const updateUser = createAsyncThunk(
  "cardTapAccess/updateUser",
  async (userProfileUpdateViewModel, { dispatch }) => {
    const response = await axios.put(
      `${urlIdentityService}/UserProfileUpdate`,
      userProfileUpdateViewModel
    )
    response.data.success === true ? ToastMessage("Profile", response.data.messages[0], true) : ToastMessage("Profile", response.data.errors[0], false)
    await dispatch(getUser(userProfileUpdateViewModel.id))
    return userProfileUpdateViewModel
  }
)

export const cardAccess = createAsyncThunk(
  "cardTapAccess/cardAccess",
  async (cardAccessViewModel, { dispatch }) => {
    const response = await axios.post(
      `${urlAuthService}/CardAccess`,
      cardAccessViewModel
    )
    if (response.data.success === true) {
      const userData = response.data.data.userData
      const data = {
        ...response.data.data.userData,
        accessToken: response.data.data.accessToken,
        refreshToken: response.data.data.refreshToken
      }
      dispatch(handleLogin(data))
      const DailyPlannerFilter = {
        userId: userData.id,
        startDate: new Date().toLocaleString('en-US', { hour12: true })
      }
      const resDailyPlan = await axios.post(`${urlDailyPlannigService}/GetAllDailyPlan`, DailyPlannerFilter)
      const ClockInOutStatusViewModel = {
        userId: userData.id,
        startDateTime: new Date().toLocaleString("en-US", { hour12: true })
      }
      const resStatus = await axios.post(
        `${urlTimeSheetService}/GetClockInOutStatus`,
        ClockInOutStatusViewModel
      )
      if (resStatus.data.isClockIn === false) {
        
        return {
          userClockIn: true,
          selectedUser: userData,
          dailyPlan: resDailyPlan.data,
          startDateTime: resStatus.data.startDateTime
        }
      } else {
        // const endDateTime = new Date().toLocaleString("en-US", {
        //   hour12: true
        // })
        // const minutes = getNoOfMinutes(
        //   resStatus.data.startDateTime,
        //   endDateTime
        // )

        // const ClockOutViewModel = {
        //   userId: userData.id,
        //   endDateTime,
        //   minutes,
        //   notes: "Clock Out",
        //   clockOutGeoLocation: cardAccessViewModel.deviceLocationIP
        // }
        // const ClockOut = await axios.put(
        //   `${urlTimeSheetService}/ClockOut`,
        //   ClockOutViewModel
        // )
        // ToastMessage("Clock-Out", "Clock-Out Successfully", true)
       
        return {
          userClockIn: false,
          selectedUser: userData,
          dailyPlan: resDailyPlan.data,
          startDateTime: resStatus.data.startDateTime
        }
      }
      
    } else {
      ToastMessage("Invalid", response.data.errors[0], false)
      return {
        userClockIn: null
      }
    }
  }
)

export const addNewTimeSheet = createAsyncThunk(
  "cardTapAccess/addNewTimeSheet",
  async (TimeSheetViewModel) => {
    await axios.post(
          `${urlTimeSheetService}/addNewTimeSheet`,
          TimeSheetViewModel
        )
        ToastMessage("Clock-In", "You are clocked in!", true)
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        window.location.href = '/clock'
    return TimeSheetViewModel
  }
)

export const updateTimeSheet = createAsyncThunk(
  "cardTapAccess/updateTimeSheet",
  async (TimeSheetViewModel) => {
    await axios.put(
          `${urlTimeSheetService}/ClockOut`,
          TimeSheetViewModel
        )
        ToastMessage("Clock-In", "You are clocked out!", true)
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        window.location.href = '/clock'
    return TimeSheetViewModel
  }
)

export const appUsersSlice = createSlice({
  name: "cardTapAccess",
  initialState: {
    loaded: false,
    userLoaded: false,
    userClockIn: null,
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: {},
    dailyPlan: [],
    startDateTime: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.pending, (state) => {
        state.userLoaded = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoaded = true
        state.selectedUser = action.payload.data
      })
      .addCase(cardAccess.pending, (state) => {
        state.userClockIn = null
        state.selectedUser = null
        state.dailyPlan = []
        state.startDateTime = null
      })
      .addCase(cardAccess.fulfilled, (state, action) => {
        state.userClockIn = action.payload.userClockIn
        state.selectedUser = action.payload.selectedUser
        state.dailyPlan = action.payload.dailyPlan
        state.startDateTime = action.payload.startDateTime
      })
  }
})

export default appUsersSlice.reducer
