import config from '../config'
// ** Utils
import { paginateArray } from '../utils'

const data = {
  status: [
    {
      type: 'FreightForwardStatus',
      value: 'Initial Request',
      label: 'Initial Request',
      name: 'Initial Request',
      description: 'User Created Request',
      order: 1,
      status: true,
      color: 'info',
      textColor: 'info',
      percentage: 10
    },
    {
      type: 'FreightForwardStatus',
      value: 'Request Pending',
      label: 'Request Pending',
      name: 'Request Pending',
      description: 'Request Pending for Approval',
      order: 2,
      status: true,
      color: 'light-info',
      textColor: 'info',
      percentage: 30
    },
    {
      type: 'FreightForwardStatus',
      value: 'Booking Processing',
      label: 'Booking Processing',
      name: 'Booking Processing',
      description: 'Booking is in Process',
      order: 3,
      status: true,
      color: 'dark',
      textColor: 'dark',
      percentage: 50
    },
    {
      type: 'FreightForwardStatus',
      value: 'ACTIVE',
      label: 'ACTIVE',
      name: 'ACTIVE',
      description: 'ACTIVE',
      order: 3,
      status: true,
      color: 'dark',
      textColor: 'dark',
      percentage: 50
    },
    {
      type: 'FreightForwardStatus',
      value: 'Booking Assigned',
      label: 'Booking Assigned',
      name: 'Booking Assigned',
      description: 'Booking is Assigned to Agent',
      order: 4,
      status: true,
      color: 'danger',
      textColor: 'danger',
      percentage: 60
    },
    {
      type: 'FreightForwardStatus',
      value: 'Booking Rejected',
      label: 'Booking Rejected',
      name: 'Booking Rejected',
      description: 'Booking is Rejected by Agent',
      order: 5,
      status: true,
      color: 'primary',
      textColor: 'primary',
      percentage: 0
    },
    {
      type: 'FreightForwardStatus',
      value: 'Booking Sent',
      label: 'Booking Sent',
      name: 'Booking Sent',
      description: 'Booking is Sent to port',
      order: 6,
      status: true,
      color: 'warning',
      textColor: 'warning',
      percentage: 70
    },
    {
      type: 'FreightForwardStatus',
      value: 'Cargo Attached',
      label: 'Cargo Attached',
      name: 'Cargo Attached',
      description: 'Cargo Attached to Booking',
      order: 7,
      status: true,
      color: 'warning',
      textColor: 'warning',
      percentage: 80
    },
    {
      type: 'FreightForwardStatus',
      value: 'Loading Completed',
      label: 'Loading Completed',
      name: 'Completed',
      description: 'Loading Completed',
      order: 9,
      status: true,
      color: 'light-success',
      textColor: 'light-success',
      percentage: 90
    },
    // {
    //   type: 'FreightForwardStatus',
    //   value: 'COMPLETED',
    //   label: 'Completed',
    //   name: 'Completed',
    //   description: 'Completed',
    //   order: 9,
    //   status: true,
    //   color: 'light-success',
    //   textColor: 'light-success',
    //   percentage: 90
    // },
    {
      type: 'FreightForwardStatus',
      value: 'Order Completed',
      label: 'Order Completed',
      name: 'Order Completed',
      description: 'Order Completed',
      order: 8,
      status: true,
      color: 'success',
      textColor: 'success',
      percentage: 100
    }
  ]
}

// GET ALL DATA
config.onGet('/api/freight-forward/status/all-status').reply(200, data.status.sort((a, b) => (a.order > b.order ? 1 : -1)))

// GET Updated DATA
config.onGet('/api/freight-forward/status/data').reply(params => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    sortColumn = 'value'
  } = params

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.status.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    _status =>
      (_status.type.toLowerCase().includes(queryLowered) ||
        _status.label.toLowerCase().includes(queryLowered) ||
        _status.description.toLowerCase().includes(queryLowered)) &&
      _status.status === (status || _status.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      status: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET FF Status
config.onGet('/api/freight-forward/status').reply(params => {
  const { id } = params
  const status = data.status.find(i => i.value === id)
  return [200, { status }]
})

