// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlCRMContactService, urlTimeSheetService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('hrmTimeSheets/getAllData', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetAllTimeSheetsCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('hrmTimeSheets/getData', async params => {
  const uri =  getUriParams(params)
  const TimeSheetFilter = {
    userId: params.userId,
    fullName: params.fullName,
    punchType: params.punchType,
    startDateTime: params.startDateTime,
    endDateTime: params.endDateTime,
    deviceName: params.deviceName,
    siteLocation: params.siteLocation,
    status: params.status,
    isSoftDeleted: params.isSoftDeleted,
    reportForgotClockOut: params.reportForgotClockOut ?? null
  }
  console.log('TimeSheetFilter', TimeSheetFilter)
  const response = await axios.post(`${urlTimeSheetService}/GetAllTimeSheets?${uri}`, TimeSheetFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})
 
export const updateTimeSheetStatus = async (statusUpdateModel) => {
  await axios.post(`${urlTimeSheetService}/UpdateTimeSheetStatus`, statusUpdateModel)
  return statusUpdateModel
}

export const getTimeSheetsUser =  createAsyncThunk('hrmTimeSheets/getTimeSheetsUser', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetTimeSheetsUser`)
  const allTimeSheetUser = [{ value: '', label: 'Select...' }, ...response.data]
  return allTimeSheetUser
})

export const getAllContactsSelect =  createAsyncThunk('hrmTimeSheets/getAllContactsSelect', async (type) => {
  const response = await axios.get(`${urlCRMContactService}/GetAllContactsSelect?type=${type}`)
  const allContacts  = [{ value: null, label: 'Select...', fullName:null, email:null, phone: null, companyId: null}, ...response.data]
  return allContacts
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'hrmTimeSheets',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser:[],
    allContacts:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTimeSheetsUser.fulfilled, (state, action) => {
        state.allTimeSheetUser = action.payload
      })
      .addCase(getAllContactsSelect.fulfilled, (state, action) => {
        state.allContacts = action.payload
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'TimeSheet - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/hrm/time-sheet/store/index.js',
  prodUrl: ''

}