import { Monitor, Cloud, Coffee } from 'react-feather' // 👈️ Icons Import
import { useSkin } from '@hooks/useSkin'// 👈️ Custom Hooks
const Footer = () => {
  const { skin } = useSkin()
  const REACT_APP_SERVER = process.env.REACT_APP_SERVER
  const logo = skin === "dark" ? "logo-01.png" : "logo-02.png",
  path = require(`@src/assets/images/pages/${logo}`).default
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-start d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a href='https://www.wtcgroup.com/' target='_blank' rel='noopener noreferrer'>
          <img style={{width:"10%"}}  src={path} alt="WTC Logo" />
        </a>
        <span className='d-none d-sm-inline-block'> &nbsp;All rights Reserved</span>
      </span>
      <span className='float-md-end d-none d-md-block'>
        {REACT_APP_SERVER}
        {REACT_APP_SERVER === 'Development' ? <Monitor size={14} /> : REACT_APP_SERVER === 'Staging' ? <Coffee size={14} /> : <Cloud size={14} />}
      </span>
    </p>
  )
}

export default Footer
