import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' // 👈️ Redux Imports
import axios from 'axios'// 👈️ Axios Imports
import { urlReportingService } from '@src/endpoints' // 👈️ API Microservice EndPoint

export const getCarReconReport = createAsyncThunk('carrecon/getCarReconReport', async (param) => {
  const response = await axios.get(`${urlReportingService}/GetSourceUnitReconciliationReport/${param.oid}/${param.bid}`)
  return {
    carreconReport: response.data.data,
    vloaded: true
  }
})

export const carreconSlice = createSlice({
  name: 'carrecon',
  initialState: {
    vloaded : false,
    verror : false,
    carreconReport: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getCarReconReport.fulfilled, (state, action) => {
      state.vloaded = action.payload.vloaded
      state.carreconReport = action.payload.carreconReport
    })
    .addCase(getCarReconReport.rejected, (state) => {
      state.vloaded = true
      state.verror = true
    })
  }
})
  
export default carreconSlice.reducer

carreconSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Source Unit Reconciliation Reports - Store {Get Data from Microservices}',
  filename: 'carrecon',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/reports/store/carrecon.js',
  prodUrl: ''
}