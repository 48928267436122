// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlBookTimeOffService, urlDailyPlannigService, urlIdentityService } from '@src/endpoints'
import { getUriParams } from "@utils" // 👈️ Utils Imports 
export const getAllData = createAsyncThunk('bookTimeOff/getAllData', async (myself) => {
  myself = myself === undefined ? false : myself
  const response = await axios.get(`${urlBookTimeOffService}/GetMyBookTimeOffCount?myself=${myself}`)
  return {
    data: response.data,
    loadedAll: true
  }
})
export const getMyTeam = createAsyncThunk('bookTimeOff/getMyTeam', async () => {
  const response = await axios.get(`${urlDailyPlannigService}/GetMyTeam`)
  const myTeam = [...response.data]
  return myTeam
})

export const getHRTeam = createAsyncThunk('bookTimeOff/getHRTeam', async () => {
  const response = await axios.get(`${urlBookTimeOffService}/GetHRTeam`)
  const hrTeam = [...response.data]
  return hrTeam
})

export const getMyTeamProject = createAsyncThunk('bookTimeOff/getMyTeamProject', async () => {
  const response = await axios.get(`${urlDailyPlannigService}/GetMyTeamProject`)
  const myTeamProject = [...response.data]
  return myTeamProject
})

export const getAllBookTimeOffUser = createAsyncThunk('dailyPlanning/getAllBookTimeOffUser', async () => {
  const response = await axios.get(`${urlIdentityService}/GetMembers`)
  const allBookTimeOffUser = [{ value: null, label: 'Select...' }, ...response.data]
  return allBookTimeOffUser
})

export const getRemainingLeavesCall =  async (id) => {
  const response = await axios.get(`${urlIdentityService}/GetRemainingLeaves/${id}`)
  return response.data
}

export const getRemainingLeaves = createAsyncThunk('bookTimeOff/getRemainingLeaves', getRemainingLeavesCall)

export const getData = createAsyncThunk('bookTimeOff/getData', async params => {
  const uri = getUriParams(params)
  const BookTimeOffFilter = {
    userId: params.userId,
    startDate: params.startDate,
    endDate: params.endDate,
    status: params.status,
    isSoftDeleted: params.isSoftDeleted
  }

  const response = await axios.post(`${urlBookTimeOffService}/GetAllBookTimeOff?${uri}`, BookTimeOffFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const addBookTimeOff = createAsyncThunk('bookTimeOff/addBookTimeOff', async (BookTimeOffViewModel, { dispatch, getState }) => {
  await axios.post(`${urlBookTimeOffService}/AddBookTimeOff`, BookTimeOffViewModel)
  await dispatch(getData(getState().bookTimeOff.params))
  await dispatch(getAllData(BookTimeOffViewModel.myself))
  return BookTimeOffViewModel
})

export const updateBookTimeOff = createAsyncThunk('bookTimeOff/updateBookTimeOff', async (BookTimeOffUpdateViewModel, { dispatch, getState }) => {
  await axios.post(`${urlBookTimeOffService}/UpdateBookTimeOff`, BookTimeOffUpdateViewModel)
  await dispatch(getData(getState().bookTimeOff.params))
  await dispatch(getAllData(BookTimeOffUpdateViewModel.myself))
  return BookTimeOffUpdateViewModel
})

export const updateBookTimeOffStatus = async (BookTimeOffStatusViewModel) => {
  const response = await axios.put(`${urlBookTimeOffService}/UpdateBookTimeOffStatus`, BookTimeOffStatusViewModel)
  return response.data
}

export const updateRemainingLeave = async (UserLeavesViewModel) => {
  const response = await axios.put(`${urlIdentityService}/UserLeavesUpdate`, UserLeavesViewModel)
  return response.data
}

export const bookTimeOffSlice = createSlice({
  name: 'bookTimeOff',
  initialState: {
    loaded: false,
    loadedAll: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    myTeam: [],
    hrTeam: [],
    myTeamProject: [],
    allBookTimeOffUser: [],
    remainingLeaves: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMyTeam.fulfilled, (state, action) => {
        state.myTeam = action.payload
      })
      .addCase(getMyTeamProject.fulfilled, (state, action) => {
        state.myTeamProject = action.payload
      })
      .addCase(getHRTeam.fulfilled, (state, action) => {
        state.hrTeam = action.payload
      })
      .addCase(getAllBookTimeOffUser.fulfilled, (state, action) => {
        state.allBookTimeOffUser = action.payload
      })
      .addCase(getRemainingLeaves.fulfilled, (state, action) => {
        state.remainingLeaves = action.payload.data
        console.log('Remaining Leaves in reducer:', state.remainingLeaves)
      })
  }
})

export default bookTimeOffSlice.reducer
bookTimeOffSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'BookTimeOff - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}