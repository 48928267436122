// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇 EndPoints
import { urlFreightForwardService, urlReportingService, urlCRMCompanyService, urlCRMCompanyAddressService } from '@src/endpoints'

export const getFFReport = createAsyncThunk('ffReports/getFFReport', async (ffNumber) => {
  const response = await axios.get(`${urlReportingService}/GetBookingDepartureReportQuery/${ffNumber}`)
  return {
    data: response.data.data,
    errors: response.data.errors,
    message: response.data.message,
    success: response.data.success
  }
})

export const generateFFReport = createAsyncThunk('ffReports/generateFFReport', async (FFReportData, { dispatch }) => {
  const FFReportGenerateModel = {
    FFRequestId : FFReportData.FFRequestId,
    FFOceanBookingId : FFReportData.FFOceanBookingId,
    ReportType : FFReportData.ReportType,
    notes : FFReportData.notes,
    noteType : FFReportData.noteType
  }
  const response = await axios.post(`${urlFreightForwardService}/GenerateFFReport`, FFReportGenerateModel)
  await axios.post(`${urlFreightForwardService}/UpdateJsonFFReport/${response.data}`, FFReportData.json)
  const param = {
    id: FFReportGenerateModel.FFOceanBookingId, 
    rid: undefined
  }
  if (FFReportGenerateModel.ReportType === 'BookingConfirmation') {
    await dispatch(getFFReport(param))
  } else {
    await dispatch(getFFReport(param))
  }
  return FFReportGenerateModel
})

export const updateFFReport = createAsyncThunk('ffReports/AddOrUpdateBookingDepartureReport', async (updatedReport, { dispatch }) => {
  await axios.post(`${urlReportingService}/AddOrUpdateBookingDepartureReport`, updatedReport.json)
  await dispatch(getFFReport(updatedReport.ffNumber))
  return updatedReport
})

export const getAllCrmCustomer = createAsyncThunk('ffReports/getAllCrmCustomer', async (shipperName) => {
  const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/customer`)

  const matchingObject = response.data.find(e => shipperName.trim().toLowerCase() === e.companyCode.trim().toLowerCase())
  const companyRes = await axios.get(`${urlCRMCompanyAddressService}/GetCompanyAddressById/${matchingObject.value}`)
  return companyRes.data
})

export const GetCompanyByCode = createAsyncThunk('ffReports/GetCompanyByCode', async (terminal) => {
  const response = await axios.get(`${urlCRMCompanyService}/GetCompanyByCode/${terminal}`)
  return response.data
})

export const ffReportsSlice = createSlice({
  name: 'ffReports',
  initialState: {
    data: [],
    errors: [],
    message: '',
    success: false,
    dLoaded: false,
    dError: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFFReport.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.errors = action.payload.errors
        state.message = action.payload.message
        state.success = action.payload.success
      })
      .addCase(getFFReport.rejected, (state) => {
        state.dLoaded = true
        state.dError = true
      })
  }
})

export default ffReportsSlice.reducer

ffReportsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'FF Reports - Store {Get Data from Microservices}',
  filename: 'ffReports',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/freight-forward-request/store/ffReports.js',
  prodUrl: ''
}