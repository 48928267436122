// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlVesselVoyageService } from '@src/endpoints'

//import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('vesselMind/getAllData', async () => {
  const response = await axios.get(`${urlVesselVoyageService}/GetVesselVoyageCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('vesselMind/getAllVesselVoyageByTerminal', async params => {
  const response = await axios.get(`${urlVesselVoyageService}/GetAllVesselVoyageByTerminal/${params.terminalCode}`)
  return {
    params,
    terminal: response.data.data.terminal === '' ? {} : response.data.data.terminal,
    data: response.data.data.vessels === '' ? [] : response.data.data.vessels,
    totalPages: response.data.data.total,
    loaded: true
  }
})

// export const updateTimeSheetStatus = async (statusUpdateModel) => {
//   await axios.post(`${urlTimeSheetService}/UpdateTimeSheetStatus`, statusUpdateModel)
//   return statusUpdateModel
// }

// export const getTimeSheetsUser =  createAsyncThunk('vesselVoyage/getTimeSheetsUser', async () => {
//   const response = await axios.get(`${urlTimeSheetService}/GetTimeSheetsUser`)
//   const allTimeSheetUser = [{ value: '', label: 'Select...' }, ...response.data]
//   return allTimeSheetUser
// })

// export const getAllContactsSelect =  createAsyncThunk('vesselVoyage/getAllContactsSelect', async (type) => {
//   const response = await axios.get(`${urlCRMContactService}/GetAllContactsSelect?type=${type}`)
//   const allContacts  = [{ value: null, label: 'Select...', fullName:null, email:null, phone: null, companyId: null}, ...response.data]
//   return allContacts
// })

export const vesselMindSlice = createSlice({
  name: 'vesselMind',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    terminal: {},
    total: 1,
    params: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser:[],
    allContacts:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.terminal = action.payload.terminal
      })
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
      })
      // .addCase(getTimeSheetsUser.fulfilled, (state, action) => {
      //   state.allTimeSheetUser = action.payload
      // })
      // .addCase(getAllContactsSelect.fulfilled, (state, action) => {
      //   state.allContacts = action.payload
      // })
  }
})

export default vesselMindSlice.reducer

vesselMindSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'VesselVoyage - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/hrm/time-sheet/store/index.js',
  prodUrl: ''
}
