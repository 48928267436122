import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  invoices: [
    {
      id: 4987,
      issuedDate: '13 Dec 2019',
      client: {
        address: '64351 Andrew Lights',
        company: 'MSC CANADA CLAIMS',
        companyEmail: 'can-info@msc.com',
        country: 'Romania',
        contact: '(320) 616-3915',
        name: 'MSC CANADA CLAIMS'
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '21 Set 2022'
    },
    {
      id: 4988,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'WESTERN FREIGHT SOLUTIONS',
        companyEmail: 'cbassenden@westernfreightsolutions.ca',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'WESTERN FREIGHT SOLUTIONS'
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '21 Set 2022'
    },
    {
      id: 4989,
      issuedDate: '19 Oct 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'WESTERN FREIGHT SOLUTIONS',
        companyEmail: 'cbassenden@westernfreightsolutions.ca',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'WESTERN FREIGHT SOLUTIONS'
      },
      service: 'Unlimited Extended License',
      total: 3719,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '03 Nov 2019'
    },
    {
      id: 4990,
      issuedDate: '06 Mar 2020',
      client: {
        address: '19022 Clark Parks Suite 149',
        company: 'COLUMBIA GRAIN',
        companyEmail: 'dcontreras@columbiagrain.com',
        country: 'Cambodia',
        contact: '(832) 323-6914',
        name: 'COLUMBIA GRAIN'
      },
      service: 'Software Development',
      total: 4749,
      avatar: '',
      invoiceStatus: 'Sent',
      balance: 4749,
      dueDate: '23 Dec 2022'
    },
    {
      id: 4991,
      issuedDate: '08 Feb 2020',
      client: {
        address: '8534 Saunders Hill Apt. 583',
        company: 'DOMTAR CORPORATION',
        companyEmail: 'tithecott@domtar.com',
        country: 'Martinique',
        contact: '(970) 982-3353',
        name: 'DOMTAR CORPORATION'
      },
      service: 'UI/UX Design & Development',
      total: 4056,
      avatar: '',
      invoiceStatus: 'Test',
      balance: '$4056',
      dueDate: '26 Dec 2022'
    },
    {
      id: 4992,
      issuedDate: '26 Aug 2019',
      client: {
        address: '19022 Clark Parks Suite 149',
        company: 'COLUMBIA GRAIN',
        companyEmail: 'dcontreras@columbiagrain.com',
        country: 'Cambodia',
        contact: '(832) 323-6914',
        name: 'COLUMBIA GRAIN'
      },
      service: 'UI/UX Design & Development',
      total: 2771,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '24 Jun 2019'
    },
    {
      id: 4993,
      issuedDate: '17 Sep 2019',
      client: {
        address: '19022 Clark Parks Suite 149',
        company: 'COLUMBIA GRAIN',
        companyEmail: 'dcontreras@columbiagrain.com',
        country: 'Cambodia',
        contact: '(832) 323-6914',
        name: 'COLUMBIA GRAIN'
      },
      service: 'UI/UX Design & Development',
      total: 2713,
      avatar: '',
      invoiceStatus: 'Ready',
      balance: '$2713',
      dueDate: '22 Dec 2022'
    },
    {
      id: 4994,
      issuedDate: '11 Feb 2020',
      client: {
        address: '8534 Saunders Hill Apt. 583',
        company: 'DOMTAR CORPORATION',
        companyEmail: 'tithecott@domtar.com',
        country: 'Martinique',
        contact: '(970) 982-3353',
        name: 'DOMTAR CORPORATION'
      },
      service: 'Template Customization',
      total: 4309,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '10 Feb 2020'
    },
    {
      id: 4995,
      issuedDate: '26 Jan 2020',
      client: {
        address: '56381 Ashley Village Apt. 332',
        company: 'OCEAN BLUE EXPRESS',
        companyEmail: 'mike@oceanbluexp.com',
        country: 'Ukraine',
        contact: '(583) 470-8356',
        name: 'OCEAN BLUE EXPRESS'
      },
      service: 'Software Development',
      total: 3367,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '03 Dec 2022'
    },
    {
      id: 4996,
      issuedDate: '15 Jan 2020',
      client: {
        address: '64351 Andrew Lights',
        company: 'MSC CANADA CLAIMS',
        companyEmail: 'can-info@msc.com',
        country: 'Romania',
        contact: '(320) 616-3915',
        name: 'MSC CANADA CLAIMS'
      },
      service: 'Software Development',
      total: 4776,
      avatar: '',
      invoiceStatus: 'Past Due',
      balance: '$4776',
      dueDate: '02 Oct 2022'
    },
    {
      id: 4997,
      issuedDate: '27 Sep 2019',
      client: {
        address: '56381 Ashley Village Apt. 332',
        company: 'OCEAN BLUE EXPRESS',
        companyEmail: 'mike@oceanbluexp.com',
        country: 'Ukraine',
        contact: '(583) 470-8356',
        name: 'OCEAN BLUE EXPRESS'
      },
      service: 'Unlimited Extended License',
      total: 3789,
      avatar: '',
      invoiceStatus: 'Partial Payment',
      balance: '$666',
      dueDate: '20 Dec 2022'
    },
    {
      id: 4998,
      issuedDate: '31 Jul 2019',
      client: {
        address: '64351 Andrew Lights',
        company: 'MSC CANADA CLAIMS',
        companyEmail: 'can-info@msc.com',
        country: 'Romania',
        contact: '(320) 616-3915',
        name: 'MSC CANADA CLAIMS'
      },
      service: 'Unlimited Extended License',
      total: 5200,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '20 Nov 2022'
    },
    {
      id: 4999,
      issuedDate: '14 Feb 2020',
      client: {
        address: '56381 Ashley Village Apt. 332',
        company: 'OCEAN BLUE EXPRESS',
        companyEmail: 'mike@oceanbluexp.com',
        country: 'Ukraine',
        contact: '(583) 470-8356',
        name: 'OCEAN BLUE EXPRESS'
      },
      service: 'Software Development',
      total: 4558,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '05 Nov 2022'
    },
    {
      id: 5000,
      issuedDate: '21 May 2019',
      client: {
        address: '8534 Saunders Hill Apt. 583',
        company: 'DOMTAR CORPORATION',
        companyEmail: 'tithecott@domtar.com',
        country: 'Martinique',
        contact: '(970) 982-3353',
        name: 'DOMTAR CORPORATION'
      },
      service: 'Template Customization',
      total: 3503,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '13 Nov 2022'
    },
    {
      id: 5001,
      issuedDate: '30 Jun 2019',
      client: {
        address: '64351 Andrew Lights',
        company: 'MSC CANADA CLAIMS',
        companyEmail: 'can-info@msc.com',
        country: 'Romania',
        contact: '(320) 616-3915',
        name: 'MSC CANADA CLAIMS'
      },
      service: 'Unlimited Extended License',
      total: 5285,
      avatar: '',
      invoiceStatus: 'Partial Payment',
      balance: '$202',
      dueDate: '27 Dec 2022'
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/invoice/invoices').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.invoices.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter(invoice => {
    if (String('paid').includes(queryLowered) && invoice.balance === 0) {
      return invoice.balance === 0
    } else {
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      return (
        (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
          invoice.client.name.toLowerCase().includes(queryLowered) ||
          String(invoice.id).toLowerCase().includes(queryLowered) ||
          String(invoice.total).toLowerCase().includes(queryLowered) ||
          String(invoice.balance).toLowerCase().includes(queryLowered) ||
          invoice.dueDate.toLowerCase().includes(queryLowered)) &&
        invoice.invoiceStatus.toLowerCase() === (status.toLowerCase() || invoice.invoiceStatus.toLowerCase())
      )
    }
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.invoices,
      total: filteredData.length,
      invoices: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(config => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
