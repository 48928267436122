import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { urlSpecialTransloadTransactionService, urlLandBookingService, urlTemporaryTransactionService, urlMovementService, urlEquipmentPictureService, urlTransloadOrderService, urlOceanBookingService, urlEquipmentService, urlTimeSheetService, urlLoadingInstructionService, urlTransloadTransactionService, urlCargoInventoryService} from "@src/endpoints"
import toast from "react-hot-toast"
import { urlMaterialInventoryService } from "../../../../endpoints"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const getParamString = (params) => {
  let string = ""
  if (Object.keys(params).length > 0) {
    Object.keys(params).map((key) => {
      if (!!params[key]) {
        string += `${key}=${params[key]}&`
      }
    })
  }
  return string
}

///api/v1/TransloadOrder/GetTransloadOrderStatusCount
export const getTransloadOrderStatusCount = createAsyncThunk(
  "transloadOrder/getTransloadOrderStatusCount",
  async () => {
    const response = await axios.get(
      `${urlTransloadOrderService}/GetTransloadOrderStatusCount`
    )
    return response.data.data
  }
)

export const getTransloadData = createAsyncThunk(
  "transloadOrder/getTransloadData",
  async (params, {dispatch}) => {
    try {
    const response = await axios.post(
      `${urlTransloadOrderService}/GetAllTransloadOrder?${getParamString(
        params.params
      )}`,
      params.filter || {}
    )
    dispatch(getTransloadOrderStatusCount())
    return {...response.data, ...params}
  } catch (error) {
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return { success: false, message: "Internal server Error" }
  }
  }
)

export const getTransloadDetail = createAsyncThunk(
  "transloadOrder/getTransloadDetail",
  async ({id, bookingId}) => {
    const response = await axios.get(
      `${urlTransloadOrderService}/GetTransloadOrder/${id}`
    )
    if (bookingId !== undefined) {
      const booking = response.data.data.transloadBooking.find(i => i.bookingId === bookingId)
      if (booking) {
      const bookingInfo = {
        bookingId: booking.bookingId,
        bookingNumber: booking.bookingNumber,
        shippingLine: booking.shippingLine,
        bookingType: booking.bookingType
      }
      response.data.data = {...response.data.data, ...bookingInfo}
      }
    }
    return response.data
  }
)

export const updateTransloadOrder = createAsyncThunk(
  "transloadOrder/updateTransloadOrder",
  async (data, {dispatch}) => {
    try {
    const response = await axios.put(
      `${urlTransloadOrderService}/UpdateTransloadOrder`,
      data
    )
    if (response.data.success) {
      toast.success("Transload Order Updated Successfully")
      dispatch(
        getTransloadDetail({id: data.id, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  } catch (error) {
  MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
  return { success: false, message: "Internal server Error" }
}
}
)

  export const addTransloadOrder = createAsyncThunk(
  "transloadOrder/addTransloadOrder",
    async (data, {dispatch}) => {
    try {
      // const formData = FormatMultiPartForm(data)
      const response = await axios.post(`${urlTransloadOrderService}/AddTransloadOrder`, data)
      if (response.data.success) {
        toast.success("Transload Order Added Successfully")
      dispatch(getTransloadData({params: {page: 1, perPage: 10, sort: 'desc', sortColumn: 'createdDate'}}))
        return { data: response.data, success: true }
      } else {
        const errors = response.data.errors
        if (errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: errors[0]
          })
        }
        return { success: false, message: "Internal server Error" }
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
  )

export const deleteTransloadOrder = async (id) => {
    const response = await axios.delete(
      `${urlTransloadOrderService}/DeleteTransloadOrder/${id}`
    )
    if (response.data.success) {
      toast.success("Transload Order Deleted!!")
      return { success: true}
    } else {
      return { success: false, message: response.data.errors[0] }
    }
  }

export const assignBookings = createAsyncThunk(
  "transloadOrder/assignBookings",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadOrderService}/AssignBookingsToTransloadOrder`,
      data
    )
    if (response.data.success) {
      toast.success("Bookings Assigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
) 

export const unassignBookings = createAsyncThunk(
  "transloadOrder/unassignBookings",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadOrderService}/UnassignBookingsFromTransloadOrder`,
      data
    )
    if (response.data.success) {
      toast.success("Bookings Unassigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

///UpdateTransloadBooking
export const updateTransloadBooking = createAsyncThunk(
  "transloadOrder/updateTransloadBooking",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadOrderService}/UpdateTransloadBooking`,
      data
    )
    if (response.data.success) {
      toast.success("Transload Booking Updated!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

// /api/v1/TransloadOrder/UnassignOutboundEquipmentsFromTransloadBooking
export const unassignOutboundEquipments = createAsyncThunk(
  "transloadOrder/unassignOutboundEquipments",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadOrderService}/UnassignOutboundEquipmentsFromTransloadBooking`,
      data
    )
    if (response.data.success) {
      toast.success("Outbound Equipments Unassigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

export const assignOutboundEquipments = createAsyncThunk(
  "transloadOrder/assignOutboundEquipments",
  async (data, {dispatch}) => {
    try {
    const response = await axios.put(
      `${urlTransloadOrderService}/AssignOutboundEquipmentsToTransloadBooking`,
      data
    )
    if (response.data.success) {
      toast.success("Outbound Equipments Assigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  } catch (error) {
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
      }
} 
)

///TransloadOrder/MoveContainersBetweenBookings
export const moveContainersBetweenBookings = createAsyncThunk(
  "transloadOrder/moveContainersBetweenBookings",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlTransloadOrderService}/MoveContainersBetweenBookings`,
        data
      )
      if (response.data.success) {
        toast.success("Containers Moved!!")
        dispatch(
          getTransloadDetail({id: data.transloadOrderId, bookingId: data.bId})
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
              icon: "error",
              title: "Error",
              text: response.data.errors[0]
          })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

//assignInboundEquipments
export const assignInboundEquipments = createAsyncThunk(
  "transloadOrder/assignInboundEquipments",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlLoadingInstructionService}/AssignInboundEquipmentsToLoadingInstruction`,
        data
      )
      if (response.data.success) {
        toast.success("Inbound Equipments Assigned!!")
        dispatch(
          getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
              icon: "error",
              title: "Error",
              text: response.data.errors[0]
          })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

//unassignInboundEquipments
export const unassignInboundEquipments = createAsyncThunk(
  "transloadOrder/unassignInboundEquipments",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/UnassignInboundEquipmentsFromLoadingInstruction`,
      data
    )
    if (response.data.success) {
      toast.success("Inbound Equipments Unassigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

//assignStorageEquipments
export const assignStorageEquipments = createAsyncThunk(
  "transloadOrder/assignStorageEquipments",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/AssignCargoInventoryToLoadingInstruction`,
      data
    )
    if (response.data.success) {
      toast.success("Storage Equipments Assigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

//unassignStorageEquipments
export const unassignStorageEquipments = createAsyncThunk(
  "transloadOrder/unassignStorageEquipments",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/UnassignCargoInventoryFromLoadingInstruction`,
      data
    )
    if (response.data.success) {
      toast.success("Storage Equipments Unassigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

//AssignStorageEquipmentsToLoadingInstruction
export const assignStorageEquipmentsToLoadingInstruction = createAsyncThunk(
  "transloadOrder/assignStorageEquipmentsToLoadingInstruction",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/AssignStorageEquipmentsToLoadingInstruction`,
      data
    )
    if (response.data.success) {
      toast.success("Storage Equipments Assigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

//UnassignStorageEquipmentsToLoadingInstruction
export const unassignStorageEquipmentsToLoadingInstruction = createAsyncThunk(
  "transloadOrder/unassignStorageEquipmentsFromLoadingInstruction",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/UnassignStorageEquipmentsFromLoadingInstruction`,
      data
    )
    if (response.data.success) {
      toast.success("Storage Equipments Unassigned!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

export const addLoadingInstruction = createAsyncThunk(
  "transloadOrder/addLoadingInstruction",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
      `${urlLoadingInstructionService}/AddLoadingInstruction`,
      data.formData)
    if (response.data.success) {
      toast.success("Loading Instruction Added!!")
      dispatch(
        getTransloadDetail({id: data.id, bookingId: data.bookingId})
      )
      return { data: response.data, success: true }
    } else {
      if (response.data.errors) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
      } else {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to Add Loading Instruction"
        })
      }
      return { success: false, message: response.data.errors[0]}
    }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
    
  }
)

export const updateLoadingInstruction = createAsyncThunk(
  "transloadOrder/updateLoadingInstruction",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlLoadingInstructionService}/UpdateLoadingInstruction`,
        data.formData)
      if (response.data.success) {
        toast.success("Loading Instruction Updated!!")
        dispatch(
          // getTransloadDetail(data.transloadOrderId)
          getTransloadDetail({id: data.id, bookingId: data.bookingId})
        )
        return { data: response.data, success: true }
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        } else {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to Update Loading Instruction"
        })
        }
        return { success: false, message: "Failed to Update Loading Instruction"}
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

export const deleteLoadingInstruction =  createAsyncThunk(
  "transloadOrder/deleteLoadingInstruction",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlLoadingInstructionService}/DeleteLoadingInstruction`,
        data
      )  
      if (response.data.success) {
        toast.success("Loading Instruction Deleted!!")
        dispatch(getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId}))
        return { data: response.data, success: true }
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        } else {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to Delete Loading Instruction"
        })
        }
        return { success: false, message: response.data.errors ? response.data.errors.join(", ") : "Internal server Error" }
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

export const fetchOceanBookings = async (customer) => {
  const response = await axios.post(
    `${urlOceanBookingService}/GetAllOceanBookingToTransloadOrderList`, customer.toString(),
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response.data
}

//landBooking
export const fetchLandBookings = async (customer) => {
  const response = await axios.post(
    `${urlLandBookingService}/GetAllLandBookingToTransloadOrderList`, customer.toString(),
    { headers: { 'Content-Type': 'application/json' } }
  )
  return response.data
}

///GetAllOceanBookingsList
export const fetchAllOceanBookings = async (data) => {
  const response = await axios.post(
    `${urlOceanBookingService}/GetAllOceanBookingsList?${getParamString(data.params)}`, data.filter
  )
  return response.data
}

export const fetchOutboundEqs = async (data) => {
  const {params, filter} = data
  // /Equipment/GetAvailableEquipment
  const response = await axios.post(
    `${urlEquipmentService}/GetAvailableEquipment?${getParamString(params)}`, filter
  )
  return response.data
}

///TransloadOrder/GetAllTransloadBooking
export const fetchAllTransloadBookings = async (data) => {
  try {
  const response = await axios.post(
    `${urlTransloadOrderService}/GetAllTransloadBooking?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
  return {data: response.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  }
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

///LoadingInstruction/GetAllLoadingInstruction
export const fetchAllLoadingInstructions = async (data) => {
  try {
  const response = await axios.post(
    `${urlLoadingInstructionService}/GetAllLoadingInstruction?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
  return {data: response.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  } 
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

//LoadingInstruction/GetAllInboundEquipmentAndStorageEquipment
export const fetchAllInboundEquipments = async (data) => {
  try {
  const response = await axios.post(
    `${urlLoadingInstructionService}/GetAllInboundEquipmentAndStorageEquipment?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
    return {data: response.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  }
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

//TransloadOrder/GetAllOutboundEquipment
export const fetchAllOutboundEquipments = async (data) => {
  try {
  const response = await axios.post(
    `${urlTransloadOrderService}/GetAllOutboundEquipment?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
    return {data: response.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  }
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

///TransloadTransaction/GetAllTransloadTransaction
export const fetchAllTransloadTransactions = async (data) => {
  try {
  const response = await axios.post(
    `${urlTransloadTransactionService}/GetAllTransloadTransaction?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
    return {data: response.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  }
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

//Add Transload Transaction
export const addTransloadTransaction = createAsyncThunk(
  "transloadOrder/addTransloadTransaction",
  async ({data, order}, {dispatch}) => {
    try {
      const formData = data //jsonToFormData(data)
      const response = await axios.put(
        `${urlTransloadTransactionService}/AddTransloadTransaction`,
        formData,
          { headers: { 'Content-Type': 'application/json' }}
      )
      if (response.data.success) {
        toast.success("Transload Transaction Added!!")
        dispatch(
          getTransloadDetail({id: order.transloadOrderId, bookingId: order.bookingId})
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

//TransloadTransaction/UpdateTransloadTransaction
export const updateTransloadTransaction = createAsyncThunk(
  "transloadOrder/updateTransloadTransaction",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlTransloadTransactionService}/UpdateTransloadTransaction`,
        data
      )
      if (response.data.success) {
        toast.success("Transload Transaction Updated!!")
        dispatch(
          getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

//getTransloadTransaction
export const getTransloadTransaction = async (data) => {
    try {
      const response = await axios.post(
        `${urlTransloadTransactionService}/GetTransloadTransaction`, data
      )
      if (response.data.success) {
        return {data: response.data, success: true}
      } else {
        return {data: [], success: false, error: response.data.errors[0]}
      }
    } catch (error) {
      return {data: [], success: false, error: 'Internal server error'}
    }
  }

//deleteTransloadTransaction
export const deleteTransloadTransaction = createAsyncThunk(
  "transloadOrder/deleteTransloadTransaction",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadTransactionService}/DeleteTransloadTransaction`, 
      data, 
      { headers: { 'Content-Type': 'application/json' } }
    )
    if (response.data.success) {
      toast.success("Transload Transaction Deleted!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
      })
      }
      return response.data
    }
  }
)

//CargoInventory/GetAllCargoInventory
export const fetchAllCargoInventory = async (data) => {
  try {
  const response = await axios.post(
    `${urlCargoInventoryService}/GetAllCargoInventory?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
    return {data: response.data, success: true}
  }
  return {data: [], success: false, error: response.data.errors[0]}
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

//LoadingInstruction/UpdateAssignedStorageEquipment
export const updateAssignedStorageEquipment = createAsyncThunk(
  "transloadOrder/updateAssignedStorageEquipment",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlLoadingInstructionService}/UpdateAssignedStorageEquipment`,
      data
    )
    if (response.data.success) {
      toast.success("Storage Equipment Updated!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
        })
      }
      return response.data
    }
  }
)

export const fetchCrewList = async (data) => {
  try {
    const response = await axios.post(
      `${urlTimeSheetService}/GetCurrentCrewList`, data
    )
    if (response.data) {
      return {data: response.data, success: true}
    }
    return {data: [], success: false, error: response.data.errors[0]}
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

//GET STORAGE EQUIPMENT
export const fetchStorageEquipments = async (data) => {
  try {
    const response = await axios.post(
      `${urlEquipmentService}/GetEquipmentInventory?${getParamString(data.params)}`, data.filter
    )
    if (response.data.success) {
      return {data: response.data, success: true}
    }
    return {data: [], success: false, error: response.data.errors[0]}
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

///AcknowledgeBookingChanges
export const acknowledgeBookingChanges = createAsyncThunk(
  "transloadOrder/acknowledgeBookingChanges",
  async (data, {dispatch}) => {
  try {
    const response = await axios.put(`${urlTransloadOrderService}/AcknowledgeBookingChanges`, data)
    if (response.data.success) {
      toast.success("Booking Changes Acknowledged!!")
      dispatch(
        getTransloadDetail({id: data.transloadOrderId, bookingId: data.bookingId})
      )
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
        })
      }
      return {data: [], success: false, error: response.data.errors[0]}
    }
  } catch (error) {
        MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return {data: [], success: false, error: 'Internal server error'}
  }
}
)

export const addInOutBoundPictures = async (data) => {
  try {
    const response = await axios.put(
      `${urlEquipmentPictureService}/UploadEquipmentPictures`,
      data
    )
    if (response.data.success) {
      toast.success("Pictures Uploaded!!")
      return {data: response.data, success: true}
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
        })
      }
      return {data: [], success: false, error: response.data.errors[0]}
    }
  } catch (error) {
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return { success: false, message: "Internal server Error" }
  }
}

///UploadSpecialEquipmentPictures
export const addSpecialEquipmentPictures = async (data) => {
  try {
    const response = await axios.put(
      `${urlEquipmentPictureService}/UploadSpecialEquipmentPictures`,
      data
    )
    if (response.data.success) {
      toast.success("Pictures Uploaded!!")
      return {data: response.data, success: true}
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
        })
      }
      return {data: [], success: false, error: response.data.errors[0]}
    }
  } catch (error) {
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return { success: false, message: "Internal server Error" }
  }
}

//cancel transload order
export const cancelTransloadOrder = createAsyncThunk(
  "transloadOrder/cancelTransloadOrder",
  async (data, {dispatch}) => {
    const response = await axios.put(
      `${urlTransloadOrderService}/CancelTransloadOrder/${data.id}`
    )
    if (response.data.success) {
      dispatch(getTransloadDetail(data))
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
      })
      }
      return response.data
    }
  }
)

//MaterialInventory/GetAllMaterialInventory
export const fetchAllMaterialInventory = async (data) => {
  try {
  const response = await axios.post(
    `${urlMaterialInventoryService}/GetAllMaterialInventory?${getParamString(data.params)}`, data.filter
  )
  if (response.data.success) {
  return {data: response.data.data, success: true}
  } else {
    return {data: [], success: false, error: response.data.errors[0]}
  }
  } catch (error) {
    return {data: [], success: false, error: 'Internal server error'}
  }
}

export const validateSealNumber = async (data) => {
  try {
    const response = await axios.post(
      `${urlMovementService}/validateSealNumber`, data)
    if (response.data.errors.length === 0) {
      return {data: response.data, success: true}
    }
    return {data: [], success: false, errors: response.data.errors}
  } catch (error) {
    return {data: [], success: false, errors: [], message: 'Internal server error'}
  }
}

// /TemporaryTransaction/GetAllTemporaryTransaction
export const fetchAllTemporaryTransactions = createAsyncThunk(
  "transloadOrder/fetchAllTemporaryTransactions",
  async (data) => {
  try {
  const response = await axios.post(
    `${urlTemporaryTransactionService}/GetAllTemporaryTransaction?${getParamString(data.params)}`, data.filter || {}
  )
  return response.data
  } catch (error) {
    console.log(error)
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return {data: [], success: false, error: 'Internal server error'}
  }
})

///SpecialTransloadTransaction/GetAllSpecialTransloadTransaction
export const fetchAllSpecialTransloadTransactions = createAsyncThunk(
  "transloadOrder/fetchAllSpecialTransloadTransactions",
  async (data) => {
  try {
  const response = await axios.post(
    `${urlSpecialTransloadTransactionService}/GetAllSpecialTransloadTransaction?${getParamString(data.params)}`, data.filter || {}
  )
  return response.data
  } catch (error) {
    console.log(error)
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return {data: [], success: false, error: 'Internal server error'}
      }
}
)

// //AddSpecialTransloadTransaction
export const addSpecialTransloadTransaction = createAsyncThunk(
  "transloadOrder/addSpecialTransloadTransaction",
  async (data, {dispatch}) => { //, {dispatch}
    try {
      const response = await axios.post(
        `${urlSpecialTransloadTransactionService}/AddSpecialTransloadTransaction`,
        data
      )
      if (response.data.success) {
        toast.success("Special Transload Transaction Added!!")
        const params = {
          page: 1,
          perPage: 10,
          sort: 'desc',
          sortColumn: 'createdDate'
        }
        dispatch(
          fetchAllSpecialTransloadTransactions({params, filter: {}})
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)
///GetSpecialTransloadTransaction
export const getSpecialTransloadTransaction = createAsyncThunk(
  "transloadOrder/getSpecialTransloadTransaction",
  async (id) => {
  try {
    const response = await axios.get(
      `${urlSpecialTransloadTransactionService}/GetSpecialTransloadTransaction/${id}`
    )
    return response.data
  } catch (error) {
    console.log(error)
    MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
    return {data: null, success: false, error: 'Internal server error'}
  }
})

//updateSpecialTransloadTransaction
export const updateSpecialTransloadTransaction = createAsyncThunk(
  "transloadOrder/updateSpecialTransloadTransaction",
  async (data, {dispatch}) => {
    try {
      const response = await axios.put(
        `${urlSpecialTransloadTransactionService}/UpdateSpecialTransloadTransaction`,
        data
      )
      if (response.data.success) {
        toast.success("Special Transload Transaction Updated!!")
        dispatch(
          getSpecialTransloadTransaction(data.id)
        )
        return response.data
      } else {
        if (response.data.errors) {
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: response.data.errors[0]
        })
        }
        return response.data
      }
    } catch (error) {
      MySwal.fire({
            icon: "error",
            title: "Error",
            text: "Internal Server Error"
        })
      return { success: false, message: "Internal server Error" }
    }
  }
)

//deleteSpecialTransloadTransaction 
export const deleteSpecialTransloadTransaction = createAsyncThunk(
  "transloadOrder/deleteSpecialTransloadTransaction",
  async (id, {dispatch}) => {
    const response = await axios.delete(
      `${urlSpecialTransloadTransactionService}/DeleteSpecialTransloadTransaction/${id}`)
    if (response.data.success) {
      toast.success("Special Transload Transaction Deleted!!")
      const params = {
        page: 1,
        perPage: 10,
        sort: 'desc',
        sortColumn: 'createdDate'
      }
      dispatch(
        fetchAllSpecialTransloadTransactions({params, filter: {}})
      )
      return response.data
    } else {
      if (response.data.errors) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: response.data.errors[0]
      })
      }
      return response.data
    }
  }
)


export const transloadOrderSlice = createSlice({
  name: "transloadOrder",
  initialState: {
    transloadData: [],
    statusCount: {
      created: 0,
      cargoEnroute: 0,
      inProgress: 0,
      completed: 0,
      cancelled: 0,
      invoiced: 0
    },
    transloadId: null,
    total: 0,
    loaded: true,
    transloadDetail:{
      data: {},
      loading: false,
      activeTab: '1',
      storageNumber: null
    },
    temporaryStorage:{
      data: [],
      detailViewData: {
        data: {},
        loading: false
      },
      loading: false,
      total: 0
    },
    specialTransload:{
      transloadData: [],
      detailViewData: {
        data: null,
        loaded: true
      },
      loaded: true,
      total: 0
    }
  },
  reducers: {
    resetId: (state) => {
      state.transloadId = null
    },
    setBookingInfo: (state, action) => {
      if (state.transloadDetail.data) {
        state.transloadDetail.data.bookingId = action.payload.bookingId
        state.transloadDetail.data.bookingNumber = action.payload.bookingNumber
        state.transloadDetail.data.shippingLine = action.payload.shippingLine
      }
    },
    resetTransloadDetail: (state) => {
      state.transloadDetail = {
        data: {},
        loading: false,
        activeTab: '1',
        storageNumber: null
      }
    },
    setActiveTab: (state, action) => {
      state.transloadDetail.activeTab = action.payload
    },
    resetSpecialTransloadDetail: (state) => {
      state.specialTransload.detailViewData = {
        data: null,
        loaded: true
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransloadData.fulfilled, (state, action) => {
        state.transloadData = action.payload.data
        state.total = action.payload.totalCount
        state.loaded = true
      })
      .addCase(getTransloadData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getTransloadData.rejected, (state) => {
        state.transloadData = []
        state.total = 0
        state.loaded = true
      })
      .addCase(getTransloadOrderStatusCount.fulfilled, (state, action) => {
        state.statusCount = action.payload
      })
      .addCase(getTransloadDetail.fulfilled, (state, action) => {
        let storageNumber = null
        if (!action.payload.data.bookingId) {
          storageNumber = sessionStorage.getItem("storageNumber") ? JSON.parse(sessionStorage.getItem("storageNumber")) : null
        } else {
          sessionStorage.removeItem("storageNumber")
        }
        state.transloadDetail.data = action.payload
        state.transloadDetail.storageNumber = storageNumber
        state.transloadDetail.loading = false
      })
      .addCase(getTransloadDetail.rejected, (state) => {  
        state.transloadDetail.data = {}
        state.transloadDetail.loading = false
      })
      .addCase(getTransloadDetail.pending, (state) => {
        state.transloadDetail.loading = true
      })
      .addCase(updateTransloadOrder.pending, (state) => {
        state.transloadDetail.loading = true
      })
      .addCase(updateTransloadOrder.fulfilled, (state) => {
        state.transloadDetail.loading = false
      })
      //temporary storage
      .addCase(fetchAllTemporaryTransactions.pending, (state) => {
        state.temporaryStorage.loading = true
      })
      .addCase(fetchAllTemporaryTransactions.fulfilled, (state, action) => {
        state.temporaryStorage.data = action.payload.data
        state.temporaryStorage.total = action.payload.totalCount
        state.temporaryStorage.loading = false
      })
      .addCase(fetchAllTemporaryTransactions.rejected, (state) => {
        state.temporaryStorage.data = []
        state.temporaryStorage.total = 0
        state.temporaryStorage.loading = false
      })
      //special transload
      .addCase(fetchAllSpecialTransloadTransactions.pending, (state) => {
        state.specialTransload.loaded = false
      })
      .addCase(fetchAllSpecialTransloadTransactions.fulfilled, (state, action) => {
        state.specialTransload.transloadData = action.payload.data
        state.specialTransload.total = action.payload.totalCount
        state.specialTransload.loaded = true
      })
      .addCase(fetchAllSpecialTransloadTransactions.rejected, (state) => {
        state.specialTransload.transloadData = []
        state.specialTransload.total = 0
        state.specialTransload.loaded = true
      })
      .addCase(getSpecialTransloadTransaction.fulfilled, (state, action) => {
        state.specialTransload.detailViewData.data = action.payload?.data || null
        state.specialTransload.detailViewData.loaded = true
      })
      .addCase(getSpecialTransloadTransaction.rejected, (state) => {
        state.specialTransload.detailViewData.data = null
        state.specialTransload.detailViewData.loaded = true
      })
      .addCase(getSpecialTransloadTransaction.pending, (state) => {
        state.specialTransload.detailViewData.loaded = false
      })
  }
})

export const { resetId, resetTransloadDetail, setActiveTab, setBookingInfo, resetSpecialTransloadDetail } = transloadOrderSlice.actions

export default transloadOrderSlice.reducer
