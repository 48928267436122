// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'
import { urlEquipmentService, urlReportingService } from '../../../../endpoints'
import { getUriParams } from '@utils'

const initialState = {
    loaded:false,
    statsData: {},
    data: [],
    total: 0
}

const getStuffingReportApiUrl = `${urlReportingService}/GetStuffingReportStats`

export const getStuffingReportStats = createAsyncThunk('customerDashboard/getStuffingReportStats', async (data) => {
    const customerDashboardDTO = {
        //six months ago
        fromDate: new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(),
        toDate: new Date().toISOString(),
        customerCode: data.companyCode
    }
    const response = await axios.post(`${getStuffingReportApiUrl}`, customerDashboardDTO)
    return response.data?.counters ? response.data?.counters[0] : {}
})

export const getReportList = createAsyncThunk('customerDashboard/getReportList', async (data) => {
    const { params, filters } = data
    try {
    const uri = getUriParams(params)
    const response = await axios.post(`${urlReportingService}/GetAllTransloadBookingReportList?${uri}`, filters)
    return {
        response: response.data.data,
        totalPages: response.data.totalCount
    }
  } catch (error) {
    console.log(error)
  }
})

export const getScheduleTerminalData = createAsyncThunk('customerDashboard/getScheduleTerminalData', async (data) => {
    const { params, filters } = data

    const uri = getUriParams(params)
    const filterData = {
        movementType: "schedule", // Customer Dashboard only to show scheduled movement
        yardLocation: filters.terminal || "ALL",
        customerName: filters.customerName === "ALL" || filters.customerName === "" ? "" : filters.customerName || "",
        line: filters.line || "",
        referenceNumber: filters.referenceNumber || "",
        equipmentStatus: filters.equipmentStatus || "",
        equipmentNumber: filters.equipmentNumber || "",
        commodityType: filters.commodityType || "",
        equipmentType: filters.equipmentType || "ALL",
        from: filters.from || null,
        to: filters.to || null,
        destination: filters.destination || "",
        origin: filters.origin || "",
        cargoType: filters.cargoType || "",
        commodity: filters.commodity || "",
        grade: filters.grade || "",
        lotNumber: filters.lotNumber || "",
        lastTracedLocation: filters.lastTracedLocation || "",
        servingYard: filters.servingYard || "",
        tracingStatus: filters.tracingStatus || "",
        equipmentSize: filters.equipmentSize || "",
        ...(filters.tracingStatus !== 'TRANSIT' ? { [filters.tracingStatus]: filters.tracingDate } : {})
        }
        const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

export const customerDashboardSlice = createSlice({
    name: 'customerDashboard',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getStuffingReportStats.fulfilled, (state, action) => {
            state.statsData = action.payload
        })
        builder.addCase(getReportList.fulfilled, (state, action) => {
            state.dataReport = action.payload.response
            state.totalReport = action.payload.totalPages
            state.loaded = true
        })
        .addCase(getScheduleTerminalData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
            state.filter = action.payload.filterData
            state.loading = false
        })
        //if loading, set loaded to false
        builder.addMatcher(
            action => {
                return action.type.includes('/pending')
            },
            (state) => {
                state.loaded = false
            }
        )
    }
})

export default customerDashboardSlice.reducer