// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlTimeSheetService, urlDailyPlannigService } from '@src/endpoints'

export const getAllData = createAsyncThunk('dailyPlanning/getAllData', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetAllTimeSheetsCount`)
  return {
    data: response.data.data,
    loadedAll: true
  }
})

export const getAllDailyPlan = createAsyncThunk('dailyPlanning/getAllDailyPlan', async (DailyPlannerFilter) => {
  const response = await axios.post(`${urlDailyPlannigService}/GetAllDailyPlan`, DailyPlannerFilter)
  return { 
    data: response.data,
    loaded: true
  }
})

export const getAllUpComingGoals = createAsyncThunk('dailyPlanning/getAllUpComingGoals', async (UpcomingGoalsFilter) => {
  const response = await axios.post(`${urlDailyPlannigService}/GetAllDailyPlan`, UpcomingGoalsFilter)
  return { 
      upcomingGoals: response.data,
      loadedUpComing: true
    }
})

export const addDailyPlan = createAsyncThunk('dailyPlanning/addDailyPlan', async (DailyPlannigViewModel, { dispatch }) => {
    await axios.post(`${urlDailyPlannigService}/AddDailyPlan`, DailyPlannigViewModel)
    await dispatch(getAllDailyPlan({...DailyPlannigViewModel}))
    return DailyPlannigViewModel
})

export const updateDailyPlanStatus = createAsyncThunk('dailyPlanning/updateDailyPlanStatus', async (DailyPlanStatusViewModel, { dispatch }) => {
  await axios.put(`${urlDailyPlannigService}/UpdateDailyPlanStatus`, DailyPlanStatusViewModel)
  await dispatch(getAllDailyPlan({...DailyPlanStatusViewModel}))
  return DailyPlannigViewModel
})

export const moveToNextDayPlan = createAsyncThunk('dailyPlanning/moveToNextDayPlan', async (MoveToNextDayPlanViewModel, { dispatch }) => {
  await axios.put(`${urlDailyPlannigService}/MoveToNextDayPlan`, MoveToNextDayPlanViewModel)
  await dispatch(getAllDailyPlan({...MoveToNextDayPlanViewModel}))
  return DailyPlannigViewModel
})

export const getAllEmployeesSelect =  createAsyncThunk('dailyPlanning/getAllEmployeesSelect', async () => {
    const response = await axios.get(`${urlTimeSheetService}/GetAllEmployeesSelect`)
    const allEmployees  = [{ value: null, label: 'Select...'}, ...response.data.data]
    return allEmployees
})

export const updateTimeSheet = createAsyncThunk('dailyPlanning/updateTimeSheet', async (TimeSheetViewModel) => {
  await axios.post(`${urlTimeSheetService}/UpdateTimeSheet`, TimeSheetViewModel)
  return TimeSheetViewModel
})

export const clockOutToday = createAsyncThunk('dailyPlanning/clockOutToday', async (ClockOutViewModel) => {
  await axios.put(`${urlTimeSheetService}/ClockOut`, ClockOutViewModel)
  return ClockOutViewModel
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'dailyPlanning',
  initialState: {
    loaded : false,
    loadedUpComing : false,
    loadedAll: false,
    data: [],
    upcomingGoals: [],
    total: 1,
    params: {},
    allData: {},
    allEmployees:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getAllDailyPlan.pending, (state) => {
        state.loaded = false
      })
      .addCase(getAllDailyPlan.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
      })
      .addCase(getAllEmployeesSelect.fulfilled, (state, action) => {
        state.allEmployees = action.payload
      })
      .addCase(getAllUpComingGoals.pending, (state) => {
        state.loadedUpComing = false
      })
      .addCase(getAllUpComingGoals.fulfilled, (state, action) => {
        state.loadedUpComing = action.payload.loadedUpComing
        state.upcomingGoals = action.payload.upcomingGoals
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}
