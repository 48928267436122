// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlTimeSheetService, urlInvoiceService, urlPieceWorkService } from '@src/endpoints'

import { getUriParams, twoDigitFormat } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('hrmPieceWork/getAllData', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetAllTimeSheetsCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('hrmPieceWork/getData', async params => {
  const uri =  getUriParams(params)
  
  const PieceWorksFilter = {
    payPeriodId: params.payPeriodId,
    status: params.status
  }
  const response = await axios.post(`${urlPieceWorkService}/GetAllPieceWorks?${uri}`, PieceWorksFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const getAllPayPeriodCount = createAsyncThunk('hrmPieceWork/GetAllPayPeriodCount', async () => {
  const response = await axios.get(`${urlPieceWorkService}/GetAllPayPeriodCount`)
  return {
    data: response.data,
    loadedAllPayPeriod: true
  }
})

export const getPayPeriodData = createAsyncThunk('hrmPieceWork/getPayPeriodData', async params => {
  const uri =  getUriParams(params)
  const PayPeriodFilter = {
    id: "",
    startDateTime: params.startDateTime,
    endDateTime: params.endDateTime,
    deviceName: params.deviceName,
    title: params.title,
    status: params.status,
    isSoftDeleted: params.isSoftDeleted
  }
  const response = await axios.post(`${urlPieceWorkService}/GetAllPayPeriod?${uri}`, PayPeriodFilter)
  return {
    params,
    payPeriodData: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loadedPayPeriod: true
  }
})

export const updatePayPeriodStatus = async (statusUpdateModel) => {
  await axios.post(`${urlPieceWorkService}/UpdatePayPeriodStatus`, statusUpdateModel)
  return statusUpdateModel
}

export const updatePieceWorkStatus = async (statusUpdateModel) => {
  await axios.post(`${urlPieceWorkService}/updatePieceWorkStatus`, statusUpdateModel)
  return statusUpdateModel
}

export const getPieceWorkData = createAsyncThunk('hrmPieceWork/getPieceWorkData', async params => {
  //const uri =  getUriParams(params)
  let startDate = new Date()
  let endDate = new Date()
  const currentDay = new Date().getDate()
  const monthMidStart = startDate.getMonth() === 0 ? `${startDate.getFullYear() - 1}-12-16` : `${startDate.getFullYear()}-${twoDigitFormat(endDate.getMonth())}-16`
  const monthEnd = endDate.getMonth() === 0 ? `${endDate.getFullYear() - 1}-12-${new Date(endDate.getFullYear() - 1, 12, 0).getDate()}` : `${endDate.getFullYear()}-${twoDigitFormat(endDate.getMonth())}-${new Date(endDate.getFullYear(), twoDigitFormat(endDate.getMonth()), 0).getDate()}`
  
  startDate = startDate.getDate() > 15 ? `${startDate.getFullYear()}-${twoDigitFormat(startDate.getMonth() + 1)}-${twoDigitFormat(startDate.getDate() - currentDay + 1)}` : monthMidStart
  endDate = endDate.getDate() > 15 ? `${endDate.getFullYear()}-${twoDigitFormat(endDate.getMonth() + 1)}-15` : monthEnd
  
  const TimeSheetFilter = {
    //userId: params.userId,
    //punchType: params.punchType !== null ? params.punchType : null,
    from: params.startDateTime === null ? startDate : params.startDateTime,
    to: params.endDateTime === null ? endDate : params.endDateTime
    //siteLocation: params.siteLocation,
    //status: params.status
  }

  const response = await axios.post(`${urlInvoiceService}/GetPieceWorkReport`, TimeSheetFilter)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true,
    from:TimeSheetFilter.from,
    to: TimeSheetFilter.to
  }
})

export const getAllEmployeesSelect =  createAsyncThunk('hrmPieceWork/getAllEmployeesSelect', async () => {
  const response = await axios.get(`${urlPieceWorkService}/GetAllEmployeesSelect`)
  const allEmployees  = [{ value: null, label: 'Select...'}, ...response.data.data]
  return allEmployees
})

export const addNewPayPeriod = createAsyncThunk('hrmPieceWork/addNewPayPeriod', async (PayPeriodViewModel) => {
  const response = await axios.post(`${urlPieceWorkService}/addNewPayPeriod`, PayPeriodViewModel)
  return {
    payPeriod: response,
    loadedPay: true
  }
  //return PayPeriodViewModel
})

export const updatePayPeriod = createAsyncThunk('hrmPieceWork/updatePayPeriod', async (PayPeriodViewModel) => {
  await axios.post(`${urlPieceWorkService}/updatePayPeriod`, PayPeriodViewModel)
  return PayPeriodViewModel
})

export const addNewPieceWork = createAsyncThunk('hrmPieceWork/addNewPieceWork', async (pieceWorkViewModel) => {
  await axios.post(`${urlPieceWorkService}/addNewPieceWork`, pieceWorkViewModel)
  return pieceWorkViewModel
})

export const updatePieceWork = createAsyncThunk('hrmPieceWork/updatePieceWork', async (pieceWorkViewModel) => {
  await axios.post(`${urlPieceWorkService}/updatePieceWork`, pieceWorkViewModel)
  return pieceWorkViewModel
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'hrmPieceWork',
  initialState: {
    loaded : false,
    loadedPayPeriod : false,
    loadedAll: false,
    loadedAllPayPeriod: false,
    data: [],
    payPeriodData: [],
    total: 1,
    params: {},
    allData: {},
    allPayPeriodData: {},
    allEmployees:[],
    from : '',
    to : '',
    payPeriod: {},
    loadedPay: false

  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(addNewPayPeriod.pending, (state) => {
      state.loadedPay = false
    })
    .addCase(addNewPayPeriod.fulfilled, (state, action) => {
      state.loadedPay = action.payload.loadedPay
      state.payPeriod = action.payload.payPeriod
    })
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allPayPeriodData = action.payload.data
      })
      .addCase(getAllPayPeriodCount.pending, (state) => {
        state.loadedAllPayPeriod = false
      })
      .addCase(getAllPayPeriodCount.fulfilled, (state, action) => {
        state.loadedAllPayPeriod = action.payload.loadedAllPayPeriod
        state.allPayPeriodData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPayPeriodData.pending, (state) => {
        state.loadedPayPeriod = false
      })
      .addCase(getPayPeriodData.fulfilled, (state, action) => {
        state.loadedPayPeriod = action.payload.loadedPayPeriod
        state.payPeriodData = action.payload.payPeriodData
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPieceWorkData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getPieceWorkData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.from = action.payload.from
        state.to = action.payload.to
      })
      .addCase(getAllEmployeesSelect.fulfilled, (state, action) => {
        state.allEmployees = action.payload
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/hrm/time-sheet/piece-work/view/store/index.js',
  prodUrl: ''
}
