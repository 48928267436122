const initialState = {
    loading: false,
    error: null,
    success: null,
    data: [],
    params:{
    sort: 'asc',
    sortColumn:'priorityLevel',
    q: '',
    perPage: 10,
    page: 1,
    status:''
    },
    filterData:{
        shippingLineName: null,
        cansSize: null,
        cansStatus: null,
        pickUpTerminal: null,
        dropOffTerminal: null,
        priorityLevel: null,
        fromEarliestDate: null,
        toEarliestDate: null,
        fromDeadlineDate: null,
        toDeadlineDate: null
    },
    totalCount: 0,
    lastUpdated: null
}

const yardMoveReducer = (state = initialState, action) => {
    switch (action.type) {

        //loading
        case 'YARDMOVE_LOADING':
            return { ...state, loading: action.payload }

        //get all yard moves
        case 'GET_YARD_MOVEMENTS':
            return { ...state,
                 data: action.payload.data ? action.payload.data.map(el => ({
                ...el,
                 isChecked: false,
                 note : el.notes !== undefined ? el.notes[el.notes.length - 1]?.note : ''
                })) : [],
                totalCount: action.payload.totalCount,
                lastUpdated: new Date()
             }
        
        //updatePARAMS
        case 'UPDATE_YARDMOVE_PARAMS':
            return { ...state, params: {...state.params, ...action.payload} }
        
        //delete yard move
        case 'DELETE_YARDMOVE':
            return { ...state, data: state.data.filter(item => item.id !== action.payload) }
        
        //update yard move //will receive only a id, field, value to update
        case 'UPDATE_YARDMOVE':
            const {field, value, id} = action.payload
            return { ...state, data: state.data.map(item => (item.id === id ? {...item, [field]: value, isChanged: true} : item)) }
        
        //update filter data
        case 'UPDATE_YARDMOVE_FILTER_DATA':
            const {f, v} = action.payload //field, value
            return { ...state, filterData: {...state.filterData, [f]:v === "" ? null : v} }

        //reset state
        case 'RESET_STATE_YARDMOVE':
            return {
                loading: false,
                error: null,
                success: null,
                data: [],
                params:{
                sort: 'asc',
                sortColumn:'priorityLevel',
                q: '',
                perPage: 10,
                page: 1,
                status:''
                },
                filterData:{
                    shippingLineName: null,
                    cansSize: null,
                    cansStatus: null,
                    pickUpTerminal: null,
                    dropOffTerminal: null,
                    priorityLevel: null,
                    fromEarliestDate: null,
                    toEarliestDate: null,
                    fromDeadlineDate: null,
                    toDeadlineDate: null
                },
                totalCount: 0,
                lastUpdated: null
            }

        default:
            return state
    }
}

export default yardMoveReducer