import Avatar from "@components/avatar"
import { Edit, X } from "react-feather"
import toast from 'react-hot-toast'

export const ToastContent = ({ t, heading, response, message }) => {
    return (  
    <div className='d-flex'>
        <div className='me-1'>
        <Avatar size="sm" color={response === true ? "success" : "danger"} icon={<Edit size={12} />} />
            </div>
            <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
                <h6>{heading}</h6>
                <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
            </div>
            <span>{message}</span>
        </div>
    </div>
  )
  }