// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlTimeSheetService, urlInvoiceService } from '@src/endpoints'

import { getUriParams, twoDigitFormat } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('hrmTSReport/getAllData', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetAllTimeSheetsCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('hrmTSReport/getData', async params => {
  const uri =  getUriParams(params)
  
  let startDate = new Date()
  let endDate = new Date()
  const currentDay = new Date().getDate()

  const monthMidStart = startDate.getMonth() === 0 ? `${startDate.getFullYear() - 1}-12-16` : `${startDate.getFullYear()}-${endDate.getMonth()}-16`
  const monthEnd = endDate.getMonth() === 0 ? `${endDate.getFullYear() - 1}-12-${new Date(endDate.getFullYear() - 1, 12, 0).getDate()}` : `${endDate.getFullYear()}-${endDate.getMonth()}-${new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()}`
  
  startDate = startDate.getDate() > 15 ? `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate() - currentDay + 1}` : monthMidStart
  endDate = endDate.getDate() > 15 ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}-15` : monthEnd
  
  const TimeSheetFilter = {
    userId: params.userId,
    punchType: params.punchType !== null ? params.punchType : null,
    startDateTime: params.startDateTime === null ? startDate : params.startDateTime,
    endDateTime: params.endDateTime === null ? endDate : params.endDateTime,
    siteLocation: params.siteLocation,
    status: params.status
  }
  const response = await axios.post(`${urlTimeSheetService}/GetTimeSheetsUserHours?${uri}`, TimeSheetFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})


export const getPieceWorkData = createAsyncThunk('hrmTSReport/getPieceWorkData', async params => {
  //const uri =  getUriParams(params)
  let startDate = new Date()
  let endDate = new Date()
  const currentDay = new Date().getDate()
  const monthMidStart = startDate.getMonth() === 0 ? `${startDate.getFullYear() - 1}-12-16` : `${startDate.getFullYear()}-${twoDigitFormat(endDate.getMonth())}-16`
  const monthEnd = endDate.getMonth() === 0 ? `${endDate.getFullYear() - 1}-12-${new Date(endDate.getFullYear() - 1, 12, 0).getDate()}` : `${endDate.getFullYear()}-${twoDigitFormat(endDate.getMonth())}-${new Date(endDate.getFullYear(), twoDigitFormat(endDate.getMonth()), 0).getDate()}`
  
  startDate = startDate.getDate() > 15 ? `${startDate.getFullYear()}-${twoDigitFormat(startDate.getMonth() + 1)}-${twoDigitFormat(startDate.getDate() - currentDay + 1)}` : monthMidStart
  endDate = endDate.getDate() > 15 ? `${endDate.getFullYear()}-${twoDigitFormat(endDate.getMonth() + 1)}-15` : monthEnd
  
  const TimeSheetFilter = {
    //userId: params.userId,
    //punchType: params.punchType !== null ? params.punchType : null,
    from: params.startDateTime === null ? startDate : params.startDateTime,
    to: params.endDateTime === null ? endDate : params.endDateTime
    //siteLocation: params.siteLocation,
    //status: params.status
  }

  const response = await axios.post(`${urlInvoiceService}/GetPieceWorkReport`, TimeSheetFilter)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true,
    from:TimeSheetFilter.from,
    to: TimeSheetFilter.to
  }
})

export const getAllEmployeesSelect =  createAsyncThunk('hrmTSReport/getAllEmployeesSelect', async () => {
  const response = await axios.get(`${urlTimeSheetService}/GetAllEmployeesSelect`)
  const allEmployees  = [{ value: null, label: 'Select...'}, ...response.data.data]
  return allEmployees
})

export const addNewTimeSheet = createAsyncThunk('hrmTSReport/addNewTimeSheet', async (TimeSheetViewModel) => {
  await axios.post(`${urlTimeSheetService}/addNewTimeSheet`, TimeSheetViewModel)
  return TimeSheetViewModel
})

export const updateTimeSheet = createAsyncThunk('hrmTSReport/updateTimeSheet', async (TimeSheetViewModel) => {
  await axios.post(`${urlTimeSheetService}/UpdateTimeSheet`, TimeSheetViewModel)
  return TimeSheetViewModel
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'hrmTSReport',
  initialState: {
    loaded : false,
    loadedAll: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    allEmployees:[],
    from : '',
    to : ''

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPieceWorkData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getPieceWorkData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.from = action.payload.from
        state.to = action.payload.to
      })
      .addCase(getAllEmployeesSelect.fulfilled, (state, action) => {
        state.allEmployees = action.payload
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}
