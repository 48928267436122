// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlCRMContactService, urlUserSkills, urlIdentityService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'

export const getAllData = createAsyncThunk('userSkills/getAllData', async () => {
  const response = await axios.get(`${urlUserSkills}/GetAllUserSkillsCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('userSkills/getData', async params => {
  const uri =  getUriParams(params)
  const userSkillsFilter = {
    userId: params.userId,
    skills: params.skills,
    location: params.location,
    status: params.status
  }
  const response = await axios.post(`${urlUserSkills}/GetAllUserSkills?${uri}`, userSkillsFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const addNewUserSkills1 = async (userSkillsViewModel) => {
  await axios.post(`${urlUserSkills}/AddNewUserSkills`, userSkillsViewModel)
  return userSkillsViewModel
}

export const addNewUserSkills = createAsyncThunk('userSkills/addNewUserSkills', async (userSkillsViewModel, { dispatch, getState }) => {
  await axios.post(`${urlUserSkills}/AddNewUserSkills`, userSkillsViewModel)
  await dispatch(getData(getState().userSkills.params))
  await dispatch(getAllData())
  return userSkillsViewModel
})

export const updateUserSkills = createAsyncThunk('userSkills/updateUserSkills', async (userSkillsUpdateViewModel, { dispatch, getState }) => {
  await axios.post(`${urlUserSkills}/UpdateUserSkills`, userSkillsUpdateViewModel)
  await dispatch(getData(getState().userSkills.params))
  await dispatch(getAllData())
  return userSkillsUpdateViewModel
})
 
export const getAllEmployees =  createAsyncThunk('userSkills/getAllEmployees', async () => {
  const response = await axios.get(`${urlIdentityService}/GetMembers`)
  const allEmployees = [...response.data]
  return allEmployees
})

export const getAllContactsSelect =  createAsyncThunk('userSkills/getAllContactsSelect', async (type) => {
  const response = await axios.get(`${urlCRMContactService}/GetAllContactsSelect?type=${type}`)
  const allContacts  = [{ value: null, label: 'Select...', fullName:null, email:null, phone: null, companyId: null}, ...response.data]
  return allContacts
})

export const userSkillsSlice = createSlice({
  name: 'userSkills',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    status: [],
    notes: [],
    allEmployees:[],
    allContacts:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.allEmployees = action.payload
      })
      .addCase(getAllContactsSelect.fulfilled, (state, action) => {
        state.allContacts = action.payload
      })
  }
})

export default userSkillsSlice.reducer

userSkillsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'UserSkills - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/hrm/skills/store/index.js',
  prodUrl: ''
}
