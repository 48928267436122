import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { urlDunnageService } from "@src/endpoints"
import { urlConfigCacheService } from "../../../../../endpoints"
import toast from "react-hot-toast"

const getParamString = (params) => {
  let string = ""
  if (Object.keys(params).length > 0) {
    Object.keys(params).map((key) => {
      if (!!params[key]) {
        string += `${key}=${params[key]}&`
      }
    })
  }
  return string
}

export const getAllDunnages = createAsyncThunk(
  "dunnage/getAllDunnages",
  async (params) => {
    console.log('params', params)
    const response = await axios.post(
      `${urlDunnageService}/GetAllDunnage?${getParamString(params.params)}`,
      params.filter || {}
    )
    return {
      params: params.params,
      filter: params.filter,
      data: response.data.data,
      totalPages: response.data.totalCount,
      loaded: true
    }
  }
)

export const addDunnage = async (data) => {
  try {
    const response = await axios.post(`${urlDunnageService}/AddDunnage`, data)
    if (response.data.success) {
      toast.success('Dunnage Added Successfully!')
      return { success: true }
    } else {
      toast(t => (
        <ToastContent t={t}
          heading = {'Error'}
          response = {false}
          message = {response.data.errors[0]}
        />
      ))
    }
  } catch (error) {
    toast.error('Internal Server Error!')
  }
}

export const updateDunnage = async (data) => {
  try {
    const response = await axios.put(`${urlDunnageService}/UpdateDunnage`, data)
    if (response.data.success) {
      toast.success('Dunnage Updated Successfully!')
      return { success: true }
    } else {
      toast(t => (
        <ToastContent t={t}
          heading = {'Error'}
          response = {false}
          message = {response.data.errors[0]}
        />
      ))
    }
  } catch (error) {
    toast.error('Internal Server Error!')
  }
}

export const deleteDunnage = async (id) => {
  try {
    const response = await axios.delete(`${urlDunnageService}/DeleteDunnage/${id}`)
    if (response.data.success) {
      toast.success('Dunnage Deleted Successfully!')
      return { success: true }
    } else {
      toast(t => (
        <ToastContent t={t}
          heading = {'Error'}
          response = {false}
          message = {response.data.errors[0]}
        />
      ))
    }
  } catch (error) {
    toast.error('Internal Server Error!')
  }
}

export const getDunnage = createAsyncThunk(
  "dunnage/getDunnage",
  async (id) => {
    const response = await axios.get(
      `${urlDunnageService}/GetDunnage/${id}`
    )
    return response.data
  }
)

export const getPackagingSupplierData = createAsyncThunk(
  'crmCompany/getPackagingSupplier',
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/packagingsupplier`)
    return response.data
  }
)

export const getPackagingTypeData = createAsyncThunk('companyConfig/getPackagingTypeData', async () => {
  const response = await axios.get(`${urlConfigCacheService}/PackagingType`)
  return response.data
})

export const dunnageSlice = createSlice({
  name: "dunnage",
  initialState: {
    transloadData: [],
    supplierData: [],
    packagingData: [],
    typeData : [],
    transloadId: null,
    loaded: false,
    data: [],
    total: 0,
    params: {},
    filter: {},
    dunnage: {
      dunnage : {}
    }
  },
  reducers: {
    resetId: (state) => {
      state.transloadId = null
    },
    resetDunnage : (state) => {
      state.dunnage.dunnage = {}
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllDunnages.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.loaded = true
      state.params = action.payload.params
      state.filter = action.payload.filter
    })
    .addCase(getAllDunnages.pending, (state) => {
      state.loaded = false
      state.data = []
    })
    .addCase(getAllDunnages.rejected, (state) => {
      state.loaded = true
      state.data = []
      state.total = 0
    })
    .addCase(getDunnage.fulfilled, (state, action) => {
      state.dunnage.dunnage = action.payload
    })
    .addCase(getPackagingSupplierData.fulfilled, (state, action) => {
      state.supplierData = action.payload
    })
    .addCase(getPackagingTypeData.fulfilled, (state, action) => {
      state.packagingData = action.payload
    })
  }
})

export const { resetId, resetDunnage } = dunnageSlice.actions

export default dunnageSlice.reducer
