// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlTimeSheetService } from '@src/endpoints'
import { getData } from '../../../apps/hrm/time-sheet/store' // 👈️ Store & Actions

export const getClockInOutStatus = createAsyncThunk('clockInOut/getClockInOutStatus', async (ClockInOutStatusViewModel) => {
  const response = await axios.post(`${urlTimeSheetService}/GetClockInOutStatus`, ClockInOutStatusViewModel)
  return {
    data: response.data,
    loadedInOutStatus: true
  }
})

export const clockinClockOut = createAsyncThunk('clockInOut/clockinClockOut', async (ClockInOutViewModel, { dispatch }) => {
  const ClockInOutStatusViewModel = {
    userId: ClockInOutViewModel.userId,
    startDateTime: new Date().toLocaleString('en-US', { hour12: true })
  }
  await dispatch(getClockInOutStatus({
    ...ClockInOutStatusViewModel
  }))

  await dispatch(
    getData({
      sort:'desc',
      sortColumn:'startDateTime',
      page: 1,
      perPage: 5,
      userId: ClockInOutViewModel.userId
    }))
})

export const clockInToday = createAsyncThunk('hrmTSReport/clockInToday', async (TimeSheetViewModel, { dispatch }) => {
  await axios.post(`${urlTimeSheetService}/addNewTimeSheet`, TimeSheetViewModel)
  dispatch(clockinClockOut(TimeSheetViewModel))
  return TimeSheetViewModel
})

export const clockOutToday = createAsyncThunk('hrmTSReport/clockOutToday', async (ClockOutViewModel, { dispatch }) => {
  await axios.put(`${urlTimeSheetService}/ClockOut`, ClockOutViewModel)
  dispatch(clockinClockOut(ClockOutViewModel))
  return ClockOutViewModel
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'clockInOut',
  initialState: {
    loadedAll: false,
    loadedInOutStatus : false,
    status : {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getClockInOutStatus.pending, (state) => {
        state.loadedInOutStatus = false
      })
      .addCase(getClockInOutStatus.fulfilled, (state, action) => {
        state.loadedInOutStatus = action.payload.loadedInOutStatus
        state.status = action.payload.data
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}
