import { urlAuthService, urlIdentityService } from '@src/endpoints'

// ** Auth Endpoints
export default {
  loginEndpoint: `${urlAuthService}/Login`,
  registerEndpoint: `${urlIdentityService}/Register`,
  refreshEndpoint: `${urlAuthService}/RefreshToken`,
  logoutEndpoint: `${urlAuthService}/Logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
