// ** API EndPoints
import { urlIdentityService } from "@src/endpoints"

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import { urlConfigCacheService, urlCRMCompanyService } from "../../../../endpoints"
import { getUserData } from '@utils'

const staticToken = getUserData() ? getUserData().accessToken : process.env.REACT_APP_API_KIOSK_TOKEN

export const getHRConfigPayMethod = createAsyncThunk(
  "hrConfig/getHRConfigPayMethod",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/PayMethod`)
    return response.data
  }
)

export const getJobTitleData = createAsyncThunk(
  "hrConfig/getJobTitleData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/JobTitle`)
    return response.data
  }
)

export const getAllTypeDataa = createAsyncThunk(
  "hrConfig/getAllTypeDataa",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}`)
    return response.data
  }
)

const getParamString = (params) => {
  let string = ""
  if (Object.keys(params).length > 0) {
    Object.keys(params).map((key) => {
      if (!!params[key]) {
        string += `${key}=${params[key]}&`
      }
    })
  }
  return string
}

export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  const response = await axios.get(
    `${urlIdentityService}/GetAllUsers?${getParamString(params)}`
  )
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})
//${baseURL}/api/v1/ConfigCache/PayMethod

export const getPayMethod = createAsyncThunk("payment/data", async (params) => {
  const response = await axios.get(
    `${urlConfigCacheService}/PayMethod/GetAllUsers`
  )
  console.log("response.data", response)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

export const getPayMethodData = createAsyncThunk(
  "hrConfig/getPayMethodData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/PayMethod`)
    return response.data
  }
)

export const getHrConfigUserData = createAsyncThunk(
  "hrConfig/getHrConfigUserData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/JobTitle`)
    return response.data
  }
)

export const getWorkTypeData = createAsyncThunk(
  "hrConfig/getWorkTypeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/WorkType`)
    return response.data
  }
)

export const getRelationshipEmergContacteData = createAsyncThunk(
  "hrConfig/getRelationshipEmergContacteData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/RelationshipEmergContact`
    )
    return response.data
  }
)

export const createHrNewRecord = createAsyncThunk(
  "hrConfig/createHrNewRecord",
  async (createHrNewRecord, { dispatch }) => {
    const response = await axios.post(
      `${urlConfigCacheService}`,
      createHrNewRecord
    )

    if (createHrNewRecord?.type === "JobTitle") {
      await dispatch(getJobTitleData())
    }
    if (createHrNewRecord?.type === "WorkType") {
      await dispatch(getWorkTypeData())
    }
    if (createHrNewRecord?.type === "PayMethod") {
      await dispatch(getPayMethodData())
    }
    if (createHrNewRecord?.type === "RelationshipEmergContact") {
      await dispatch(getRelationshipEmergContacteData())
    }

    return response.data
  }
)

export const createNewJobTitle = createAsyncThunk(
  "hrConfig/createNewJobTitle",
  async (newJobTitle, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, newJobTitle)
    await dispatch(getJobTitleData()) // Refresh the job title data after adding a new job title
    return response.data
  }
)

export const getFreightForwardData = createAsyncThunk(
  "companyConfig/getFreightForwardData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/FreightForwarder`
    )
    return response.data
  }
)

// SteamshipLineData - CRM
export const getSteamshipLineData = createAsyncThunk(
  "CRMCompany/getSteamshipLineData",
  async () => {
    // const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/steamshipline`)
    // return response.data

    try {
      const res = await fetch(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/steamshipline`, {headers: {Authorization: `Bearer ${staticToken}`}})
      const data = await res.json()
      if (data) {
        return data
    } else {
        return {success: false, message: 'Internal server Error'}
    }
    }  catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
  }
)

// RailRoadData - CRM
export const getRailRoadData = createAsyncThunk(
  "CRMCompany/getRailRoadData",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/railroad`)
    return response.data
  }
)

// DrayageProvider - CRM
export const getDrayageProviderData = createAsyncThunk(
  "CRMCompany/getDrayageProviderData",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/drayageprovider`)
    return response.data
  }
)

export const getShipperData = createAsyncThunk(
  "companyConfig/getShipperData",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/customer`)
    return response.data
  }
)

export const getCustomerData = createAsyncThunk(
  "companyConfig/getCustomerData",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/customer`)
    return response.data
  }
)

// /GetAllCompanyTypesSelect/customer 
export const getCrmCustomer = createAsyncThunk(
  "companyConfig/getCrmCustomer",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/customer`)
    return response.data
  }
)

//shipper
export const getCrmShipper = createAsyncThunk(
  "companyConfig/getCrmShipper",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/shipper`)
    return response.data
  }
)

export const getConsigneeData = createAsyncThunk(
  "companyConfig/getConsigneeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/Consignee`)
    return response.data
  }
)

export const getPackagingSupplierData = createAsyncThunk(
  "companyConfig/getPackagingSupplierData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/PackagingSupplier`
    )
    return response.data
  }
)

export const createNewCompanyRecord = createAsyncThunk(
  "companyConfig/createNewCompanyRecord",
  async (createHrNewRecord, { dispatch }) => {
    const response = await axios.post(
      `${urlConfigCacheService}`,
      createHrNewRecord
    )

    if (createHrNewRecord?.type === "Freight Forwarder") {
      await dispatch(getFreightForwardData())
    }
    if (createHrNewRecord?.type === "Steamship Line") {
      await dispatch(getSteamshipLineData())
    }
    if (createHrNewRecord?.type === "Shipper") await dispatch(getShipperData())
    if (createHrNewRecord?.type === "Customer") {
      await dispatch(getCustomerData())
    }
    if (createHrNewRecord?.type === "Consignee") {
      await dispatch(getConsigneeData())
    }
    if (createHrNewRecord?.type === "Packaging Supplier") {
      await dispatch(getPackagingSupplierData())
    }

    return response.data
  }
)

export const getQualityTypeData = createAsyncThunk(
  "qualityConfig/getQualityTypeData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/QualityAssuranceServiceType`
    )
    return response.data
  }
)

export const getQualityNameData = createAsyncThunk(
  "qualityConfig/getQualityNameData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/QualityAssuranceServiceName`
    )
    return response.data
  }
)

export const getQualityProviderData = createAsyncThunk(
  "qualityConfig/getQualityProviderData",
  async () => {
    const response = await axios.get(
      `${urlConfigCacheService}/QualityAssuranceServiceProvider`
    )
    return response.data
  }
)

export const createQualityNewRecord = createAsyncThunk(
  "qualityConfig/createQualityNewRecord",
  async (data, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, data)

    if (data?.type === "QualityAssuranceServiceType") {
      await dispatch(getQualityTypeData())
    }
    if (data?.type === "QualityAssuranceServiceName") {
      await dispatch(getQualityNameData())
    }
    if (data?.type === "QualityAssuranceServiceProvider") {
      await dispatch(getQualityProviderData())
    }

    return response.data
  }
)

export const getCommodityTypeData = createAsyncThunk(
  "qualityConfig/getCommodityTypeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/CommodityType`)
    return response.data
  }
)

export const getCommodityNameData = createAsyncThunk(
  "qualityConfig/getCommodityNameData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/CommodityName`)
    return response.data
  }
)

export const getCommodityGradeData = createAsyncThunk(
  "qualityConfig/getCommodityGradeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/CommodityGrade`)
    return response.data
  }
)

export const createCommodityNewRecord = createAsyncThunk(
  "commodityConfig/createCommodityNewRecord",
  async (data, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, data)

    if (data?.type === "CommodityType") {
      await dispatch(getCommodityTypeData())
    }
    if (data?.type === "CommodityName") {
      await dispatch(getCommodityNameData())
    }
    if (data?.type === "CommodityGrade") {
      await dispatch(getCommodityGradeData())
    }

    return response.data
  }
)

export const deleteConfigCache = createAsyncThunk(
  "config/deleteConfigCache",
  async (data) => {
    const response = await axios.delete(`${urlConfigCacheService}&Key=${data?.key}&SecretDeleteKey=${data?.secretKey}`)

    return response.data
  }
)

export const getPackagingTypeData = createAsyncThunk(
  "qualityConfig/getPackagingTypeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/PackagingType`)
    return response.data
  }
)

export const getPackagingMaterialData = createAsyncThunk(
  "qualityConfig/getPackagingMaterialData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/PackagingMaterial`)
    return response.data
  }
)

export const getPackagingSupplier = createAsyncThunk(
  'crmCompany/getPackagingSupplier',
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/packagingsupplier`)
    return response.data
  }
)

export const getColorListData = createAsyncThunk(
  "qualityConfig/getColorListData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/ColorList`)
    return response.data
  }
)

export const createPackagingNewRecord = createAsyncThunk(
  "qualityConfig/createPackagingNewRecord",
  async (data, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, data)

    if (data?.type === "PackagingType") {
      await dispatch(getPackagingTypeData())
    }
    if (data?.type === "PackagingMaterial") {
      await dispatch(getPackagingMaterialData())
    }
    if (data?.type === "ColorList") {
      await dispatch(getColorListData())
    }

    return response.data
  }
)

export const getTransloadTypeData = createAsyncThunk(
  "qualityConfig/getTransloadTypeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/TransloadType`)
    return response.data
  }
)

export const getDunnageTypeData = createAsyncThunk(
  "qualityConfig/getDunnageTypeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/DunnageType`)
    return response.data
  }
)

export const getAmountRequestData = createAsyncThunk(
  "qualityConfig/getAmountRequestData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/AmountRequest`)
    return response.data
  }
)

export const getAdditionalServiceData = createAsyncThunk(
  "qualityConfig/getAdditionalServiceData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/AdditionalService`)
    return response.data
  }
)

export const createTransloadNewRecord = createAsyncThunk(
  "qualityConfig/createTransloadNewRecord",
  async (data, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, data)

    if (data?.type === "TransloadType") {
      await dispatch(getTransloadTypeData())
    }
    if (data?.type === "AmountRequest") {
      await dispatch(getAmountRequestData())
    }
    if (data?.type === "AdditionalService") {
      await dispatch(getAdditionalServiceData())
    }

    return response.data
  }
)

export const getEquipmentIssueData = createAsyncThunk(
  "qualityConfig/getEquipmentIssueData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/EquipmentIssue`)
    return response.data
  }
)

export const getPackagingIssueData = createAsyncThunk(
  "qualityConfig/getPackagingIssueData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/PackagingIssue`)
    return response.data
  }
)

export const createQualityIssueNewRecord = createAsyncThunk(
  "qualityConfig/createQualityIssueNewRecord",
  async (data, { dispatch }) => {
    const response = await axios.post(`${urlConfigCacheService}`, data)

    if (data?.type === "EquipmentIssue") {
      await dispatch(getEquipmentIssueData())
    }
    if (data?.type === "PackagingIssue") {
      await dispatch(getPackagingIssueData())
    }

    return response.data
  }
)

export const fetchDrayageProviders = createAsyncThunk(
  "qualityConfig/fetchDrayageProviders",
  async () => {
    const res = await axios.get(`${urlConfigCacheService}/DrayageProvider`)
    return res.data
  }
)

// /CRMCompany/GetAllCompanyTypesSelect/containerTerminal
export const getContainerTerminalData = createAsyncThunk(
  "companyConfig/getContainerTerminalData",
  async () => {
    const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/containerTerminal`)
    return response.data
  }
)

// /ConfigCache/ContainerSize
export const getContainerSizeData = createAsyncThunk(
  "companyConfig/getContainerSizeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}?type=ContainerSize`)
    return response.data ? response.data.sort((a, b) => a.id.localeCompare(b.id)) : []
  }
)

// /ConfigCache/YardLocation
export const getYardLocations = createAsyncThunk(
  "companyConfig/getYardLocations",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}/YardLocation`)
    return response.data.sort((a, b) => a.name.localeCompare(b.name))
  }
)

export const hrConfigSlice = createSlice({
  name: "hrConfig",
  initialState: {
    jobTitleData: [],
    payMethodData: [],
    workTypeData: [],
    relationshipData: [],
    freightForwarderData: [],
    steamshipLineData: [],
    drayageData: [],
    railRoadData: [],
    shipperData: [],
    customerData: [],
    crmCustomerData: [],
    crmShipperData: [],
    consigneeData: [],
    packagingSupplierData: [],
    allDataRecord: [],
    total: 1,
    params: {},
    data: [],
    hrConfigUserData: [],
    loaded: false,
    qualityType: [],
    qualityName: [],
    qualityProvider: [],
    drayageProviders: [],
    commodityType: [],
    commodityName: [],
    commodityGrade: [],
    packagingType: [],
    packagingMaterial: [],
    colorList: [],
    loader: false,
    transloadType: [],
    amountRequest: [],
    additionalService: [],
    equipmentIssue: [],
    packagingIssue: [],
    dunnageType: [],
    containerTerminal: [],
    containerSizes: [],
    yardLocations: []
  },
  reducers: {
    resetHrConfigUserData: (state) => {
      state.hrConfigUserData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getJobTitleData.fulfilled, (state, action) => {
        state.jobTitleData = action.payload
      })
      .addCase(getPayMethodData.fulfilled, (state, action) => {
        state.payMethodData = action.payload
      })
      .addCase(getHrConfigUserData.fulfilled, (state, action) => {
        state.hrConfigUserData = action.payload
      })
      .addCase(getWorkTypeData.fulfilled, (state, action) => {
        state.workTypeData = action.payload
      })
      .addCase(getRelationshipEmergContacteData.fulfilled, (state, action) => {
        state.relationshipData = action.payload
      })
      .addCase(getAllTypeDataa.fulfilled, (state, action) => {
        state.allDataRecord = action.payload
      })
      .addCase(getFreightForwardData.fulfilled, (state, action) => {
        state.freightForwarderData = action.payload
      })
      .addCase(getSteamshipLineData.fulfilled, (state, action) => {
        state.steamshipLineData = action.payload
      })
      .addCase(getShipperData.fulfilled, (state, action) => {
        state.shipperData = action.payload
      })
      .addCase(getDrayageProviderData.fulfilled, (state, action) => {
        state.drayageData = action.payload
      })
      .addCase(getRailRoadData.fulfilled, (state, action) => {
        state.railRoadData = action.payload
      })
      .addCase(getCustomerData.fulfilled, (state, action) => {
        state.customerData = action.payload
      })
      .addCase(getCrmCustomer.fulfilled, (state, action) => {
        state.crmCustomerData = action.payload
      })
      .addCase(getCrmShipper.fulfilled, (state, action) => {
        state.crmShipperData = action.payload
      })
      .addCase(getConsigneeData.fulfilled, (state, action) => {
        state.consigneeData = action.payload
      })
      .addCase(getPackagingSupplierData.fulfilled, (state, action) => {
        state.packagingSupplierData = action.payload
      })
      .addCase(getQualityTypeData.fulfilled, (state, action) => {
        state.qualityType = action.payload
      })
      .addCase(getQualityNameData.fulfilled, (state, action) => {
        state.qualityName = action.payload
      })
      .addCase(getQualityProviderData.fulfilled, (state, action) => {
        state.qualityProvider = action.payload
      })
      .addCase(getCommodityTypeData.fulfilled, (state, action) => {
        state.commodityType = action.payload
      })
      .addCase(getCommodityNameData.fulfilled, (state, action) => {
        state.commodityName = action.payload
      })
      .addCase(getCommodityGradeData.fulfilled, (state, action) => {
        state.commodityGrade = action.payload
      })
      .addCase(getPackagingTypeData.pending, (state) => {
        state.loader = true
      })
      .addCase(getPackagingTypeData.fulfilled, (state, action) => {
        state.loader = false
        state.packagingType = action.payload
      })
      .addCase(getPackagingMaterialData.pending, (state) => {
        state.loader = true
      })
      .addCase(getPackagingMaterialData.fulfilled, (state, action) => {
        state.loader = false
        state.packagingMaterial = action.payload
      })
      .addCase(getPackagingSupplier.fulfilled, (state, action) => {
        state.packagingSupplier = action.payload
      })
      .addCase(getColorListData.pending, (state) => {
        state.loader = true
      })
      .addCase(getColorListData.fulfilled, (state, action) => {
        state.loader = false
        state.colorList = action.payload
      })
      .addCase(getTransloadTypeData.pending, (state) => {
        state.loader = true
      })
      .addCase(getTransloadTypeData.fulfilled, (state, action) => {
        state.loader = false
        state.transloadType = action.payload
      })
      .addCase(getDunnageTypeData.fulfilled, (state, action) => {
        state.dunnageType = action.payload
      })
      .addCase(getAmountRequestData.pending, (state) => {
        state.loader = true
      })
      .addCase(getAmountRequestData.fulfilled, (state, action) => {
        state.loader = false
        state.amountRequest = action.payload
      })
      .addCase(getAdditionalServiceData.pending, (state) => {
        state.loader = true
      })
      .addCase(getAdditionalServiceData.fulfilled, (state, action) => {
        state.loader = false
        state.additionalService = action.payload
      })
      .addCase(getEquipmentIssueData.pending, (state) => {
        state.loader = true
      })
      .addCase(getEquipmentIssueData.fulfilled, (state, action) => {
        state.loader = false
        state.equipmentIssue = action.payload
      })
      .addCase(getPackagingIssueData.pending, (state) => {
        state.loader = true
      })
      .addCase(getPackagingIssueData.fulfilled, (state, action) => {
        state.loader = false
        state.packagingIssue = action.payload
      })
      .addCase(fetchDrayageProviders.fulfilled, (state, action) => {
        state.drayageProviders = action.payload
      })
      .addCase(getContainerTerminalData.fulfilled, (state, action) => {
        state.containerTerminal = action.payload
      })
      .addCase(getContainerSizeData.fulfilled, (state, action) => {
        state.containerSizes = action.payload
      })
      .addCase(getYardLocations.fulfilled, (state, action) => {
        state.yardLocations = action.payload
      })
  }
})

export const { resetHrConfigUserData } = hrConfigSlice.actions

export default hrConfigSlice.reducer
