// ** Icons Import
import { Home, Grid } from 'react-feather'

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Grid size={20} />,
    badge: 'light-warning',
    navLink: '/dashboard',
    permissions: ['admin', 'any', 'masteradmin', 'customer']
  }
]
