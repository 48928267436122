import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' // 👈️ Redux Imports
import axios from 'axios'// 👈️ Axios Imports
import { urlShipSmplService } from '@src/endpoints' // 👈️ EndPoints
import { getUriParams } from '@src/utility/Utils' // 👈️ Utility

export const getData = createAsyncThunk('shipments/getData', async params => {
  const uri =  getUriParams(params)
  const OceanBookingFilter = {
    ffNumber: params.ffNumber === '' ? null : params.ffNumber,
    shipperRefNumber: params.shipperRefNumber === '' ? null : params.shipperRefNumber,
    bookingNumber: params.bookingNumber === '' ? null : params.bookingNumber,
    customerId: params.customerId?.companyId,
    containerSizeId: params.containerSizeId?.value,
    vesselName: params.vesselName === '' ? null : params.vesselName,
    terminalId: params.terminalId?.value,
    etdFrom: params.fromDate === '' ? null : params.fromDate,
    etdTo: params.toDate === '' ? null : params.toDate,
    destinationId: params.destinationId?.value,
    ssLineId: params.ssLineId?.value,
    isActive: params.isActive === false ? null : params.isActive,
    ffStatusId: params.ffStatusId?.value
  } 
  const response = await axios.post(`${urlShipSmplService}/GetAllFFOceanBooking?${uri}`, OceanBookingFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState: {
    loaded : false,
    data: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getData.pending, (state) => {
      state.loaded = false
    })
    .addCase(getData.fulfilled, (state, action) => {
      state.loaded = action.payload.loaded
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
      
  }
})
  
export default shipmentsSlice.reducer

shipmentsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Shipments - Store {Get Data from Microservices}',
  filename: 'shipments',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/freight-forward-request/store/shipments.js',
  prodUrl: ''
}
