import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { urlCargoInventoryService, urlCRMCompanyService, urlConfigCacheService, urlEquipmentService, urlMaterialInventoryService, urlPackagingService } from "../../../../endpoints"
import axios from "axios"
import toast from "react-hot-toast"

const getParamString = (params) => {
    let string = ""
    if (Object.keys(params).length > 0) {
      Object.keys(params).map((key) => {
        if (!!params[key]) {
          string += `${key}=${params[key]}&`
        }
      })
    }
    return string
  }

export const getMaterialData = createAsyncThunk(
    "inventory/getMaterialData",
    async (params) => {
      const response = await axios.post(
        `${urlMaterialInventoryService}/GetAllMaterialInventory?${getParamString(params.params)}`, params.filter || {}
      )
      return {
        data: response.data.data,
        totalPages: response.data.totalCount,
        params: params.params,
        filter: params.filter
      }
    }
  )

export const getCargoData = createAsyncThunk(
  "inventory/getCargoData",
  async (obj) => {
    const {params, filter} = obj
    const response = await axios.post(
      `${urlCargoInventoryService}/GetAllCargoInventory?${getParamString(params)}`, filter || {}
    )
    const result = {
      data: response.data.data || [],
      totalPages: response.data.totalCount || 0,
      params,
      filter
    }
    return result
  }
)

export const cargoExcelData = async (data) => {
  const {params, filter} = data
  const response = await axios.post(
    `${urlCargoInventoryService}/GetAllCargoInventory?${getParamString(params)}`, filter || {}
  )
  return response.data.data || []
}

  export const getYardLocation = createAsyncThunk(
    "inventory/getYardLocation",
    async () => {
      const response = await axios.get(
        `${urlConfigCacheService}/YardLocation`)
      return response.data || []
    }
  )

  // StorageType
  export const getStorageLocationTypes = createAsyncThunk(
    "inventory/getStorageLocationTypes",
    async () => {
      const response = await axios.get(
        `${urlConfigCacheService}/StorageLocationType`)
      return  response.data || []
    }
  )

  //Location Code - WTC
  export const getEquipmentInventory = createAsyncThunk(
    "equipment/getEquipmentInventory",
    async () => {
      const requestBody = {
        line: "WTC"
      }

      const response = await axios.post(
        `${urlEquipmentService}/GetEquipmentInventory`,
        requestBody
      )

      return response.data || []
    }
  )

  //Customer - Shipper
  export const getCustomer = createAsyncThunk(
    "inventory/getCustomer",
    async () => {
      const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/customer`)
      return response.data
    }
  )

//WriteOffReason
  export const getWriteOffReason = createAsyncThunk(
    "inventory/getWriteOffReason",
    async () => {
      const response = await axios.get(
        `${urlConfigCacheService}/WriteOffReason`)
      return  response.data || []
    }
  )

  export const getPackagingTypes = createAsyncThunk(
    "inventory/getPackagingTypes",
    async () => {
      const response = await axios.get(
        `${urlConfigCacheService}/PackagingType`)
      return  response.data || []
    }
  )

  export const getPackagingSuppliers = createAsyncThunk(
    "inventory/getPackagingSuppliers",
    async () => {
      const response = await axios.get(
        `${urlConfigCacheService}/PackagingSupplier`)
      return  response.data || []
    }
  )

export const addMaterialInventory = createAsyncThunk(
    "inventory/AddMaterialInventory",
    async (data, {dispatch}) => {
      try {
        const response = await axios.post(`${urlMaterialInventoryService}/AddMaterialInventory`, data)
      console.log("🚀 ~ response:", response)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
      } catch (error) {
        toast.error("Internal Server Error!")
      }
    }
  )

  export const receiveMaterialInventory = createAsyncThunk(
    "inventory/receiveMaterialInventory",
    async (data, {dispatch}) => {
      const response = await axios.put(`${urlMaterialInventoryService}/ReceiveMaterialInventory`, data)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const shipMaterialInventory = createAsyncThunk(
    "inventory/shipMaterialInventory",
    async (data, {dispatch}) => {
      const response = await axios.put(`${urlMaterialInventoryService}/ShipMaterialInventory`, data)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const adjustMaterialInventory = createAsyncThunk(
    "inventory/adjustMaterialInventory",
    async (data, {dispatch}) => {
      const response = await axios.put(`${urlMaterialInventoryService}/AdjustMaterialInventory`, data)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const moveMaterialInventory = createAsyncThunk(
    "inventory/moveMaterialInventory",
    async (data, {dispatch}) => {
      const response = await axios.put(`${urlMaterialInventoryService}/MoveMaterialInventory`, data)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const deleteMaterialInventory = createAsyncThunk(
    "inventory/deleteMaterialInventory",
    async (id, { dispatch }) => {
      const response = await axios.delete(`${urlMaterialInventoryService}/DeleteMaterialInventory/${id}`)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const addMaterialInventoryNotes = createAsyncThunk(
    "inventory/addMaterialInventoryNotes",
    async (data, { dispatch }) => {
      const response = await axios.put(`${urlMaterialInventoryService}/AddMaterialInventoryNotes`, data)
      await dispatch(
        getMaterialData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )

  export const deleteCargoInventory = createAsyncThunk(
    "inventory/deleteCargoInventory",
    async (data, { dispatch }) => {
      const {id, filter} = data
      try {
        const response = await axios.delete(`${urlCargoInventoryService}/DeleteCargoInventory/${id}`)
      await dispatch(
        getCargoData({
          params: {
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        },
         filter
        })
      )
      toast.success("Cargo Deleted Successfully!")
      return response.data
      } catch (error) {
        toast.error("Failed To Delete Cargo!")
      }
      
      return response.data
    }
  )

  export const getPackagingData = createAsyncThunk(
    "appUsers/getPackagingData",
    async (params) => {
      const response = await axios.post(
        `${urlPackagingService}/GetAllPackaging?${getParamString(params.params)}`, params.filter || {}
      )
      return {
        params: params.params,
        filter: params.filter,
        data: response.data.data,
        totalPages: response.data.totalCount,
        loaded: true
      }
    }
  )

  //get packaging data, returns all packaging data
  export const getPackagingDataAll = async (params) => {
    const response = await axios.post(
      `${urlPackagingService}/GetAllPackaging?${getParamString(params.params)}`, params.filter || {}
    )
    return response.data.data
  }
  
  // export const addPackaging = createAsyncThunk(
  //   "transloadOrder/addPackaging",
  //   async (data) => {
  //     console.log(data)
  //     const response = await axios.post(
  //       `${urlPackagingService}/AddPackaging`,
  //       data
  //     )
  
  //     console.log(response.data)
  
  //     return response.data
  //   }
  // )

  export const addPackaging = async (data) => {
    try {
      const response = await axios.post(`${urlPackagingService}/AddPackaging`, data)
      if (response.data.success) {
        return {success: true, message: "Packaging Added Successfully"}
      } else {
        return {success: false, message: response.data.errors[0]}
      }
    } catch (error) {
      return {success: false, message: "Internal Server Error!"}
    }
  }
  
  // export const updatePackaging = createAsyncThunk(
  //   "transloadOrder/updatePackaging",
  //   async (data, { dispatch }) => {
  //     const response = await axios.put(`${urlPackagingService}/UpdatePackaging`, data)
  //     await dispatch(
  //       getPackagingData({
  //         sort:"desc",
  //         sortColumn: 'id',
  //         q: '',
  //         page: 1,
  //         perPage: 10,
  //         status: ""
  //       })
  //     )
  //     return response.data
  //   }
  // )

  export const updatePackaging = async (data) => {
    try {
      const response = await axios.put(`${urlPackagingService}/UpdatePackaging`, data)
    if (response.data.success) {
      return {success: true, message: "Packaging Updated Successfully"}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
    } catch (error) {
      return {success: false, message: "Internal Server Error!"}
    }
  }
  
  export const updatePackagingPicture = createAsyncThunk(
    "transloadOrder/updatePackagingPicture",
    async (data, { dispatch }) => {
      const response = await axios.put(`${urlPackagingService}/UpdatePackagingPicture`, data)
      await dispatch(
        getPackagingData({
          sort:"desc",
          sortColumn: 'id',
          q: '',
          page: 1,
          perPage: 10,
          status: ""
        })
      )
      return response.data
    }
  )
  
  // export const deletePackaging = createAsyncThunk(
  //   "transloadOrder/deletePackaging",
  //   async (id, { dispatch }) => {
  //     const response = await axios.delete(`${urlPackagingService}/DeletePackaging/${id}`)
  //     await dispatch(
  //       getPackagingData({
  //         sort:"desc",
  //         sortColumn: 'id',
  //         q: '',
  //         page: 1,
  //         perPage: 10,
  //         status: ""
  //       })
  //     )
  //     return response.data
  //   }
  // )

  export const deletePackaging = async (id) => {
    try {
      const response = await axios.delete(`${urlPackagingService}/DeletePackaging/${id}`)
      if (response.data.success) {
        return {success: true, message: "Packaging Deleted Successfully"}
      } else {
        return {success: false, message: response.data.errors[0]}
      }
    } catch (error) {
      return {success: false, message: "Internal Server Error!"}
    }
  }
  
  export const getPackaging = createAsyncThunk(
    "transloadOrder/getPackaging",
    async (id) => {
      const response = await axios.get(
        `${urlPackagingService}/GetPackaging/${id}`
      )
      return response.data
    }
  )

  // Move Inventory -> CargoInventory/MoveCargoInventory
  // Transfer Inventory -> CargoInventory/TransferCargoInventory
  // Adjust Inventory -> CargoInventory/AdjustCargoInventory
  // Ship Inventory -> CargoInventory/ShipCargoInventory

  export const shipCargoInventory = async (data) => {
    const response = await axios.put(`${urlCargoInventoryService}/ShipCargoInventory`, data)
    if (response.status === 200) {
      return {success: true, message: "Cargo Shipped Successfully"}
    } else {
      return {success: false, message: "Error Shipping Cargo"}
    }
  }

  export const adjustCargoInventory = async (data) => {
    const response = await axios.put(`${urlCargoInventoryService}/AdjustCargoInventory`, data)
    if (response.status === 200) {
      return {success: true, message: "Cargo Adjusted Successfully"}
    } else {
      return {success: false, message: "Error Adjusting Cargo"}
    }
  }

  export const moveCargoInventory = async (data) => {
    const response = await axios.put(`${urlCargoInventoryService}/MoveCargoInventory`, data)
    if (response.status === 200) {
      return {success: true, message: "Cargo Moved Successfully"}
    } else {
      return {success: false, message: "Error Moving Cargo"}
    }
  }

  export const transferCargoInventory = async (data) => {
    const response = await axios.put(`${urlCargoInventoryService}/TransferCargoInventory`, data)
    if (response.status === 200) {
      return {success: true, message: "Cargo Transferred Successfully"}
    } else {
      return {success: false, message: "Error Transferring Cargo"}
    }
  }

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: {
      loading: false,
      material : {
        data: [],
        total: 0,
        params: {},
        filter: {}
      },
      cargo : {
        data: [],
        total: 0,
        params: {},
        filter: {}
      },
      yardLocation : [],
      storageLocationTypes : [],
      customers : [],
writeOffReasons : [],
      packagingTypes : [],
      packagingSuppliers : [],
      packaging: {
        data: [],
        total: 0,
        packaging : {},
        loading: false
      }
    },
    reducers : {
      resetPackaging : (state) => {
        state.packaging.packaging = {}
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getMaterialData.fulfilled, (state, action) => {
          state.material.data = action.payload.data
          state.material.total = action.payload.totalPages
          state.material.params = action.payload.params
          state.material.filter = action.payload.filter
          state.loading = false
        })
        .addCase(getCargoData.fulfilled, (state, action) => {
            state.cargo.data = action.payload.data
            state.cargo.total = action.payload.totalPages
            state.cargo.params = action.payload.params
            state.cargo.filter = action.payload.filter
            state.loading = false
        })
        .addCase(getCargoData.pending, (state) => {
          state.loading = true
          state.cargo.data = []
        })
        .addCase(getCargoData.rejected, (state) => {
          state.loading = false
          state.cargo.data = []
        })
        .addCase(getMaterialData.pending, (state) => {
          state.loading = true
          state.material.data = []
        })
        .addCase(getMaterialData.rejected, (state) => {
          state.loading = false
          state.material.data = []
        })
        .addCase(getYardLocation.fulfilled, (state, action) => {
            state.yardLocation = action.payload
        })
        .addCase(getStorageLocationTypes.fulfilled, (state, action) => {
            state.storageLocationTypes = action.payload
        })
        .addCase(getEquipmentInventory.fulfilled, (state, action) => {
            state.equipmentInventory = action.payload
        })
        .addCase(getWriteOffReason.fulfilled, (state, action) => {
            state.writeOffReasons = action.payload
        })
        .addCase(getCustomer.fulfilled, (state, action) => {
            state.customers = action.payload
        })
        .addCase(getPackagingTypes.fulfilled, (state, action) => {
            state.packagingTypes = action.payload
        })
        .addCase(getPackagingData.pending, (state) => {
          state.packaging.loading = true
        })
        .addCase(getPackagingData.fulfilled, (state, action) => {
          state.packaging.data = action.payload.data
          state.packaging.total = action.payload.totalPages
          state.packaging.loading = false
        })
        .addCase(getPackaging.fulfilled, (state, action) => {
          state.packaging.packaging = action.payload
        })
        // .addCase(addPackaging.fulfilled, (state, action) => {
        //   state.packaging.packagingData.push(action.payload)
        // })
        //if rejected
        .addMatcher(
          (action) => {
            return action.type.includes("/rejected")
          },
          (state) => {
            state.loading = false
          }
        )
    }
  })

export const { resetPackaging } = inventorySlice.actions
  
  export default inventorySlice.reducer