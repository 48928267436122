// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlDailyPlannigService } from '@src/endpoints'
import { getUriParams } from "@utils" // 👈️ Utils Imports 

export const getAllData = createAsyncThunk('resourcePlanning/getAllData', async () => {
  const response = await axios.get(`${urlDailyPlannigService}/GetMyTeamDailyPlanCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getMyTeam =  createAsyncThunk('resourcePlanning/getMyTeam', async () => {
  const response = await axios.get(`${urlDailyPlannigService}/GetMyTeam`)
  const myTeam  = [...response.data]
  return myTeam
})

export const getMyTeamProject =  createAsyncThunk('resourcePlanning/getMyTeamProject', async () => {
  const response = await axios.get(`${urlDailyPlannigService}/GetMyTeamProject`)
  const myTeamProject  = [...response.data]
  return myTeamProject
})

export const getData = createAsyncThunk('resourcePlanning/getData', async params => {
  const uri =  getUriParams(params)
  const DailyPlannigViewModel = {
    userId: params.userId,
    startDate: params.startDate,
    endDate: params.endDate,
    status: params.status,
    isSoftDeleted: params.isSoftDeleted
  }
  const response = await axios.post(`${urlDailyPlannigService}/GetAllTeamDailyPlan?${uri}`, DailyPlannigViewModel)
  return {
    params,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const addTeamDailyPlan = createAsyncThunk('resourcePlanning/addTeamDailyPlan', async (DailyPlannigViewModel, { dispatch, getState }) => {
  await axios.post(`${urlDailyPlannigService}/AddTeamDailyPlan`, DailyPlannigViewModel)
  await dispatch(getData(getState().resourcePlanning.params))
  await dispatch(getAllData())
  return DailyPlannigViewModel
})

export const updateDailyPlan = createAsyncThunk('resourcePlanning/updateDailyPlan', async (DailyPlanUpdateViewModel, { dispatch, getState }) => {
  await axios.put(`${urlDailyPlannigService}/UpdateDailyPlan`, DailyPlanUpdateViewModel)
  await dispatch(getData(getState().resourcePlanning.params))
  await dispatch(getAllData())
  return DailyPlanUpdateViewModel
})

export const hrmTimeSheetsSlice = createSlice({
  name: 'resourcePlanning',
  initialState: {
    loaded : false,
    loadedAll: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    myTeam:[],
    myTeamProject:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMyTeam.fulfilled, (state, action) => {
        state.myTeam = action.payload
      })
      .addCase(getMyTeamProject.fulfilled, (state, action) => {
        state.myTeamProject = action.payload
      })
  }
})

export default hrmTimeSheetsSlice.reducer

hrmTimeSheetsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'CRM Deals - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/crm/deals/store/index.js',
  prodUrl: ''
}
