
// 👇 API EndPoints
import { urlCRMContactService, urlCompanyService, urlIdentityService } from '@src/endpoints'

import { getUserUriParams } from '@src/utility/Utils'

// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

// 👇 Third-Party component
import toast from 'react-hot-toast'

// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}

export const getAllCustomerList =  createAsyncThunk('crmContacts/getAllCustomerList', async () => {
  const response = await axios.get(`${urlCompanyService}/GetAllCompanySelect`)
  const customers = [{ value: '', label: 'Select Customer' }, ...response.data.data]
  return {
    allCustomerList: customers
  }
}) 

export const getAllData = createAsyncThunk('crmContacts/getAllData', async () => {
  const response = await axios.get(`${urlCRMContactService}/GetAllContactsCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('crmContacts/getData',  async (args) => {
const {params, filter} = args
  const uri =  getUserUriParams(params)
  const response = await axios.post(`${urlCRMContactService}/GetAllContacts?${uri}`, filter)
  return {
    params,
    filter,
    data: response.data,
    totalPages: response.data.length > 0 ? response.data[0].totalCount : 0,
    loaded: true
  }
})

export const getContact = createAsyncThunk('crmContacts/getContact', async id => {
  const response = await axios.get(`${urlCRMContactService}/ContactDetailsById/${id}`)
  return {
    selectedUser: response.data,
    loadedContact: true
  }
})

// export const addUser = createAsyncThunk('crmContacts/addUser', async (user, { dispatch, getState }) => {
//   const response = await axios.post(`${urlIdentityService}/Register`, user)
//   if (response.data.userId !== null) {
//     const contact = {
//       ...user,
//       userId : response.data.userId
//     }
//     await axios.post(`${urlCRMContactService}/AddContact`, contact)
//   }
//   const params = getState().crmContacts.params
//   const filter = getState().crmContacts.filter
//   await dispatch(getData({params, filter}))
//   await dispatch(getAllData())
//   return user
// })
export const addUser = createAsyncThunk(
  'appUsers/addUser',
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${urlIdentityService}/Register`, user)
      if (response.data) {
        // Assuming the response has a data field that indicates success
        toast.success('User added successfully!')
        // Refresh data after adding user
        await dispatch(getData(getState().users.params))
        await dispatch(getAllData())
        return response.data  // You may want to return something specific from response.data
      } else {
        toast.error('Failed to add user.')
        return rejectWithValue('Failed to add user')  // Use an appropriate error message
      }
    } catch (error) {
      // Error handling if the request itself fails
      const message = (error.response && error.response.data && error.response.data.message) || 'Failed to add user'
      toast.error(message)
      return rejectWithValue(message)
    }
  }
)

export const updateUser = createAsyncThunk('crmContacts/updateUser', async (userProfileUpdateViewModel, {dispatch}) => {
  await axios.put(`${urlCRMContactService}/UpdateContact`, userProfileUpdateViewModel)
  await dispatch(getContact(userProfileUpdateViewModel.userId))
  return userProfileUpdateViewModel
})

export const updateContactBoolean = async (updateContactBooleanViewModel) => {
  await axios.put(`${urlCRMContactService}/UpdateContactBoolean`, updateContactBooleanViewModel)
  return updateContactBooleanViewModel
}

export const updateContactBooleanAsync = createAsyncThunk('crmContacts/updateContactBooleanAsync', async (updateContactBooleanViewModel, {dispatch}) => {
  await axios.put(`${urlCRMContactService}/UpdateContactBoolean`, updateContactBooleanViewModel)
  await dispatch(getContact(updateContactBooleanViewModel.userId))
  return updateContactBooleanViewModel
})

export const updateContactType = async (updateContactTypeViewModel) => {
  await axios.put(`${urlCRMContactService}/UpdateContactType`, updateContactTypeViewModel)
  return updateContactTypeViewModel
}

export const addPassword = createAsyncThunk('crmContacts/addPassword', async (addPasswordViewModel) => {
  const response = await axios.patch(`${urlIdentityService}/AddPassword`, addPasswordViewModel)
  response.data.success === true ? ToastMessage('Password', response.data.messages[0], true) : ToastMessage('Password', response.data.errors[0], false)
})

export const appUsersSlice = createSlice({
  name: 'crmContacts',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedContact: false,
    data: [],
    total: 1,
    params: {},
    filter: {},
    allData: {},
    selectedUser: null,
    allCustomerList:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.filter = action.payload.filter
        state.total = action.payload.totalPages
      })
      .addCase(getContact.pending, (state) => {
        state.loadedContact = false
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.selectedUser = action.payload.selectedUser
        state.loadedContact = action.payload.loadedContact
      })
      .addCase(getAllCustomerList.fulfilled, (state, action) => {
        state.allCustomerList = action.payload.allCustomerList
      })
  }
})

export default appUsersSlice.reducer
