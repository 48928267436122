// 👇 Axios Imports
import axios from 'axios'

import { urlReportingService, urlOutboundPlanService } from '@src/endpoints'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUriParams } from '@src/utility/Utils'

export const getAllData = async (params, body) => {
  const uri = `page=${params.page}&perPage=${params.perPage}&status=${params.status}&q=${params.q}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  try {
    const response = await axios.post(`${urlReportingService}/GetAllOutboundReportList?${uri}`, body)
    if (response.status === 200) {
      return response.data
    } else {
      return {success: false, message: 'Internal server Error'}
    }
  } catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
}

export const getOutboundPlans = async (data) => {
  try {
    const response = await axios.post(`${urlOutboundPlanService}/GetOutboundPlan`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
}

export const updateOutboundPlans = async (data) => {
  try {
    const response = await axios.put(`${urlOutboundPlanService}/UpdateOutboundPlan`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
}

export const addOutboundPlans = async (data) => {
  try {
    const response = await axios.post(`${urlOutboundPlanService}/AddOutboundPlan`, data)
    if (response.data.success) {
      return {success: true, data: response.data.data}
    } else {
      return {success: false, message: response.data.errors[0]}
    }
  } catch (error) {
      return {success: false, message: 'Internal server Error'}
  }
}

export const getAllOutboundLandReportList = createAsyncThunk('outboundRequests/getAllOutboundLandReportList', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)

  const filterData = {
    bookingNumber: filter.bookingNumber || null,
    containerNumber: filter.containerNumber || null,
    lineCarrier: filter.lineCarrier || null,
    forwarder: filter.forwarder || null,
    equipmentSize: filter.equipmentSize || null,
    lrdFrom: filter.lrdFrom || null,
    lrdTo: filter.lrdTo || null,
    erdFrom: filter.erdFrom || null,
    erdTo: filter.erdTo || null
  }

  const response = await axios.post(`${urlReportingService}/GetAllOutboundLandReportList?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    total: response.data.totalCount,
    filterData
  }
})

export const addOutboundLandReportNotes = createAsyncThunk(
  'outboundRequests/addOutboundLandReportNotes',
  async (data) => {
    const response = await axios.put(`${urlReportingService}/AddOutboundLandReportNotes`, data)
  return response.data
  }
)

export const ffRequestsSlice = createSlice({
  name: 'ffRequests',
  initialState: {
    data: [],
    total: 1,
    params: {},
    filter: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllOutboundLandReportList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        state.filter = action.payload.filterData
      })
  }
})

export default ffRequestsSlice.reducer