// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { urlIdentityService, urlHolidayCalendarService } from '@src/endpoints'

// ** Axios Imports
import axios from 'axios'

export const fetchEvents = createAsyncThunk('holidayCalendar/fetchEvents', async calendars => {
  const HolidayCalendaFilter = {
    selectedCalendars: calendars,
    date: null,
    userId: null
  }
  const response = await axios.post(`${urlHolidayCalendarService}/GetAllHolidays`, HolidayCalendaFilter)
  return {
    event: response.data,
    loaded: true
  }
})

export const addEvent = createAsyncThunk('holidayCalendar/addEvent', async (event, { dispatch, getState }) => {
  const HolidayCalendarViewModel = {
    title: event.title,
    start: event.start,
    end: event.end,
    allDay: event.allDay,
    display: event.display,
    calendar: event.extendedProps.calendar,
    url: event.extendedProps.url,
    guests: event.selectedTeam,
    location: event.extendedProps.location,
    desc: event.extendedProps.desc
  }
  await axios.post(`${urlHolidayCalendarService}/AddNewHoliday`, HolidayCalendarViewModel)
  await dispatch(fetchEvents(getState().holidayCalendar.selectedCalendars))
  return event
})

export const updateEvent = createAsyncThunk('holidayCalendar/updateEvent', async (event, { dispatch, getState }) => {
  const HolidayCalendarViewModel = {
    id: event.id,
    title: event.title,
    start: event.start,
    end: event.end,
    allDay: event.allDay,
    display: event.display,
    calendar: event.extendedProps.calendar,
    url: event.url,
    guests: event.selectedTeam,
    location: event.extendedProps.location,
    desc: event.extendedProps.description
  }
  await axios.post(`${urlHolidayCalendarService}/UpdateHoliday`, HolidayCalendarViewModel)
  await dispatch(fetchEvents(getState().holidayCalendar.selectedCalendars))
  return event
})

export const updateFilter = createAsyncThunk('holidayCalendar/updateFilter', async (filter, { dispatch, getState }) => {
  if (getState().holidayCalendar.selectedCalendars.includes(filter)) {
    await dispatch(fetchEvents(getState().holidayCalendar.selectedCalendars.filter(i => i !== filter)))
  } else {
    await dispatch(fetchEvents([...getState().holidayCalendar.selectedCalendars, filter]))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('holidayCalendar/updateAllFilters', async (value, { dispatch }) => {
  if (value === true) {
    await dispatch(fetchEvents(['Public Holidays', 'Statutory Holidays', 'Business']))
  } else {
    await dispatch(fetchEvents([]))
  }
  return value
})

export const removeEvent = createAsyncThunk('holidayCalendar/removeEvent', async id => {
  await axios.delete(`${urlHolidayCalendarService}/RemoveHoliday/${id}`)
  return id
})

export const getMembers =  createAsyncThunk('holidayCalendar/getMembers', async () => {
  const response = await axios.get(`${urlIdentityService}/GetMembers`)
  const members  = [...response.data]
  return members
})

export const holidayCalendarSlice = createSlice({
  name: 'holidayCalendar',
  initialState: {
    loaded: false,
    events: [],
    selectedEvent: {},
    selectedCalendars: ['Public Holidays', 'Statutory Holidays', 'Business'],
    members: []
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loaded = false
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loaded = true
        state.events = action.payload.event
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = ['Public Holidays', 'Statutory Holidays', 'Business']
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.members = action.payload
      })
  }
})

export const { selectEvent } = holidayCalendarSlice.actions

export default holidayCalendarSlice.reducer
