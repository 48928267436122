// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlIdentityService, urlAuthService } from '@src/endpoints'
// 👇 Third-Party component
import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}

export const getAllData = createAsyncThunk('accountSettings/getAllData', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllTimeSheetsCount`)
  return {
    data: response.data.data,
    loadedAll: true
  }
})

export const updateAvatar = createAsyncThunk('accountSettings/updateAvatar', async (UpdateAvatarViewModel) => {
  const response = await axios.put(`${urlIdentityService}/UpdateAvatar`, UpdateAvatarViewModel)
  return response
})

export const changePassword = createAsyncThunk('appUsers/changePassword', async (changePasswordViewModel) => {
  const response = await axios.patch(`${urlIdentityService}/ChangePassword`, changePasswordViewModel)
  response.data.success === true ? ToastMessage('Password', response.data.messages[0], true) : ToastMessage('Password', response.data.errors[0], false)
})

export const getAvatar = createAsyncThunk('accountSettings/getAvatar', async id => {
  const response = await axios.get(`${urlIdentityService}/GetAvatar/${id}`)
  return response.data.data[0].avatar
})

export const logoutSession = createAsyncThunk('accountSettings/logoutSession', async () => {
  const response = await axios.get(`${urlAuthService}/Logout`)
  return response
})

export const isValidToken = createAsyncThunk('accountSettings/isValidToken', async () => {
  const response = await axios.get(`${urlAuthService}/IsValidToken`)
  if (!response.data.success) {
    window.location = "/login"
  }
  return response
})

export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState: {
    loaded : false,
    loadedAvatar : false,
    loadedUpComing : false,
    loadedAll: false,
    avatar: '',
    data: [],
    total: 1,
    params: {},
    allData: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getAvatar.pending, (state) => {
        state.loadedAvatar = false
      })
      .addCase(getAvatar.fulfilled, (state, action) => {
        state.avatar = action.payload
        state.loadedAvatar = true
      })
  }
})

export default accountSettingsSlice.reducer

accountSettingsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Account Settings - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/pages/account-settings/store/index.js',
  prodUrl: ''
}
