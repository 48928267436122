// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

// ** Utils
import { canViewMenuItem, canViewMenuGroup, resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@layouts/utils' // canViewMenuItem,
import { getUserRole } from '@utils'

const VerticalMenuNavItems = props => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  const loggedInUser = getUserRole()
  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.navLink === undefined) { // Header
      if (item.children) { 
        const itemPermissions = item.permissions?.filter(element => loggedInUser?.indexOf(element) !== -1)
        if (itemPermissions?.length > 0) {
          return canViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />
        }
      }  
  } else { // Submenu
          const itemPermissions = item.permissions?.filter(element => loggedInUser?.indexOf(element) !== -1)
          if (itemPermissions.length > 0) {
            return canViewMenuItem(item) && <TagName key={item.id || item.header} item={item} {...props} /> 
          } 
        }
  })
  return RenderNavItems
}

export default VerticalMenuNavItems