// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
const baseURL = process.env.REACT_APP_API_URL

// 👇 Axios Imports
import axios from 'axios'

import { urlInboundRequestService, urlOceanBookingService } from '@src/endpoints'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import toast from 'react-hot-toast'
import { ToastContent } from "@src/views/components/toast/ToastContent"

const MySwal = withReactContent(Swal)

export const getAllData = createAsyncThunk('inboundRequests/getAllData', async () => {
  const response = await axios.get(`${urlInboundRequestService}/GetInboundRequestStatusCount`)
  return response.data.data
})

export const getData = createAsyncThunk('inboundRequests/getData', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'inboundRequestId'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? filters : {}
  try {
    const response = await axios.post(`${urlInboundRequestService}/GetAllInboundRequest?${uri}`, filterData)
    return {
      params,
      filterData: filters,
      data: response.data.data || [],
      totalPages: response.data.data ? response.data.totalCount : 1
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
  })
  return {
    params,
    filterData: filters,
    data: [],
    totalPages: 1
  }
  }
})

//Request Released
export const getReleaseData = createAsyncThunk('inboundRequests/getReleaseData', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'inboundRequestId'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? filters : {}

  const response = await axios.post(`${urlInboundRequestService}/GetAllRequestReleased?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 1
  }
})

//Distribution Tab - On Dock
export const getDistributionDataOnDock = createAsyncThunk('inboundRequests/getDistributionDataOnDock', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'inboundRequestId'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? {...filters, dockType: 'on-dock'} : {dockType: 'on-dock'}
  const response = await axios.post(`${urlInboundRequestService}/GetAllDistributionPlan?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 1
  }
})

// Distribution Tab - off dock
export const getDistributionDataOffDock = createAsyncThunk('inboundRequests/getDistributionDataOffDock', async (data) => {
  const { params, filters } = data
  const uri = `sort=${params.sort || 'desc'}&sortColumn=${params.sortColumn || 'id'}&page=${params.page || 1}&perPage=${params.perPage || 10}&q=${params.q || ''}`
  const filterData = filters ? {...filters, dockType: 'off-dock'} : {dockType: 'off-dock'}
  const response = await axios.post(`${urlInboundRequestService}/GetAllDistributionPlan?${uri}`, filterData)
  return {
    params,
    filterData: filters,
    data: response.data.data || [],
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 1
  }
})

export const getInboundRequest = createAsyncThunk('inboundRequests/GetInboundRequest', async id => {
  try {
    const response = await axios.get(`${urlInboundRequestService}/GetInboundRequest/${id}`)
    if (!response.data.success) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: response.data.errors[0]
      })
    }
    return {
      selectedCustomer: true,
      inboundRequest: response.data.data,
      status: response.data.status,
      oceanBookings: response.data.oceanBookings,
      notes: response.data.notes
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const getOceanBookingList = createAsyncThunk('ffRequests/getOceanBookingList', async oceanBookingIds => {
  const response = await axios.post(`${urlOceanBookingService}/getOceanBookingList`, oceanBookingIds)
  return {
    oceanBookingList: response.data.data.allData
  }
})

export const addInboundRequest = async data => {
try {
    const res = await axios.post(`${urlInboundRequestService}/AddInboundRequest`, data)
    if (res.data.success) {
      return {success: true, message: res.data}
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
      return {success: false, message: 'Bad Request'}
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
    return {success: false, message: 'Bad Request'}
  }
}

export const updateInboundRequest = async data => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/UpdateInboundRequest`, data)
  if (res.data.success) {
    return {success: true, message: res.data}
  } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
      return {success: false, message: 'Bad Request'}
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
    return {success: false, message: 'Bad Request'}
  }
}

export const addPlanDeclinedRequest = async data => {
  const res = await axios.put(`${urlInboundRequestService}/AddPlanDeclined`, data)
  if (res.data.success) {
    return { success: true, message: res.data.data }
  } else {
    return { success: false, message: 'Bad Request' }
  }
}

export const deletePlanDeclinedRequest = async data => {
  const res = await axios.put(`${urlInboundRequestService}/DeletePlanDeclined`, data)
  if (res.data.success) {
    return { success: true, message: res.data.data }
  } else {
    return { success: false, message: 'Bad Request' }
  }
}

  export const  fetchBookings = async (id) => {
    const res = await axios.get(`${baseURL}/OceanBooking/GetBookingNumberListBySSLine/${id}`)
    if (res) {
      return {success: true, data: res.data}
    } else {
        return {success: false, message: 'Bad Request'}
      }
  }

  export const fetchLandBookings = async (carrier) => {
    try {
    const res = await axios.get(`${baseURL}/LandBooking/GetBookingNumberListByCarrier/${carrier}`)
    if (res.data) {
      return {success: true, data: res.data || []}
    } else {
        return {success: false, message: 'Bad Request'}
      }
    } catch (error) {
      return {success: false, message: 'Bad Request'}
    }
  }

  export const addRvListInbound = async data => {
    const res = await axios.put(`${urlInboundRequestService}/AddRvList`, data)
    if (res.data.success) {
      return { success: true, message: res.data.data }
    } else {
      return { success: false, message: 'Bad Request' }
    }
  }

  export const updateRvListInbound = async data => {
    const res = await axios.put(`${urlInboundRequestService}/UpdateRvList`, data)
    if (res.data.success) {
      return { success: true, message: res.data.data }
    } else {
      return { success: false, message: 'Bad Request' }
    }
  }

  //SendDistributionPlanToDispatch
  export const sendDistributionPlanToDispatch = async data => {
    const res = await axios.put(`${urlInboundRequestService}/SendDistributionPlanToDispatch`, data)
    if (res.data.success) {
      return { success: true }
    } else {
      return { success: false, message: 'Bad Request' }
    }
  }

export const addNotes = createAsyncThunk('inboundRequests/addNotes', async (InboundRequestNotesViewModel, { dispatch }) => {
  await axios.put(`${urlInboundRequestService}/AddInboundRequestNotes`, InboundRequestNotesViewModel)
  await dispatch(getInboundRequest(InboundRequestNotesViewModel.InboundRequestId))
  return InboundRequestNotesViewModel
})

export const updateStatus = createAsyncThunk('inboundRequests/updateStatus', async (RequestStatusUpdateModel, { dispatch }) => {
 try {
    const res = await axios.put(`${urlInboundRequestService}/UpdateInboundRequestStatus`, RequestStatusUpdateModel)
    if (res.data.success) {
      await dispatch(getInboundRequest(RequestStatusUpdateModel.InboundRequestId))
      return RequestStatusUpdateModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const deleteInboundRequest = createAsyncThunk('inboundRequests/deleteInboundRequest', async (id, { dispatch, getState }) => {
  try {
    const res = await axios.delete(`${urlInboundRequestService}/DeleteInboundRequest/${id}`)
    if (res.data.success) {
      await dispatch(getData(getState().inboundRequest.params))
      await dispatch(getAllData())
      return id
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const addRequestDeclined = createAsyncThunk('inboundRequests/addRequestDeclined', async (RequestDeclinedViewModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/AddRequestDeclined`, RequestDeclinedViewModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Request Declined Successfully"} />
      ))
      await dispatch(getInboundRequest(RequestDeclinedViewModel.inboundRequestId))
      return RequestDeclinedViewModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const deleteRequestDeclined = createAsyncThunk('inboundRequests/deleteRequestDeclined', async (RequestDeclinedDeleteModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/DeleteRequestDeclined`, RequestDeclinedDeleteModel)
    if (res.data.success) {
      await dispatch(getInboundRequest(RequestDeclinedDeleteModel.inboundRequestId))
      return RequestDeclinedDeleteModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: 'Internal Server Error'
    })
  }
})

export const addRequestReleased = createAsyncThunk('inboundRequests/addRequestReleased', async (RequestReleasedViewModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/AddRequestReleased`, RequestReleasedViewModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Request Released Successfully"} />
      ))
      await dispatch(getInboundRequest(RequestReleasedViewModel.inboundRequestId))
      return RequestReleasedViewModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const updateRequestReleased = createAsyncThunk('inboundRequests/updateRequestReleased', async (RequestReleasedViewModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/UpdateRequestReleased`, RequestReleasedViewModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Request Released Updated Successfully"} />
      ))
      await dispatch(getInboundRequest(RequestReleasedViewModel.inboundRequestId))
      return RequestReleasedViewModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const deleteRequestReleased = createAsyncThunk('inboundRequests/deleteRequestReleased', async (RequestReleasedDeleteModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/DeleteRequestReleased`, RequestReleasedDeleteModel)
    if (res.data.success) {
      await dispatch(getInboundRequest(RequestReleasedDeleteModel.inboundRequestId))
      return RequestReleasedDeleteModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: 'Internal Server Error'
    })
  }
})

export const addDistributionPlan = createAsyncThunk('inboundRequests/addDistributionPlan', async (DistributionPlanViewModel, { dispatch }) => {
  // await axios.put(`${urlInboundRequestService}/AddDistributionPlan`, DistributionPlanViewModel)
  // await dispatch(getInboundRequest(DistributionPlanViewModel.inboundRequestId))
  // return DistributionPlanViewModel
  try {
    const res = await axios.put(`${urlInboundRequestService}/AddDistributionPlan`, DistributionPlanViewModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Distribution Plan Added Successfully"} />
      ))
      await dispatch(getInboundRequest(DistributionPlanViewModel.inboundRequestId))
      return DistributionPlanViewModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const updateDistributionPlan = createAsyncThunk('inboundRequests/updateDistributionPlan', async (DistributionPlanUpdateModel, { dispatch }) => {
  // await axios.put(`${urlInboundRequestService}/UpdateDistributionPlan`, DistributionPlanUpdateModel)
  // await dispatch(getInboundRequest(DistributionPlanUpdateModel.inboundRequestId))
  // return DistributionPlanUpdateModel
  try {
    const res = await axios.put(`${urlInboundRequestService}/UpdateDistributionPlan`, DistributionPlanUpdateModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Distribution Plan Updated Successfully"} />
      ))
      await dispatch(getInboundRequest(DistributionPlanUpdateModel.inboundRequestId))
      return DistributionPlanUpdateModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const deleteDistributionPlan = createAsyncThunk('inboundRequests/deleteDistributionPlan', async (DistributionPlanDeleteModel, { dispatch }) => {
  try {
    const res = await axios.put(`${urlInboundRequestService}/DeleteDistributionPlan`, DistributionPlanDeleteModel)
    if (res.data.success) {
      toast(t => (
        <ToastContent
         t={t}
         heading={"Success"}
         response={true}
         message={"Distribution Plan Deleted Successfully"} />
      ))
      await dispatch(getInboundRequest(DistributionPlanDeleteModel.inboundRequestId))
      return DistributionPlanDeleteModel
    } else {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: res.data.errors[0]
      })
    }
  } catch (error) {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: "Internal Server Error"
    })
  }
})

export const inboundRequestsSlice = createSlice({
  name: 'inboundRequests',
  initialState: {
    data: [],
    loading: false,
    total: 1,
    params: {},
    filterData: {},
    allData: {},
    selectedCustomer: null,
    inboundRequest: null,
    status: [],
    notes: [],
    releaseData: {
      data: [],
      total: 1,
      params: {},
      filterData: {}
    },
    distributionDataOnDock:{
      data: [],
      total: 1,
      params: {},
      filterData: {}
    },
    distributionDataOffDock:{
      data: [],
      total: 1,
      params: {},
      filterData: {}
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.loading = false
      })
      // getInboundRequest (for request tab)
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload?.data || []
        state.params = action.payload.params
        state.filterData = action.payload.filterData
        state.total = action.payload.totalPages
        state.loading = false
      })
      // getInboundRequest (for release tab)
      .addCase(getReleaseData.fulfilled, (state, action) => {
        state.releaseData.data = action.payload.data || []
        state.releaseData.params = action.payload.params
        state.releaseData.filterData = action.payload.filterData
        state.releaseData.total = action.payload.totalPages
        state.loading = false
      })
      // getInboundRequest (for distribution tab - onDock)
      .addCase(getDistributionDataOnDock.fulfilled, (state, action) => {
        state.distributionDataOnDock.data = action.payload.data || []
        state.distributionDataOnDock.params = action.payload.params
        state.distributionDataOnDock.filterData = action.payload.filterData
        state.distributionDataOnDock.total = action.payload.totalPages
        state.loading = false
      })
      // getInboundRequest (for distribution tab - offDock)
      .addCase(getDistributionDataOffDock.fulfilled, (state, action) => {
        state.distributionDataOffDock.data = action.payload.data || []
        state.distributionDataOffDock.params = action.payload.params
        state.distributionDataOffDock.filterData = action.payload.filterData
        state.distributionDataOffDock.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getInboundRequest.fulfilled, (state, action) => {
        state.selectedCustomer = action.payload.selectedCustomer
        state.inboundRequest = action.payload.inboundRequest
        state.status = action.payload.status
        state.notes = action.payload.notes
        state.loading = false
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.loading = true
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state) => {
          state.loading = false
        }
      )
  }
})

export default inboundRequestsSlice.reducer
