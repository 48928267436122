// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇️ Endpoints
import { urlDriverService } from '@src/endpoints'
// 👇️ Utils
import { getUriParams } from '@utils'
import { getUser } from '../../store/index'
//function to remove all null, undefined, empty string from object else make the value caps
// function clean(obj) {
//     if (!obj) return {}
//     for (const propName in obj) {
//         if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
//             delete obj[propName]
//         } else if (typeof obj[propName] === 'string') {
//             obj[propName] = obj[propName].toUpperCase()
//         }
//     }
//     return obj
// }
//get all block driver list
export const getBlockedDrivers = createAsyncThunk('blockDriver/getBlockedDrivers', async (params, { rejectWithValue }) => {
    try {
        const query = getUriParams(params) // Converts the params object into a URL query string
        const response = await axios.get(`${urlDriverService}/GetAllDriverBlockListList?${query}`)
        console.log('DriverList', response.data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
//add blockdriver
export const blockDriver = createAsyncThunk('blockDriver/blockDriver', async (blockDriverViewModel, { dispatch }) => {
    await axios.post(`${urlDriverService}/BlockOrUnblockDriver`, blockDriverViewModel)
    await dispatch(getUser(blockDriverViewModel.userId))
    await dispatch(getUser(blockDriverViewModel.userId))
    await dispatch(getBlockedDrivers())
    return blockDriverViewModel
})

export const blockDriverSlice = createSlice({
    name: 'blockDriver',
    initialState: {
        loaded: false,
        loadedAll: false,
        loadedFFR: false,
        data: [],
        terminal: {},
        total: 1,
        params: {},
        filterForm: {},
        allData: {},
        status: [],
        notes: [],
        blockDriver: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(blockDriver.fulfilled, (state, action) => {
                state.loaded = true
                state.data = action.payload
            })
            .addCase(getBlockedDrivers.fulfilled, (state, action) => {
                state.loadedAll = true
                state.allData = action.payload.data
                state.total = action.payload.totalCount
                state.params = action.payload.params
            })


    }
})

export default blockDriverSlice.reducer