// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import {urlVesselVoyageService} from '@src/endpoints'
import { getUriParams } from '@utils'

// 👇 Third-Party component
import toast from 'react-hot-toast'

// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}
export const getAllData = createAsyncThunk('vesselVoyage/getAllData', async () => {
  const response = await axios.get(`${urlVesselVoyageService}/GetVesselVoyageCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})

export const getData = createAsyncThunk('vesselVoyage/getData', async data => {
  const {params, filterForm} = data
  const uri = getUriParams({...params, status:"", q:""})
  const filter = {id: "", terminalName: params.terminalName, ...filterForm}
  const response = await axios.post(`${urlVesselVoyageService}/GetAllVoyage?${uri}`, filter)
  console.log(response)
  return {
    params,
    terminal: [],
    data: response.data.data || [],
    totalPages: response.data.totalCount,

    loaded: true,
    filterForm
  }
})

export const uploadExcelData = async (data) => {
  const {excelData} = data
  const res = await axios.post(`${urlVesselVoyageService}/VesselVoyageExcelUpload`, excelData)
  console.log(res)
   if (res.status === 200) {
    return {success: true}
  } else {
    return {success: false}
  }
}

export const addVoyage = createAsyncThunk('vesselVoyage/addVoyage', async (data, { dispatch }) => {
  const {voyage, params, filterForm} = data
  await axios.post(`${urlVesselVoyageService}/AddVoyage`, {...voyage, id: ""})
  dispatch(getData({params, filterForm}))
  return voyage
})

export const deleteVoyage = createAsyncThunk('vesselVoyage/deleteVoyage', async (data, { dispatch }) => {
  const {id, params, filterForm} = data
  const response = await axios.delete(`${urlVesselVoyageService}/DeleteVoyage/${id}`)
   response.data.success === true ? ToastMessage('Promotion', "The Vessel Schedule Deleted Sucessfully", true) : ToastMessage('Promotion', "Vessel Schedule Cannot be deleted", false)
  dispatch(getData({params, filterForm}))
  return id
})

export const vesselVoyageSlice = createSlice({
  name: 'vesselVoyage',
  initialState: {
    loaded : false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    terminal: {},
    total: 1,
    params: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser:[],
    allContacts:[],
    filterForm: {},
    vesselNames:[]

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.terminal = action.payload.terminal
        state.filterForm = action.payload.filterForm
      })
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
      })
  }
})

export default vesselVoyageSlice.reducer

vesselVoyageSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'VesselVoyage - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/hrm/time-sheet/store/index.js',
  prodUrl: ''
}
