import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getUriParams } from "@utils"
import { urlConfigCacheService, urlEdiRecords } from "../../../../../endpoints"

export const getData = createAsyncThunk('ediRecords/getData', async (data) => {
    const {params, filters} = data
    const uri = getUriParams(params)
    try {
        const response = await axios.post(`${urlEdiRecords}/GetAllEDIRecords?${uri}`, filters)
        return {
            params,
            data: response.data.data,
            totalPages: response.data.totalCount,
            filterData: filters
        }
    } catch (error) {
        return {
            params,
            data: [],
            totalPages: 0,
            filterData: filters
        }
    }
})

export const addEDIRecord = createAsyncThunk('ediRecords/addEDIRecord', async (data, { dispatch }) => {
    const {params, rows, filters} = data
    const response = await axios.post(`${urlEdiRecords}/AddMultipleEDIRecord`, rows)
    if (response.data.success) {
        dispatch(getData({params, filters}))
    }
        return response.data
})

export const getConfig = createAsyncThunk('ediRecords/getConfig', async () => {
    const truckingCompanies = await axios.get(`${urlConfigCacheService}/DrayageProvider`)
    const ssLines = await axios.get(`${urlConfigCacheService}/SteamshipLine`)
    return {
        truckingCompanies: truckingCompanies.data.map(el => el.description),
        ssLines: ssLines.data.map(el => el.description)
    }
})

const ediRecordsSlice = createSlice({
    name: 'ediRecords',
    initialState: {
        data: [],
        totalPages: 0,
        params: {},
        filterData: {},
        searchText: '',
        loading: false,
        errors: [],
        successMessages: [],
        drayageProviders: [],
        steamshipLines: []
    },
    reducers: {
        clearErrors: (state) => {
            state.errors = []
        },
        clearSuccessMessages: (state) => {
            state.successMessages = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.totalPages = action.payload.totalPages
                state.params = action.payload.params
                state.filterData = action.payload.filterData
                state.loading = false
            })
            .addCase(getData.rejected, (state) => {
                state.data = []
                state.totalPages = 0
                state.params = {}
                state.filterData = []
                state.loading = false
            })
            .addCase(addEDIRecord.fulfilled, (state, action) => {
                state.errors = action.payload.errors || []
                state.successMessages = action.payload.messages || []
                state.loading = false
            })
            .addCase(getConfig.fulfilled, (state, action) => {
                state.drayageProviders = action.payload.truckingCompanies
                state.steamshipLines = action.payload.ssLines
            })
            //any pending case will be handled here
            .addMatcher(action => action.type.endsWith('/pending'), (state) => {
                state.loading = true
              })
            .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
                state.loading = false
              })
      }
})

export const { clearErrors, clearSuccessMessages } = ediRecordsSlice.actions

export default ediRecordsSlice.reducer