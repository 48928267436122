// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlDashboardService } from '@src/endpoints'
import { getUriParams } from '@src/utility/Utils'

export const getVesselTrackerDownload = createAsyncThunk('vesselTracker/GetVesselTrackerDownload', async params => {
  const uri =  getUriParams(params)
  const response = await axios.post(`${urlDashboardService}/GetVesselTrackerDownload?${uri}`)

  return {
    params,
    download: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

export const vesselTrackerSlice = createSlice({
  name: 'vesselTracker',
  initialState: {
    loaded : false,
    download: {},
    params: {},
    total: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getVesselTrackerDownload.pending, (state) => {
            state.loaded = false
        })
        .addCase(getVesselTrackerDownload.fulfilled, (state, action) => {
            state.loaded = action.payload.loaded
            state.download = action.payload.download
            state.params = action.payload.params
            state.total = action.payload.totalPages
      })
  }
})

export default vesselTrackerSlice.reducer
