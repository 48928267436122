// 👇 Axios Imports
import axios from 'axios'
import { urlNodeService } from '@src/endpoints'

export const submitFeedbackForm = async (data) => {
    try {
        const response = await axios.post(`${urlNodeService}/EmailForm`, data)
        return {success: true, data: response.data}
    } catch (error) {
        return {success: false, error}
    }
}