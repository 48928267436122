// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
// import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
// import NavbarSearch from './NavbarSearch'
// import NotificationDropdown from './NotificationDropdown'

// ** Third Party Components
import { Sun, Moon, HelpCircle } from 'react-feather'

// ** Reactstrap Imports
import { Col, Form, Modal, ModalBody, ModalHeader, Button, NavItem, NavLink, Row, Input, ModalFooter, Label } from 'reactstrap'
import { useState } from 'react'
import { selectThemeColors } from '@utils'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { submitFeedbackForm } from './store'
import toast from 'react-hot-toast'

const NavbarUser = props => {
  // ** Props
  const store = useSelector(state => state.auth)
  const { skin, setSkin } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <div id='themeToggler'><Sun className='ficon' onClick={() => setSkin('light')} /></div>
    } else {
      return <div id='themeToggler'><Moon className='ficon' onClick={() => setSkin('dark')} /></div>
    }
  }

  const [filterForm, setFilterForm] = useState({
    user: "ShipSmpl",
    option: "",
    title: "",
    description: ""
  })

  const [open, setOpen] = useState(false)
  const toggle = () => {
    setFilterForm({
      option: "",
      title: "",
      description: ""
    })
    setOpen(!open)
  }

  const handleSubmit = async () => {
    try {
      const res = await submitFeedbackForm({
        user: `${store.userData.firstName} ${store.userData.lastName}`,
        page: window.location.href,
        option: filterForm.option,
        title: filterForm.title,
        description: filterForm.description
      })
      if (res.success) {
        toast.success("Feedback submitted successfully!")
        toggle()
      } else {
        toast.error("Failed to submit feedback")
      }
    } catch (error) {
      console.log(error)
      toast.error("An error occurred")
    }
  }


  return (
    <>
    <ul className='nav navbar-nav align-items-center ms-auto'>
      <IntlDropdown />
      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <ThemeToggler/>
        </NavLink>
      </NavItem>
      <NavItem className='nav-search' onClick={() => toggle()}>
      <NavLink className='nav-link-search'>
        <HelpCircle className='ficon' />
      </NavLink>
    </NavItem>
       {/* <NavbarSearch /> 
       <CartDropdown /> 
       <NotificationDropdown />  */}
      <UserDropdown />
    </ul>

      <Modal isOpen={open} toggle={toggle} className='modal-dialog-centered'>
        <ModalHeader toggle={toggle}>Help Function</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className='mb-2'>
              <Col lg='12' className='mt-1'>
                <Label for='status'>Select an option <span className='text-primary'>*</span></Label>
                <Select
                  id='status'
                  isClearable={false}
                  className='react-select border rounded'
                  classNamePrefix='select'
                  options={['Report a bug', 'Provide a feedback', 'Ask a question'].map(i => ({ value: i, label: i})) }
                  theme={selectThemeColors}
                  value={{ value: filterForm.option, label: filterForm.option === "" ? "Select an option" : filterForm.option }}
                  onChange={(e) => setFilterForm({ ...filterForm, option: e.value })}
                />
              </Col>

              <Col lg='12' className='mt-1'>
                <Label for='title'>Title <span className='text-primary'>*</span></Label>
                <Input
                  type='text'
                  value={filterForm.title || ''}
                  onChange={e => setFilterForm({ ...filterForm, title: e.target.value })}
                  name='title'
                  placeholder='Title'
                />
              </Col>

              <Col lg='12' className='mt-1'>
                <Label for='description'>Description <span className='text-primary'>*</span></Label>
                <Input
                  type='textarea'
                  value={filterForm.description || ''}
                  onChange={e => setFilterForm({ ...filterForm, description: e.target.value })}
                  name='description'
                  placeholder='Description'
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit}>Submit</Button>
          <Button color='secondary' onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
export default NavbarUser
