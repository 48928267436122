import config from '../config'
// ** Utils
import { paginateArray } from '../utils'

const data = {
  status: [
    {
      type: 'ContainerSize',
      value: 1,
      label: '20’ GP',
      name: '20’ GP',
      description: '20 FT GP',
      order: 1,
      status: true,
      color: 'info'
    },
    {
      type: 'ContainerSize',
      value: 2,
      label: '40’ GP',
      name: '40’ GP',
      description: '40 FT GP',
      order: 2,
      status: true,
      color: 'light-info'
    },
    {
      type: 'ContainerSize',
      value: 3,
      label: '40’ HC',
      name: '40’ HC',
      description: '40 FT HC',
      order: 3,
      status: true,
      color: 'dark'
    },
    {
      type: 'ContainerSize',
      value: 4,
      label: '45’ HC',
      name: '45’ HC',
      description: '45 FT HC',
      order: 4,
      status: true,
      color: 'black'
    },
    {
      type: 'ContainerSize',
      value: 5,
      label: '53’ HC',
      name: '53’ HC',
      description: '53 FT HC',
      order: 5,
      status: true,
      color: 'primary'
    },
    {
      type: 'ContainerSize',
      value: 6,
      label: '60’ HC',
      name: '60’ HC',
      description: '60 FT HC',
      order: 6,
      status: true,
      color: 'warning'
    },
    {
      type: 'ContainerSize',
      value: 7,
      label: '20’ RF',
      name: '20’ RF',
      description: '20 FT RF',
      order: 7,
      status: true,
      color: 'light-success'
    },
    {
      type: 'ContainerSize',
      value: 8,
      label: '40’ RF',
      name: '40’ RF',
      description: '40 FT RF',
      order: 8,
      status: true,
      color: 'success'
    }
  ]
}

// GET ALL DATA
config.onGet('/api/freight-forward/containerSize/all-size').reply(200, data.status.sort((a, b) => (a.order > b.order ? 1 : -1)))

// GET Updated DATA
config.onGet('/api/freight-forward/containerSize/data').reply(params => {
  const {
    q = '',
    page = 1,
    perPage = 10,
    sort = 'asc',
    status = null,
    sortColumn = 'value'
  } = params

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.status.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    _status =>
      (_status.type.toLowerCase().includes(queryLowered) ||
        _status.label.toLowerCase().includes(queryLowered) ||
        _status.description.toLowerCase().includes(queryLowered)) &&
      _status.status === (status || _status.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      status: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET FF Status
config.onGet('/api/freight-forward/containerSize').reply(params => {
  const { id } = params
  const status = data.status.find(i => i.value === id)
  return [200, { status }]
})

