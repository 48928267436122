const initialState = {
    loading: false,
    error: null,
    success: null,
    inGateData: {
        terminal: '',
        movementType: "Ingate",
        containers : {
            current:0,
            details: []
        },
        invalidFields: []
    },
    outGateData: {
        terminal: '',
        movementType: "Outgate",
        containers : {
            current:0,
            details: []
        },
        invalidFields: []
    },
    showingInGate: null,
    page: 1,
    maxNavPage: 1,
    inGateList : ['Choose Service', "DRIVER'S IDENTIFICATION", 'In-Gate Service'],
    outGateList : ['Choose Service', "DRIVER'S IDENTIFICATION", 'Out-Gate Service'],
    customers: [],
    allLocations: [
        'AHEER',   'CCL',     'CN',
        'COAST',   'CP',      'CT',
        'CW',      'DELCO',   'DP',
        'EURO',    'EWEN',    'FSD',
        'GAT',     'HARBOUR', 'KINGSWAY',
        'LINDSEY', 'MARCO',   'METR02',
        'MISC',    'RMT',     'RYDER',
        'TDK',     'VT',      'WTC'
      ]
}

const terminalReducer = (state = initialState, action) => {
    switch (action.type) {

        //loading
        case 'TERMINAL_LOADING':
            return { ...state, loading: action.payload }
        
        //change page, gets called when user clicks on a page in the timeline
        case 'TERMINAL_CHANGE_PAGE':
            return { ...state, page: action.payload, maxNavPage: action.payload > state.maxNavPage ? action.payload : state.maxNavPage }

        //post/edit terminal entry form
        case 'TERMINAL_ENTRY':
            return { ...state, inGateData: { ...state.inGateData, ...action.payload }, showingInGate: true }
        
        //container entry
        case 'TERMINAL_CONTAINER_ENTRY':
            return { ...state, inGateData: { ...state.inGateData, containers:  {...state.inGateData.containers, ...action.payload} } }

        //container entry
        case 'TERMINAL_CONTAINER_EXIT':
            return { ...state, outGateData: { ...state.outGateData, containers:  {...state.outGateData.containers, ...action.payload} } }

        //post/edit terminal exit form
        case 'TERMINAL_EXIT':
            return { ...state, outGateData: { ...state.outGateData, ...action.payload }, showingInGate: false }
        
        //change maxNavPage
        case 'TERMINAL_CHANGE_MAX_NAV_PAGE':
            return { ...state, maxNavPage: action.payload }
        
        //edit ingateList
        case 'TERMINAL_EDIT_INGATE_LIST':
            return { ...state, inGateList: action.payload }
        
        //edit outgateList
        case 'TERMINAL_EDIT_OUTGATE_LIST':
            return { ...state, outGateList: action.payload }

        //customers list
        case 'TERMINAL_CUSTOMERS':
            return { ...state, customers: action.payload }

        //add locations
        case 'TERMINAL_ADD_LOCATIONS':
            return { ...state, allLocations: action.payload }

        //reset state
        case 'RESET_STATE_TERMINAL':
            return {
                ...state,
                loading: false,
                error: null,
                success: null,
                inGateData: { terminal: '', movementType: "Ingate", containers:{current:0, details: []}, invalidFields: []},
                outGateData: { terminal: '', movementType: "Outgate", containers:{current:0, details: []}, invalidFields: []},
                showingInGate: null,
                page: 1,
                maxNavPage: 1,
                inGateList : ['Choose Service', "DRIVER'S IDENTIFICATION", 'In-Gate Service'],
                outGateList : ['Choose Service', "DRIVER'S IDENTIFICATION", 'Out-Gate Service'],
                customers: [],
                allLocations: [
                    'AHEER',   'CCL',     'CN',
                    'COAST',   'CP',      'CT',
                    'CW',      'DELCO',   'DP',
                    'EURO',    'EWEN',    'FSD',
                    'GAT',     'HARBOUR', 'KINGSWAY',
                    'LINDSEY', 'MARCO',   'METR02',
                    'MISC',    'RMT',     'RYDER',
                    'TDK',     'VT',      'WTC'
                  ]
            }

        default:
            return state
    }
}

export default terminalReducer