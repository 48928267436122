// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlOceanScheduleService } from '@src/endpoints'

export const getData = createAsyncThunk('oceanSchedules/getData', async params => {
  const response = await axios.post(`${urlOceanScheduleService}/GetOceanSchedule`, params)
  return {
    data: response.data,
    loaded : true
  }
})

export const oceanSchedulesSlice = createSlice({
  name: 'oceanSchedules',
  initialState: {
    loaded : false,
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.loaded = action.payload.loaded
      })
  }
})

export default oceanSchedulesSlice.reducer

oceanSchedulesSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Ocean Schedule - Store {Get Data from Microservices}',
  filename: 'index.js',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/freight-forward-request/store-ocean-schedule/index.js',
  prodUrl: ''
}